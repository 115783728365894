import moment from "moment";

export const datesAreSame = (a: Date, b: Date) => {
    return (
        a.getDate() === b.getDate() &&
        a.getMonth() === b.getMonth() &&
        a.getFullYear() === b.getFullYear()
    );
}

export const getDateToDisplay = (inputDate: Date | string) => {
    const displayDate = new Date(inputDate);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (datesAreSame(today, displayDate)) {
        return moment(displayDate).format("H:mm");
    } else if (datesAreSame(yesterday, displayDate)) {
        return "Yesterday";
    } else {
        return moment(displayDate).format("YYYY/MM/DD");
    }
}

export const getDateAndTimeToDisplay = (inputDate: Date | string) => {
    const displayDate = new Date(inputDate);
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const timeToDisplay = moment(displayDate).format("H:mm");

    if (datesAreSame(today, displayDate)) {
        return timeToDisplay;
    } else if (datesAreSame(yesterday, displayDate)) {
        return `Yesterday ${timeToDisplay}`;
    } else {
        return `${moment(displayDate).format("YYYY/MM/DD")} ${timeToDisplay}`;
    }
}

export const dateToGQLFormat = (date: Date) => {
    return moment(date).format("YYYY-MM-DDTHH:mm:ssZ");
}
