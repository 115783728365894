import { FC, HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';
import { UsersPermissionsUser } from '../services/graphql';

interface ProfileIconProps extends HTMLAttributes<HTMLDivElement> {
    user?: UsersPermissionsUser,
}

export const ProfileIcon: FC<ProfileIconProps> = ({ className, user }) => {
    return <div className={merge(`bg-primary-200 rounded-full flex items-center justify-center`, className)}>
        {user?.profilePicture?.url
            ? <div className={`w-full h-full rounded-full bg-cover bg-center`} style={{ backgroundImage: `url('${user.profilePicture.url}')`}} aria-label={user.profilePicture.alternativeText ? user.profilePicture.alternativeText as string : `${user?.firstName} ${user?.lastName}'s profile`} />
            : <span className="text-primary-900 text-xl font-bold">{user?.firstName?.slice(0, 1)}</span>}
    </div>
};
