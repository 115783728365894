import { FC, HTMLAttributes } from 'react';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { merge } from '../helpers/ui';
import { Button } from './Button';

export enum Vote {
  NONE,
  UP,
  DOWN
}

interface VoteButtonsProps extends HTMLAttributes<HTMLDivElement> {
  onVote: (up: boolean) => void,
  upVotes: number,
  downVotes?: number,
  currentVote?: Vote,
  loading?: boolean
}

export const VoteButtons: FC<VoteButtonsProps> = ({ className, onVote, currentVote, upVotes, downVotes, loading }) => {
  return <div className={merge("flex gap-10 ", className)}>
    <div className="flex gap-2 items-center">
      <Button
        compact
        className={merge("border-none", currentVote === Vote.UP ? "text-primary-500" : "text-gray-200")}
        onClick={() => onVote(true)}
        loading={loading}
      >
        <FiThumbsUp className="w-6 h-6" />
      </Button>
      <div className="font-bold">
        {upVotes}
      </div>
    </div>
    {downVotes !== undefined && <div className="flex gap-2 items-center">
      <Button
        compact
        className={merge("border-none", currentVote === Vote.DOWN ? "text-secondary-600" : "")}
        onClick={() => onVote(false)}
        loading={loading}
      >
        <FiThumbsDown className="w-6 h-6" />
      </Button>
      <div className="font-bold">
        {downVotes}
      </div>
    </div>}
  </div>
};