import { FC, HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: string
  weight?: string
  tight?: boolean
}

export const Title: FC<TitleProps> = ({ children, className, size = '4xl', tight = false, weight = 'bold', ...props }) => {

  // text-sm text-lg text-base text-xl text-2xl text-3xl text-4xl text-5xl text-6xl text-7xl text-8xl
  return <h1 className={merge(`text-${size}`, (size.includes('xl') && tight) || tight ? 'tracking-tight' : '', `font-${weight}`, className)} {...props}>
    { children }
  </h1>;
}
