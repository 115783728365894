import { FC } from 'react';
import { InputProps, withField } from '.';
import { merge } from '../../helpers/ui';

interface RadioInputProps extends InputProps<{ [key: string]: any } | undefined> {
  options?: { id: string, name: string }[]
}

export const RadioInput: FC<RadioInputProps> = ({ className, onChange, name, value, placeholder, options, ...props }) => {
  return <div>
    {options?.map((option) => {
      return <div className="flex items-center mb-1">
        <input
        {...props}
        name={name}
        id={option.id}
        type="radio"
        checked={option.id === value?.id}
        className={merge(className, 'focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300')}
        onChange={() => onChange(option)}
      />
        <label htmlFor={option.id} className="ml-3 block font-medium text-gray-700">
          {option.name}
        </label>
      </div>
    })}
  </div>
};

export const RadioField = withField(RadioInput);
