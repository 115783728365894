import { FC } from 'react';
import { useAuthenticationService } from '../../authentication/authentication-service';
import { getDateAndTimeToDisplay } from '../../helpers/date';
import { merge } from '../../helpers/ui';
import { Message } from '../../services/graphql';
import { ConversationAttachment } from './attachments/ConversationAttachment';

interface ConversationMessageProps {
  message: Message,
  rightAlignMessage?: boolean
}

export const ConversationMessage: FC<ConversationMessageProps> = ({message, rightAlignMessage}) => {
  const user = useAuthenticationService(state => state.user);

  const sentAt = new Date(message.created_at);
  const sentByMe = (message.sentBy!.id === user!.id.toString());

  let className: string = "flex flex-col px-4 py-3 mx-5 my-1 rounded-lg max-w-lg relative";
  let nameToDisplay = `${message.sentBy!.firstName} ${message.sentBy!.lastName}`;
  if (sentByMe) {
    className = merge(className, "bg-primary-200 font-medium");
    nameToDisplay = "You";
  } else {
    className = merge(className, "bg-white  rounded-bl-none");
  }

  if (sentByMe || rightAlignMessage) {
    className = merge(className, "self-end rounded-br-none");
  }

  let attachment: JSX.Element;

  switch (message.attachment?.type) {
    case "conversation":
      attachment = <ConversationAttachment conversation={message.attachment.conversation}/>;
      break;
    default: 
      attachment = <></>;
      break;
  }

  return <div className={className}>
    <svg className={merge(
      'absolute bottom-0',
      sentByMe ? 'text-primary-200' : 'text-white',
      (sentByMe || rightAlignMessage) ? "-right-1.5" : "-left-1.5"
    )} width="9" height="22" style={(sentByMe || rightAlignMessage) ? { transform: 'scale(-1, 1)' } : {}} viewBox="0 0 9 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C8 0 8.00851 0.272212 8 0.75894V22H1.76601C0.740547 22 0.270676 20.4607 1.01463 19.7549C7.31955 13.7736 7.95029 3.60332 8 0.75894V0Z" />
    </svg>
    <div className="text-xs w-full flex justify-between">
      <span className="font-bold mr-2">{nameToDisplay}</span>
      <span>{getDateAndTimeToDisplay(sentAt)}</span>
    </div>
    <div className="whitespace-pre-line">{message.text}</div>
    {attachment}
  </div>;
};
