import { FC } from 'react';
import { TextAreaInput } from '../../shared/inputs/TextAreaInput';
import { ConversationSendButton } from './ConversationSendButton';

import i18n from '../../translations/Conversation';
import React from 'react';

interface ConversationTextInputProps {
  value: string,
  onChange: (value: string) => void,
  onSend: () => void,
  loading: boolean
}

export const ConversationTextInput: FC<ConversationTextInputProps> = (props: ConversationTextInputProps) => {
  const onKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      props.onSend();
    }
  }

  return <div className="flex items-end space-x-2">
    <TextAreaInput
      name="chat-input"
      className="w-full"
      placeholder={i18n.message.placeholder}
      value={props.value}
      onChange={value => props.onChange(value)}
      onKeyPress={onKeyPress}
      adaptiveHeight
    />
    <ConversationSendButton onClick={() => props.onSend()} loading={props.loading} />
  </div>
};
