import { FC, HTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Group, Level, Organisation, useGetGroupsQuery, useGetLevelsQuery, useGetOrganisationsQuery } from '../../../services/graphql';
import { DropdownField } from '../../../shared/inputs/DropdownInput';
import { Title } from '../../../shared/Title';
import i18n from '../../../translations/User';


interface OrganisationDetailsSectionProps extends HTMLAttributes<HTMLDivElement> {
}

export const OrganisationDetailsSection: FC<OrganisationDetailsSectionProps> = ({ children,  ...props }) => {
  const [organisations, setOrganisations] = useState<Pick<Organisation, 'id' | 'name'>[]>();
  const [levels, setLevels] = useState<Pick<Level, 'id' | 'name'>[]>();
  const [groups, setGroups] = useState<Pick<Group, 'id' | 'name'>[]>();

  const methods = useFormContext();

  useGetOrganisationsQuery({
    onCompleted: data => {
      const organisations = data?.organisations;
      if (!organisations) return;
      setOrganisations(organisations);
    }
  });

  useGetLevelsQuery({
    onCompleted: data => {
      const levels = data?.levels;
      if (!levels) return;
      setLevels(levels);
    }
  });

  const selectedOrganisationId = methods.watch('organisation.id');

  useGetGroupsQuery({
    variables: { id: selectedOrganisationId },
    onCompleted: data => {
      const groups = data?.organisation?.groups;
      if (!groups) return;
      setGroups(groups);
    },
    skip: !selectedOrganisationId
  })


  return <>
    <Title size="xl" className="sm:col-span-3 mt-8">{i18n.profile.courseSummary.academicDetails}</Title>
    <DropdownField
      name="organisation"
      options={organisations}
      disabled
      title={i18n.editProfile.fields.university.label}
      requiredMessage={i18n.editProfile.fields.university.requiredMessage}
    />
    <DropdownField
      name="level"
      options={levels}
      title={i18n.editProfile.fields.levels.label}
      requiredMessage={i18n.editProfile.fields.levels.requiredMessage}
    />
    <DropdownField
      name="group"
      options={groups}
      title={i18n.editProfile.fields.groups.label}
      requiredMessage={i18n.editProfile.fields.groups.requiredMessage}
    />
  </>
};
