import { FC } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Login } from './authentication/components/Login';
import { Logout } from './authentication/components/Logout';
import { Register } from './authentication/components/Register';
import AuthenticatedRoute from './authentication/routes/AuthenticatedRoute';
import { Header } from './header/Header';
import { GraphQLProvider } from './helpers/apollo';
import { ConversationPage } from './conversations/ConversationPage';
import { EditProfilePage } from './user/editProfile/EditProfilePage';
import { Home } from './home/Home';
import { ModeratorHome } from './home/moderator/ModeratorHome';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { appInsights, history, reactPlugin } from './helpers/log';
import { ProfilePage } from './user/profilePage/ProfilePage';
import { Onboarding } from './authentication/components/Onboarding';
import { ForumPostPage } from './forum/ForumPostPage';
import { ForumPage } from './forum/ForumPage';
import { ForumCreatePostPage } from './forum/ForumCreatePostPage';
import { MentorSearch } from './mentors/MentorSearch';
import { ReportsPage } from './reports/ReportsPage';
import { CoursePage } from './course/CoursePage';
import { Role, useAuthenticationService } from './authentication/authentication-service';
import { ContactModerator } from './conversations/ContactModerator';
import { ForgottenPassword } from './authentication/components/ForgottenPassword';
import { ResetPassword } from './authentication/components/ResetPassword';
import { ResetPasswordConfirmed } from './authentication/components/ResetPasswordConfirmed';
import { ForgottenPasswordConfirmed } from './authentication/components/ForgottenPasswordConfirmed';

appInsights.loadAppInsights();

const DynamicHome: FC = () => {
  const user = useAuthenticationService(state => state.user);
  console.log(user?.role)
  return user?.role.id.toString() === Role.Moderator ? <ModeratorHome></ModeratorHome> : <Home></Home>;
}

const App: FC = () => {
  return (
    <GraphQLProvider>
      <Router history={history}>
        <main className="h-screen w-screen flex flex-col text-gray-700">
          <Switch>
            <Route exact={true} path="/login" component={Login} />
            <Route exact={true} path="/logout" component={Logout} />
            <Route exact={true} path="/register" component={Register} />
            <Route exact={true} path="/onboarding" component={Onboarding} />
            <Route exact={true} path="/forgotten" component={ForgottenPassword} />
            <Route exact={true} path="/request-sent" component={ForgottenPasswordConfirmed} />
            <Route exact={true} path="/reset/:code?" component={ResetPassword} />
            <Route exact={true} path="/reset-confirmed" component={ResetPasswordConfirmed} />
            <Route>
              <Header/>
              <div className="flex flex-col flex-1 overflow-auto relative">
                <Switch>
                  <AuthenticatedRoute path="/messages/:id?" component={ConversationPage} />
                  <AuthenticatedRoute path="/reports/:id?" component={ReportsPage} />
                  <AuthenticatedRoute path="/profile/:id?" component={ProfilePage} />
                  <AuthenticatedRoute path="/course/:id?" component={CoursePage} />
                  <AuthenticatedRoute path="/mentors/:searchTerm?" component={MentorSearch} />
                  <AuthenticatedRoute path="/contactModerator/" component={ContactModerator}/>
                  <AuthenticatedRoute path="/forum/posts/create/:groupId?" component={ForumCreatePostPage} />
                  <AuthenticatedRoute path="/forum/posts/:forumId?" component={ForumPostPage} />
                  <AuthenticatedRoute path="/forum/" component={ForumPage} />
                  <AuthenticatedRoute path="/edit_profile" component={EditProfilePage} />
                  <AuthenticatedRoute path="/" component={DynamicHome} />

                </Switch>
              </div>
            </Route>
          </Switch>
        </main>
      </Router>
    </GraphQLProvider>
  );
}

export default withAITracking(reactPlugin, App);
