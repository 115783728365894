import { FC, HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';

interface ChitListProps extends HTMLAttributes<HTMLDivElement> {
  secondary?: boolean,
  chits: string[]
}

export const ChitList: FC<ChitListProps> = ({ secondary, chits, className }) => {
  const backgroundColor = secondary ? "bg-secondary-200" : "bg-primary-200";
  return <div className={merge('flex flex-wrap gap-2 text-sm', className)}>
    {chits.map((chitText, index) => {
      return <div key={index} className={merge("px-2 py-1 font-semibold rounded max-w-2xs whitespace-nowrap overflow-hidden overflow-ellipsis", backgroundColor)}>
        {chitText}
      </div>
    })}
  </div>
};
