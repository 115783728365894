import { FC } from "react";
import { useParams } from "react-router-dom";
import { Group, useGetForumQuery } from "../services/graphql";
import { ContentLoader } from "../shared/ContentLoader";
import { ErrorMessage } from "../shared/ErrorMessage";
import { PageHelmet } from "../shared/Helmet";
import { PageContainer } from "../shared/PageContainer";
import i18n from "../translations/Forum";
import { ForumCreatePost } from "./ForumCreatePost";
import { ForumCreatePostHeader } from "./ForumCreatePostHeader";

interface ForumCreatePostPageParams {
    groupId: string
}

interface ForumCreatePostPageProps { }

export const ForumCreatePostPage: FC<ForumCreatePostPageProps> = () => {
    const { groupId } = useParams<ForumCreatePostPageParams>();

    const { data, loading, error } = useGetForumQuery({
        variables: {
            id: groupId
        }
    });

    return <ContentLoader loading={loading} error={error}>
        <PageHelmet pageTitle={i18n.helmets.createNewPost}/>
        {data && data.group && <ForumCreatePostHeader course={data.group as Group} />}
        <PageContainer>
            {data && data.group && <>
                <ForumCreatePost group={data.group as Group} />
            </>}
            {data && !data.group && <ErrorMessage message={i18n.forumNotFound} />}
        </PageContainer>
    </ContentLoader>
}