export const ellipsizeText = (text: string, maxLength: number) => {
    if (text.length < maxLength) {
        return text;
    }

    let cutText = text.substr(0, maxLength);
    const lastSpace = cutText.lastIndexOf(" ");
    cutText = cutText.substr(0, lastSpace);

    return (cutText + "...")
}
