import { useHistory } from "react-router-dom";
import { useAuthenticationService } from "../authentication/authentication-service";
import { dateToGQLFormat } from "../helpers/date";
import { Conversation, useCreateMessageMutation, useStartConversationMutation } from "../services/graphql";
import i18n from "../translations/Conversation";

interface StartConversationOptions {
    conversationToAttach?: Conversation
}

export const useStartConversation = () => {
    const [createConversation, createConversationStatus] = useStartConversationMutation();
    const [createMessage, createMessageStatus] = useCreateMessageMutation();

    const history = useHistory();
    const myUser = useAuthenticationService(state => state.user);
    const myUserId = myUser?.id.toString();

    const startChat = async (id: string, options: StartConversationOptions = {}) => {
        if (!myUserId) return;
        const result = await createConversation({
            variables: {
                input: {
                    data: {
                        initiatedAt: dateToGQLFormat(new Date()),
                        created_by: myUserId,
                        initiatedBy: myUserId,
                        members: [
                            myUserId,
                            id
                        ]
                    }
                }
            }
        });

        const newConversationId = result.data?.createConversation?.conversation?.id;

        if (newConversationId && options.conversationToAttach) {
            await createMessage(
                {
                    variables: {
                        input: {
                            data: {
                                text: i18n.attachment.conversation.regarding,
                                conversation: newConversationId,
                                sentAt: dateToGQLFormat(new Date()),
                                sentBy: myUser?.id.toString(),
                                attachment: {
                                    type: "conversation",
                                    conversation: options.conversationToAttach
                                }
                            }
                        }
                    }
                }
            );
        }

        if (newConversationId) {
            history.push(`/messages/${newConversationId}`);
        }
    }

    return { 
        startChat, 
        loading: createConversationStatus.loading || createMessageStatus.loading,
        error: createConversationStatus.error || createMessageStatus.error
    }
}
