import { FC, HTMLAttributes } from "react";
import { FiMessageSquare, FiThumbsUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getDateAndTimeToDisplay } from "../helpers/date";
import { ellipsizeText } from "../helpers/text";
import { merge } from "../helpers/ui";
import { ForumPost } from "../services/graphql";
import { Card } from "../shared/Card";
import { ChitList } from "../shared/ChitList";
import { TextSeparator } from "../shared/TextSeparator";

interface PostSummaryProps extends HTMLAttributes<HTMLDivElement> {
    post: ForumPost
}

const maxPostLength = 144;

export const PostSummary: FC<PostSummaryProps> = ({ post, className }) => {
    return <Card className={merge(className, "p-6 flex items-start flex-col")}>
        <Link to={`/forum/posts/${post.id}`} className="font-bold mb-1 text-primary-600">{post.title}</Link>
        <div className="flex-1 flex flex-col items-start text-left">
            <div>
                {post.text && ellipsizeText(post.text, maxPostLength)}
            </div>
            {post.activities && <ChitList
                className="my-2"
                chits={post.activities?.map(activity => activity.name as string)}
            />}
        </div>
        <div>
            <div className="flex">
                <Link to={`/profile/${post.postedBy?.id}`} className="font-bold">{`${post.postedBy?.firstName} ${post.postedBy?.lastName}`}</Link>
                <TextSeparator/>
                {getDateAndTimeToDisplay(post.published_at)}
            </div>
            <div className="flex gap-5">

                <div className="flex items-center">
                    <FiMessageSquare />
                    <span className="font-bold ml-1">{post.comments?.length || 0}</span>
                </div>
                <div className="flex items-center">
                    <FiThumbsUp />
                    <span className="font-bold ml-1">{post.upvotedBy?.length || 0}</span>
                </div>
            </div>
        </div>

    </Card>;
}
