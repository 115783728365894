import { HTMLAttributes, forwardRef } from 'react';
import { merge } from '../helpers/ui';
import { Loader } from './Loader';

interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  loading?: boolean
  compact?: boolean
  primary?: boolean
  submit?: boolean
  secondary?: boolean
  round?: boolean
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, loading, submit, compact, primary, secondary, round, className, onClick, ...props}, ref) => {
  return <button type={submit ? 'submit' : 'button'} className={merge(
    'inline-flex relative overflow-hidden text-sm items-center justify-center border shadow-sm leading-4 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2',
    (compact) ? 'px-2 py-2' : 'px-3 py-2.5',
    (!primary && !secondary) ? 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-primary-400 font-medium' : null,
    (primary) ? 'border-primary-700 text-white bg-primary-600 hover:bg-primary-700 focus:ring-primary-500 font-medium' : null,
    (secondary) ? 'border-secondary-600 text-secondary-900 bg-secondary-500 hover:bg-secondary-600 focus:ring-secondary-500 font-semibold' : null,
    (round) ? 'rounded-full' : null,
    className
  )} onClick={(e) => {
    if (!submit) e.preventDefault()
    onClick?.(e)
  }} {...props}>
    { loading ? <span className={merge(
        'absolute inset-0 flex justify-center items-center',
        (!primary && !secondary) ? 'bg-white ' : null,
        (primary) ? 'bg-primary-700 ' : null,
        (secondary) ? 'bg-secondary-600 ' : null
      )}>
      <Loader className="w-6 h-6" />
    </span> : null }
    { children }
  </button>
});
