import { FC } from 'react';
import { InputProps, withField } from '.';
import { merge } from '../../helpers/ui';

interface DropdownInputProps extends InputProps<{ [key: string]: any } | undefined> {
  placeholder?: string
  options?: { id: string, name: string }[]
}

export const DropdownInput: FC<DropdownInputProps> = ({ className, name, onChange, value, placeholder, options, ...props }) => {
  return <select
    {...props}
    name={name}
    id={name}
    className={merge(className, 'block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md')}
    value={value?.id}
    onChange={(event) => onChange(options?.find(option => option.id === event.target.value))}
  >
    { !value ? <option>{ placeholder ? placeholder : 'Please select' }</option> : null }
    {options?.map(option => <option key={option.id} value={option.id}>{option.name}</option>)}
  </select>
};

export const DropdownField = withField(DropdownInput);
