import { FC } from "react";
import { Group, useGetContentQuery, useGetMyForumQuery } from "../services/graphql";
import { Card } from "../shared/Card";
import { ContentLoader } from "../shared/ContentLoader";
import { PageContainer } from "../shared/PageContainer";
import { Forum } from "./Forum";
import { ForumHeader } from "./ForumHeader";
import i18n from "../translations/Forum";
import { PageHelmet } from "../shared/Helmet";

interface ForumPageProps { }

export const ForumPage: FC<ForumPageProps> = () => {
    const { data: forumData, loading: forumLoading, error: forumError } = useGetMyForumQuery();
    const { data: contentData, loading: contentLoading, error: contentError } = useGetContentQuery();

    const intro = contentData?.forumIntro?.content;
    const group = forumData?.me?.user?.group as Group;

    return <ContentLoader loading={forumLoading || contentLoading} error={forumError || contentError}>
        {group && intro && <>
            <PageHelmet pageTitle={i18n.helmets.forum(group.name)}/>
            <ForumHeader course={group} />
            <PageContainer>
                <Card className="mb-5 p-5 whitespace-pre-line">
                    {intro}
                </Card>
                <Forum group={group} />
            </PageContainer>
        </>}
    </ContentLoader>
}