import { HTMLAttributes, useState } from "react";
import { FC } from "react";
import { merge } from "../helpers/ui";
import { ForumLink } from "./ForumLink";
import { ForumPost, Group } from "../services/graphql";
import { Card } from "../shared/Card";
import { Button } from "../shared/Button";
import i18n from "../translations/Forum";
import { useHistory } from "react-router-dom";
import { DropdownInput } from "../shared/inputs/DropdownInput";
import { Pager } from "../shared/Pager";

interface ForumProps extends HTMLAttributes<HTMLDivElement> {
    group: Group
}

const postsPerPage = 10;

export const Forum: FC<ForumProps> = ({ group, className }) => {
    const history = useHistory();
    const [page, setPage] = useState<number>(1);

    const totalPosts = group.forumPosts?.length || 0;
    const numberOfPages = Math.ceil(totalPosts / postsPerPage);

    const activityOptions: { id: string, name: string }[] = group.activities?.map(activity => {
        return {
            id: activity.id as string,
            name: activity.name as string
        }
    }) || [];

    const seeAllId = "-1";
    const seeAll = {
        id: seeAllId,
        name: i18n.forum.filter.showAll
    };
    activityOptions.unshift(seeAll);

    const [selectedActivity, setSelectedActivity] = useState<{ id: string, name: string }>(seeAll);

    const isPostRelevant = (post: ForumPost) => {
        if (selectedActivity.id === seeAllId) {
            return true;
        }

        return post.activities?.some(activity => activity.id === selectedActivity.id)
    }

    const postsToShow = group.forumPosts?.filter(isPostRelevant).slice((page - 1) * postsPerPage, page * postsPerPage);

    return <Card className={merge(className, "flex flex-col items-start")}>
        <div className="flex justify-between flex-col sm:flex-row gap-2 w-full border-b p-5">
            <div>
                <DropdownInput
                    options={activityOptions}
                    name="activityFilter"
                    value={selectedActivity}
                    onChange={(value) => value && setSelectedActivity(value as { id: string, name: string })}
                />
            </div>
            <Button onClick={() => history.push(`/forum/posts/create/${group.id}`)} primary>{i18n.forum.create.label}</Button>
        </div>
        {postsToShow && postsToShow.length > 0 ? <div className="w-full">
            {postsToShow.map((post, index) => <ForumLink
                key={index}
                className="w-full px-5 py-4 border-b"
                post={post}
            />)}
            <Pager
                currentPage={page}
                totalPages={numberOfPages}
                onPage={(next) => setPage(next ? page + 1 : page - 1)}
            />
        </div> : <div className="m-5">{i18n.noPosts}</div>}
    </Card>;
}
