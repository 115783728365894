import { FC } from 'react';
import { UsersPermissionsUser } from '../../services/graphql';
import { HTMLAttributes } from 'react';
import { ChitList } from '../../shared/ChitList';
import { ProfileIcon } from '../../shared/ProfileIcon';
import { TextSeparator } from '../../shared/TextSeparator';
import { Link } from 'react-router-dom';

interface MentorSearchListResultProps extends HTMLAttributes<HTMLDivElement> {
  mentor: UsersPermissionsUser
}

export const MentorSearchListResult: FC<MentorSearchListResultProps> = ({ mentor }) => {
  return <div className="border-b flex flex-col gap-2 p-2">
    <div>
      <Link to={`/profile/${mentor.id}`} className="flex h-full gap-5 items-center">
        <ProfileIcon user={mentor} className="h-10 w-10" />
        <div className="flex flex-1 flex-col items-start">
          <span className="font-bold text-primary-500">{`${mentor.firstName} ${mentor.lastName}`}</span>
          <div className="flex">
            {mentor.group?.name}
            {mentor.group && mentor.level && <TextSeparator />}
            {mentor.level?.name}
          </div>
        </div>
      </Link>
    </div>
    <div>{mentor.bio}</div>
    {mentor.completedActivities && <ChitList chits={mentor.completedActivities.map(activity => activity.name as string)} />}
  </div>
};
