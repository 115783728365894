import { FC } from 'react';
import { InputProps, withField } from '.';
import { merge } from '../../helpers/ui';

interface PasswordInputProps extends InputProps<string> {}

export const PasswordInput: FC<PasswordInputProps> = ({ className, name, onChange, value, ...props }) => {
  return <div>
    <input
      {...props}
      type="password"
      name={name}
      id={name}
      className={merge('shadow-sm focus:ring-primary-500 bg-white focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md', className)}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
};

export const PasswordField = withField(PasswordInput);
