import { FC, Fragment, useState } from 'react';
import { HeaderLink } from './HeaderLink';
import { HeaderLogo } from './HeaderLogo';
import { HeaderMenuItem } from './HeaderMenuItem';
import { FiMail, FiUser, FiMenu, FiLogOut } from "react-icons/fi";

import i18n from '../translations/Navigation';
import { useAuthenticationService } from '../authentication/authentication-service';

interface HeaderProps { }

interface HeaderPageLink {
  label: string,
  link: string,
  icon?: JSX.Element
}

export const Header: FC<HeaderProps> = () => {
  const [menuOpen, setMenuOpen] = useState<Boolean>(false);
  const user = useAuthenticationService(state => state.user);

  const forum = user?.organisation.hideForum ? null
  : { label: i18n.forum.name,
    link: "/forum" }

  const menuLinksMentee: (HeaderPageLink | null)[] = [
    { label: i18n.mentors.name,
      link: "/mentors" },
    forum,
    { label: i18n.contactMod.name,
      link: "/contactModerator" },
  ]

  const menuLinksModerator: HeaderPageLink[] = [
    {
      label: i18n.reports.name,
      link: "/reports"
    },
    {
      label: i18n.mentors.name,
      link: "/mentors"
    },
  ]

  const iconLinks: HeaderPageLink[] = [
    {
      label: i18n.inbox.name,
      link: "/messages",
      icon: <FiMail className="mt-0.5 w-5 h-5" />
    },
    {
      label: i18n.profile.name,
      link: `/profile/${user?.id}`,
      icon: <FiUser className="w-5 h-5" />
    },
    {
      label: i18n.logout.name,
      link: "/logout",
      icon: <FiLogOut className="w-5 h-5"/>
    }
  ]

  const closeMenu = () => {
    setMenuOpen(false);
    window.removeEventListener('click', closeMenu);
  }

  const handleMenuClick = () => {
    if (menuOpen) {
      setTimeout(() => window.removeEventListener('click', closeMenu), 0);
    } else {
      setTimeout(() => window.addEventListener('click', closeMenu), 0);
    }

    setMenuOpen(!menuOpen);
  }

  const menuLinks = user?.role.name === "Moderator" ? menuLinksModerator : menuLinksMentee;

  return <div className="flex flex-col justify-between items-center relative bg-white border-b border-gray-200">
    <div className="max-w-7xl w-full mx-auto px-4 sm:px-4 lg:px-8 flex justify-between items-center">
      <div className="py-3 mr-3 flex items-center">
        <button onClick={handleMenuClick} className="hover:bg-gray-50 sm:hidden p-2 mr-3 rounded focus:outline-none focus:ring-2 focus:ring-primary-500">
          <FiMenu className="w-6 h-6 text-primary-600" />
        </button>
        <HeaderLogo />
      </div>
      <div className="flex-1 hidden space-x-2 sm:block">
        {
          menuLinks.map(page => {
            if (!page) return <Fragment />;
            return <HeaderLink
              key={page.label}
              label={page.label}
              link={page.link}
              icon={page.icon}
            />
          })
        }
      </div>
      <div className="space-x-2">
        {
          iconLinks.map(page => <HeaderLink
            key={page.label}
            label={page.label}
            link={page.link}
            icon={page.icon}
          />)
        }
      </div>
    </div>
    {menuOpen && <div className="w-full sm:hidden">
      {
        menuLinks.map(page => {
          if (!page) return <Fragment />;
          return <HeaderMenuItem
            key={page.label}
            label={page.label}
            link={page.link}
          />
      })
      }
    </div>}
  </div>
};
