import { FC } from 'react';
import { Review } from '../../services/graphql';
import { Label } from '../../shared/Label';
import { StarRating } from '../../shared/StarRating';
import { Title } from '../../shared/Title';
import i18n from "../../translations/Conversation";

interface ReviewSummaryProps {
  review: Review
}

export const ReviewSummary: FC<ReviewSummaryProps> = ({ review }) => {
  const worstRating = Math.min(review.knowledgeRating || 0, review.helpfulRating || 0, review.speedRating || 0);

  return <div className="border bg-white flex flex-col w-full rounded-lg items-center p-4 my-4 self-center">
    <Title className="mb-3" size="lg">{i18n.review.summaryTitle}</Title>
    {worstRating < 3 && <div className="italic mb-3">
      {i18n.review.badReviewDisclaimer(review.about?.firstName || "")}
    </div>}
    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-4 mb-3">
      <span className="flex flex-col justify-center space-y-1 items-center">
        <Label>{i18n.review.speed}</Label>
        { review.speedRating ? <StarRating buttonSize={8} value={review.speedRating} /> : null }
      </span>

      <span className="flex flex-col justify-center space-y-1 items-center">
        <Label>{i18n.review.knowledge}</Label>
        { review.knowledgeRating ? <StarRating buttonSize={8} value={review.knowledgeRating} /> : null }
      </span>

      <span className="flex flex-col justify-center space-y-1 items-center">
        <Label>{i18n.review.helpfulness}</Label>
        { review.helpfulRating ? <StarRating buttonSize={8} value={review.helpfulRating} /> : null }
      </span>
    </div>
    <div>
      {review.text}
    </div>
  </div>;
};
