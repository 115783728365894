import { FC } from 'react';
import { merge } from "../helpers/ui";
import { HTMLAttributes } from 'react';
import { FillStar } from './FilledStar';

interface StarRatingProps extends HTMLAttributes<HTMLDivElement> {
  value?: number,
  onRatingChange?: (rating: number) => void,
  buttonSize?: number,
  withValue?: boolean
}

export const StarRating: FC<StarRatingProps> = ({ onRatingChange, className, buttonSize, value, withValue }) => {
  const size: number = buttonSize || 12;
  const sizeClass = `h-${size} w-${size}`;
  let buttonClass = merge(`text-secondary-500`, sizeClass);
  if (onRatingChange) {
    buttonClass = merge(buttonClass, "hover:text-secondary-600");
  } else {
    buttonClass = merge(buttonClass, "cursor-default");
  }

  return <div className={merge('space-x-2 flex', className)}>
    {[1, 2, 3, 4, 5].map(rating => <button
      tabIndex={onRatingChange ? undefined : -1}
      type="button"
      className={buttonClass}
      onClick={() => onRatingChange && onRatingChange(rating)}
    >
      <FillStar buttonSize={buttonSize} value={value ? value - rating + 1 : 0}/>
    </button>)}
    {withValue && <div className="font-bold text-lg bg-secondary-200 rounded-sm ml-2 px-3 flex items-center justify-center">
      {value?.toPrecision(2) || 0}
    </div>}
  </div>
};
