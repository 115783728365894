import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    helmets: {
      courseDetails: "Course Details"
    },
    home: {
      moderators: {
        title: "Moderators"
      },
      quickLinks: {
        title: "Links",
        links: {
          mentors: "Mentors",
          forum: "Forum"
        }
      },
      modules: {
        title: "Modules"
      },
      topPosts: {
        title: "Top Posts"
      },
      courses: {
        title: "Courses"
      }
    }
  }
})
