import { FC, HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { merge } from '../helpers/ui';
import { TextSeparator } from './TextSeparator';

export interface breadcrumb {
  name: string,
  link?: string
}
interface PageHeaderProps extends HTMLAttributes<HTMLDivElement> {
  logo?: JSX.Element,
  title?: string,
  breadcrumbs?: breadcrumb[],
  additionalComponent?: JSX.Element
}

export const PageHeader: FC<PageHeaderProps> = ({ className, logo, title, breadcrumbs, additionalComponent }) => {
  return <div className={merge('bg-white border-b border-gray-200 shadow-sm p-2 sm:p-5', className)}>
    <div className="max-w-7xl w-full mx-auto px-4 sm:px-4 lg:px-8">
      <div className="flex w-full justify-between items-center text-primary-700">
        <div className="flex h-10  sm:h-16 gap-5 items-center">
          {logo && <div className="h-full w-10 sm:w-16">
            {logo}
          </div>}
          <div className="flex flex-col">
            <span className="font-bold sm:text-2xl">{title}</span>
            <div className="flex items-center">
              {breadcrumbs?.map((item, index) => <>
                {index !== 0 && <TextSeparator />}
                {item.link ?
                  <Link to={item.link} className="whitespace-nowrap">{item.name}</Link> :
                  <span className="whitespace-nowrap">{item.name}</span>
                }
              </>)}
            </div>
          </div>
        </div>
        {additionalComponent}
      </div>
    </div>
  </div>
};
