import { addHeadingToBody, addLineToBody } from '../../helpers/email';
import { Enum_Report_Reportsubject, Report } from '../../services/graphql';
import i18n from "../../translations/Moderators";
import { getProblemSummary } from '../ReportProblemSummary';

export const useReportEmailSummary = (report: Report) => {
    const subject = i18n.emailReport.subject(report.reportee!, getProblemSummary(report.reportType!));

    let body: string = "";
    body = addHeadingToBody(body, i18n.emailReport.problemSummary);
    body = addLineToBody(body, i18n.emailReport.reporter(report.reporter!));
    body = addLineToBody(body, i18n.emailReport.reportee(report.reportee!));
    body = addLineToBody(body, i18n.emailReport.madeAt(report.created_at));
    body = addLineToBody(body, i18n.emailReport.reason(getProblemSummary(report.reportType!)));
    addLineToBody(body, i18n.emailReport.description(report.issue));

    const buildConversationSummary = () => {
        body = addHeadingToBody(body, i18n.emailReport.conversation.chatHistory);
        report.conversation?.messages?.forEach(message => {
            body = addLineToBody(body, i18n.emailReport.conversation.message(message))
        });
    }

    const buildForumPostSummary = () => {
        body = addHeadingToBody(body, i18n.emailReport.forumPost.title);
        body = addLineToBody(body, i18n.emailReport.forumPost.postTitle(report.forumPost!.title));
        body = addLineToBody(body, i18n.emailReport.forumPost.postText(report.forumPost!.text!));
        body = addLineToBody(body, i18n.emailReport.forumPost.postedBy(report.forumPost!.postedBy!));
    }

    const buildForumCommentSummary = () => {
        body = addHeadingToBody(body, i18n.emailReport.comment.title);
        body = addLineToBody(body, i18n.emailReport.comment.originalPostTitle(report.comment!.forumPost!.title));
        body = addLineToBody(body, i18n.emailReport.comment.originalPostText(report.comment!.forumPost!.text!));
        body = addLineToBody(body, i18n.emailReport.comment.comment(report.comment!.text!));
        body = addLineToBody(body, i18n.emailReport.comment.postedBy(report.comment!.postedBy!));
    }

    switch (report.reportSubject) {
        case Enum_Report_Reportsubject.Conversation:
            buildConversationSummary();
            break;
        case Enum_Report_Reportsubject.Post:
            buildForumPostSummary();
            break;
        case Enum_Report_Reportsubject.Comment:
            buildForumCommentSummary();
            break;
    }

    return { subject, body };
};