import create, { State } from 'zustand';
import jwtDecode from 'jwt-decode';
import { log } from '../helpers/log';
import { client } from '../helpers/apollo';
import { Enum_Userspermissionsuser_Mentorstatus } from '../services/graphql';

export enum AuthenticationState {
  Authenticated = 'Authenticated',
  Unauthenticated = 'Unauthenticated'
}

export enum Role {
  Standard = '1',
  Moderator = '3'
}

export enum LoginProviders {
  Local = 'local'
}

export interface User {
  id: number | string,
  username: string,
  email: string,
  firstName?: string,
  lastName?: string,
  confirmed: boolean,
  blocked: boolean,
  isOnboarded?: boolean,
  profilePicture: {
    url: string
  }
  role: {
    id: Role,
    name: string
  },
  group?: {
    id: string
  },
  organisation: {
    hideForum?: boolean
  },
  mentorStatus: Enum_Userspermissionsuser_Mentorstatus
}

export interface AuthService extends State {
  state: AuthenticationState
  token?: string
  user?: User
  providers: LoginProviders[]

  setUserInfo: (token: string, user: User) => void
  updateUserInfo: (user: User) => void
  clearUserInfo: () => void
}

const insecurelyVerifyToken = (jwt: string) => {
  // This is insecure, it doesn't verify the signing of the token
  // or the appropriate audience, issuer, etc. That is done on the server.
  const token = jwtDecode<{ exp: number, iat: number, id: number }>(jwt);

  if (!token.exp || !token.id) {
    throw new Error('JWT token is malformed.');
  }

  if (token.exp < Math.round((new Date()).getTime() / 1000)) {
    throw new Error('JWT token has expired.');
  }
}

export class LoginError extends Error {
  public id: string

  constructor(id: string, message: string) {
    super(message);
    this.id = id;
  }
}

const handleErrors = (errors: { message: [{ messages: { id: string, message: string }[] }]}) => {
  const error = errors?.message?.[0]?.messages?.[0];
  if (!error) return;
  throw new LoginError(error.id, error.message)
}

const restoreUserFromLocalStorage = (): { state: AuthenticationState, token?: string, user?: User } => {
  const token = window.localStorage.getItem('token')
  const user = window.localStorage.getItem('user');

  try {
    if (token && user) {
      insecurelyVerifyToken(token);

      return {
        token,
        user: JSON.parse(user),
        state: AuthenticationState.Authenticated
      }
    } else {

      return {
        state: AuthenticationState.Unauthenticated
      }
    }
  } catch (e) {

    log('Authentication restoration failed', e);
    return {
      state: AuthenticationState.Unauthenticated
    }
  }
}

export const useAuthenticationService = create<AuthService>((set) => {
  return {
    ...restoreUserFromLocalStorage(),
    providers: [LoginProviders.Local],

    setUserInfo: (token: string, user: User) => set(state => {
      window.localStorage.setItem('token', token)
      window.localStorage.setItem('user', JSON.stringify(user));
      return { ...state, user, token, state: AuthenticationState.Authenticated }
    }),
    updateUserInfo: (user: User) => set(state => {
      window.localStorage.setItem('user', JSON.stringify(user));
      return { ...state, user }
    }),
    clearUserInfo: async () => {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user');
      await client.clearStore()
      set((state) => ({ ...state, user: undefined, token: undefined, state: AuthenticationState.Unauthenticated }))
    }
  }
})

export const getDefaultFetchOptions = () => {
  const { token } = useAuthenticationService.getState();
  const options: { headers: { [key: string]: string } } = { headers: { 'content-type': 'application/json', 'accept': 'application/json' } };
  if (token) options.headers['authorization'] = `Bearer ${token}`;
  const baseUrl = window.environment.baseUrl;
  return { options, baseUrl };
}

export const loginWithUsernameAndPassword = async (identifier: string, password: string) => {

  const { baseUrl, options } = await getDefaultFetchOptions();
  const response = await fetch(`${baseUrl}/auth/local`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ identifier, password })
  });

  if (!response.status.toString().includes('2')) {
    handleErrors(await response.json());
  }
  const result = await response.json();
  return result as { jwt: string, user: User }
}

export const registerWithUsernameAndPassword = async (email: string, password: string) => {

  const { baseUrl, options } = await getDefaultFetchOptions();
  const response = await fetch(`${baseUrl}/auth/local/register`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ username: email, email, password })
  });

  if (!response.status.toString().includes('2')) {
    handleErrors(await response.json());
  }
  const result = await response.json();
  return result as { jwt: string, user: User }
}

export const resendConfirmation = async (email: string) => {

  const { baseUrl, options } = await getDefaultFetchOptions();
  const response = await fetch(`${baseUrl}/auth/send-email-confirmation`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ email })
  });

  if (!response.status.toString().includes('2')) {
    handleErrors(await response.json());
  }
  const result = await response.json();
  return result as { jwt: string, user: User }
}

export const forgotPassword = async (email: string) => {
  const { baseUrl, options } = await getDefaultFetchOptions();
  const response = await fetch(`${baseUrl}/auth/forgot-password`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ email })
  });

  if (!response.status.toString().includes('2')) {
    handleErrors(await response.json());
  }
  const result = await response.json();
  return result as { jwt: string }
}

export const resetPassword = async (code: string, password: string, passwordConfirmation: string) => {
  const { baseUrl, options } = await getDefaultFetchOptions();
  const response = await fetch(`${baseUrl}/auth/reset-password`, {
    ...options,
    method: 'POST',
    body: JSON.stringify({ code, password, passwordConfirmation })
  });

  if (!response.status.toString().includes('2')) {
    handleErrors(await response.json());
  }
  const result = await response.json();
  return result as { jwt: string, user: User }
}
