import { FC, HTMLAttributes } from 'react';
import { FiMail, FiMessageSquare, FiStar } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { useAuthenticationService } from '../authentication/authentication-service';
import { merge } from '../helpers/ui';
import { CountIndicator } from '../shared/CountIndicator';

export enum NotificationType {
  MESSAGE,
  REVIEW,
  COMMENT
}

interface NotificationBoxProps extends HTMLAttributes<HTMLButtonElement> {
  type: NotificationType,
  count?: number,
  secondary?: boolean,
}

export const NotificationBox: FC<NotificationBoxProps> = ({ className, type, count, secondary }) => {
  const history = useHistory();
  const user = useAuthenticationService(state => state.user);

  let icon: JSX.Element;
  let link: string;

  const iconSize = "h-8 w-8";

  switch (type) {
    case (NotificationType.MESSAGE):
      icon = <FiMail className={iconSize} />;
      link = "/messages";
      break;
    case (NotificationType.REVIEW):
      icon = <FiStar className={iconSize} />;
      link = `/profile/${user?.id}`;
      break;
    case (NotificationType.COMMENT):
      icon = <FiMessageSquare className={iconSize} />;
      link = "/";
      break;
  }

  return <button onClick={() => history.push(link)} className={merge("flex relative pt-4 pb-2 px-2 text-white justify-center items-center rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-500   ", count !== undefined ? 'opacity-100' : 'opacity-30', className)}>
    {icon}
    { count && count > 0 ? <CountIndicator count={count} className="absolute top-0 right-0 z-10" secondary={secondary} /> : null }
  </button>
};
