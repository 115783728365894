import { FC } from 'react';
import { Activity } from '../services/graphql';
import { Card } from '../shared/Card';
import i18n from "../translations/Courses";
import { HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';
import { ChitList } from '../shared/ChitList';

interface CourseModulesCardProps extends HTMLAttributes<HTMLDivElement> {
  modules: Activity[]
}

export const CourseModulesCard: FC<CourseModulesCardProps> = ({ modules, className }) => {
  const moduleNames: string[] = modules.map(module => module.name as string);

  return <Card className={merge(className, "p-5")}>
    <div className="text-primary-500 mb-2">{i18n.home.modules.title}</div>
    <ChitList chits={moduleNames} />
  </Card>;
};
