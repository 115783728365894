import { FC } from 'react'
import { useAuthenticationService } from '../../authentication/authentication-service';
import { Enum_Userspermissionsuser_Mentorstatus, useEndConversationMutation } from '../../services/graphql';
import { Alert, AlertProps } from '../../shared/Alert';
import { Button } from '../../shared/Button';

import i18n from '../../translations/Conversation';

export interface ConfirmConversationEndAlertProps extends Omit<AlertProps, 'title' | 'description'> {
  firstName?: string | null
  id: string
}

export const ConfirmConversationEndAlert: FC<ConfirmConversationEndAlertProps> = ({ open, onClose, onSuccess, firstName, id }) => {
  const user = useAuthenticationService(state => state.user);
  const userIsMentor = user?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;

  const [endConversation, { loading }] = useEndConversationMutation();

  const name = firstName ?? 'Name';
  const menteeDescription = i18n.formatString(i18n.conversation.endConfirmation.menteeDescription, name) as string;
  const mentorDescription = i18n.formatString(i18n.conversation.endConfirmation.mentorDescription, name) as string;

  return <Alert
    open={open}
    onClose={onClose}
    title={i18n.conversation.endConfirmation.title}
    description={userIsMentor ? mentorDescription : menteeDescription}
    confirmButton={
      <Button
        className="w-full sm:w-auto"
        primary
        loading={loading}
        onClick={async () => {
          await endConversation({ variables: { id }})
          onSuccess?.();
      }}>{i18n.conversation.end}</Button>
    }
  />
}
