import { FC, HTMLAttributes } from 'react';

interface PointsCountProps extends HTMLAttributes<HTMLDivElement> {
  points: number
}

export const PointsCount: FC<PointsCountProps> = ({ points, className }) => {
  /*return <div className={merge("bg-secondary-500 border-2 border-secondary-700 font-bold text-white rounded p-2 flex flex-col items-center justify-center", className)}>
    <span className="text-xl">{points || 0}</span>
    <span className="text-sm">{i18n.points}</span>
  </div>;*/
  return <div/>
};