import { HTMLAttributes } from 'react';
import { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { merge } from '../../helpers/ui';
import { Label } from '../Label';
import i18n from "../../translations/Shared";

export interface InputProps<T> extends Omit<HTMLAttributes<HTMLElement>,'onChange'> {
  name: string
  onChange: (value: T) => void
  value: T,
}

export interface FieldProps extends HTMLAttributes<HTMLElement> {
  name: string
  title?: string
  requiredMessage?: string
  hideLabels?: boolean
  maxLength?: number
  [x: string]: any
}

export const withField = (Input: FC<InputProps<any>>): FC<FieldProps> => {

  const Field: FC<FieldProps> = ({ name, title, requiredMessage, hideLabels, maxLength, className, ...props }) => {
    const { register, watch, formState, setValue } = useFormContext();

    useEffect(() => {
      register(name, { required: requiredMessage, maxLength: maxLength })
    }, [name, register, requiredMessage, maxLength]);

    if (Object.keys(formState.errors).length !== 0) {
      console.log(formState.errors);
    }
    const value = watch(name);
    const error = formState.errors?.[name];

    const getErrorMessage = (error: any) => {
      if (error.type === "maxLength" && maxLength) {
        return i18n.inputs.maxLengthError(maxLength);
      } else if (error.message) {
        return error.message;
      }
    }

    return <div className={merge(className, 'flex-grow mb-2')}>
      {!hideLabels && <div className="flex justify-between">
        <Label className="mb-1">{title !== undefined ? title : name}</Label>
        { !requiredMessage ? <Label className="mb-1">{i18n.inputs.optional}</Label> : null }
      </div>}
      <Input {...props} name={name} value={value} onChange={(newValue) => setValue(name, newValue)}></Input>
      {error && <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>{getErrorMessage(error)}</p>}
    </div>
  }

  return Field;
}
