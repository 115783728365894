import { FC } from 'react';
import { Link } from 'react-router-dom';

interface HeaderLogoProps { }

export const HeaderLogo: FC<HeaderLogoProps> = () => {
  return <Link className=" p-2 hover:bg-primary-50 rounded focus:outline-none focus:ring-2 focus:ring-primary-500" to="/">
    <svg className="h-10" viewBox="0 0 578 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M155.74 50.98H135.67C135.295 50.9944 134.921 50.9256 134.575 50.7787C134.229 50.6317 133.92 50.4102 133.67 50.13C133.174 49.6211 132.895 48.9403 132.89 48.23V31.51C132.89 29.57 133.81 28.61 135.64 28.61H202.03C203.86 28.61 204.78 29.61 204.78 31.51V48.23C204.775 48.9403 204.496 49.6211 204 50.13C203.75 50.4102 203.441 50.6317 203.095 50.7787C202.749 50.9256 202.376 50.9944 202 50.98H181.85V121.75C181.865 122.123 181.799 122.494 181.658 122.839C181.516 123.184 181.302 123.495 181.03 123.75C180.787 123.997 180.498 124.193 180.178 124.327C179.859 124.461 179.516 124.53 179.17 124.53H158.58C156.65 124.53 155.68 123.61 155.68 121.78L155.74 50.98Z" fill="#175A84" />
        <path d="M231.81 116.1L211 53.1C210.981 52.7085 211.051 52.3177 211.205 51.9572C211.359 51.5968 211.594 51.2763 211.89 51.02C212.447 50.5423 213.156 50.2798 213.89 50.28H234.4C235.74 50.28 236.66 51.22 237.15 53.1L246.89 93.47L256.63 53.1C257.13 51.17 258.04 50.23 259.38 50.28H279.9C280.633 50.2858 281.34 50.5476 281.9 51.02C282.208 51.2693 282.452 51.5875 282.614 51.9489C282.775 52.3103 282.849 52.7046 282.83 53.1L261.83 117.85C260.257 122.934 258.138 127.833 255.51 132.46C253.628 135.84 251.301 138.951 248.59 141.71C246.365 143.846 243.756 145.543 240.9 146.71C238.529 147.735 236.035 148.447 233.48 148.83C230.986 149.103 228.479 149.226 225.97 149.2C222.92 149.306 219.867 149.068 216.87 148.49C214.331 147.718 211.878 146.686 209.55 145.41C209.056 144.998 208.655 144.487 208.373 143.91C208.09 143.333 207.933 142.702 207.91 142.06L208.06 141.4C208.16 141 208.46 139.59 208.95 137.16C209.44 134.73 210.11 131.38 210.95 127.16C211.116 126.744 211.403 126.387 211.774 126.136C212.145 125.885 212.582 125.75 213.03 125.75C216.335 126.892 219.795 127.519 223.29 127.61C226.863 127.61 229.167 126.693 230.2 124.86C231.29 122.96 231.81 120.07 231.81 116.1Z" fill="#175A84" />
        <path d="M321.29 124.5H300.62C299.92 124.495 299.247 124.231 298.73 123.76C298.447 123.511 298.224 123.203 298.075 122.857C297.926 122.511 297.856 122.136 297.87 121.76V71.83H291.78C291.382 71.8477 290.985 71.7761 290.618 71.6203C290.251 71.4646 289.924 71.2287 289.66 70.93C289.162 70.3824 288.884 69.6701 288.88 68.93V52.93C288.882 52.5818 288.956 52.2378 289.097 51.9194C289.238 51.6011 289.443 51.3153 289.7 51.08C289.972 50.8078 290.297 50.5945 290.655 50.4534C291.012 50.3123 291.396 50.2465 291.78 50.26H297.87V44.16C297.87 36.4267 300.35 30.3067 305.31 25.8C310.308 21.2738 316.86 18.852 323.6 19.04C327.594 19.0201 331.579 19.4189 335.49 20.23C336.262 20.3391 336.971 20.7182 337.49 21.3C337.998 21.7793 338.305 22.4332 338.35 23.13V38.22C338.346 38.9601 338.068 39.6724 337.57 40.22C337.31 40.5252 336.984 40.7669 336.617 40.9264C336.249 41.0859 335.85 41.1589 335.45 41.14C334.138 40.8411 332.796 40.6934 331.45 40.7C326.89 40.7 324.413 42.6567 324.02 46.57V50.29H333.91C334.659 50.2879 335.378 50.5828 335.91 51.11C336.175 51.3406 336.389 51.6245 336.537 51.9433C336.685 52.2621 336.765 52.6085 336.77 52.96V68.96C336.765 69.6945 336.499 70.4032 336.02 70.96C335.76 71.2737 335.43 71.5215 335.057 71.6831C334.683 71.8447 334.276 71.9156 333.87 71.89H323.98V121.84C323.994 122.215 323.926 122.589 323.779 122.935C323.632 123.281 323.41 123.59 323.13 123.84C322.617 124.276 321.963 124.511 321.29 124.5V124.5Z" fill="#175A84" />
        <path d="M364.09 116.1L343.27 53.1C343.25 52.7075 343.321 52.3157 343.477 51.955C343.633 51.5943 343.87 51.2744 344.17 51.02C344.726 50.5415 345.436 50.2789 346.17 50.28H366.69C368.03 50.28 368.95 51.22 369.44 53.1L379.18 93.47L388.92 53.1C389.41 51.17 390.33 50.23 391.67 50.28H412.19C412.922 50.2875 413.629 50.5491 414.19 51.02C414.494 51.2714 414.736 51.5902 414.896 51.9513C415.055 52.3124 415.129 52.7057 415.11 53.1L394.11 117.85C392.546 122.937 390.426 127.837 387.79 132.46C385.911 135.839 383.587 138.95 380.88 141.71C378.655 143.846 376.045 145.543 373.19 146.71C370.827 147.747 368.34 148.473 365.79 148.87C363.296 149.143 360.788 149.266 358.28 149.24C355.23 149.347 352.177 149.108 349.18 148.53C346.638 147.757 344.182 146.725 341.85 145.45C341.358 145.038 340.958 144.526 340.677 143.949C340.396 143.372 340.241 142.742 340.22 142.1L340.37 141.44C340.47 141.04 340.76 139.63 341.26 137.2C341.76 134.77 342.42 131.42 343.26 127.2C343.428 126.783 343.716 126.426 344.089 126.175C344.461 125.924 344.901 125.789 345.35 125.79C348.654 126.933 352.115 127.56 355.61 127.65C359.17 127.65 361.48 126.73 362.52 124.9C363.56 123.07 364.09 120.07 364.09 116.1Z" fill="#175A84" />
        <path d="M417.16 121.75C415.786 120.438 414.698 118.857 413.964 117.105C413.229 115.354 412.863 113.469 412.89 111.57C412.868 109.664 413.235 107.773 413.969 106.014C414.704 104.255 415.789 102.665 417.16 101.34C418.484 99.9706 420.072 98.8855 421.83 98.1513C423.587 97.4171 425.476 97.0492 427.38 97.07C429.284 97.0492 431.173 97.4171 432.93 98.1513C434.687 98.8855 436.276 99.9706 437.6 101.34C438.972 102.664 440.06 104.254 440.796 106.013C441.532 107.772 441.901 109.663 441.88 111.57C441.905 113.47 441.537 115.354 440.801 117.106C440.065 118.858 438.975 120.439 437.6 121.75C436.274 123.114 434.684 124.193 432.927 124.922C431.169 125.651 429.282 126.015 427.38 125.99C425.478 126.015 423.591 125.651 421.833 124.922C420.076 124.193 418.486 123.114 417.16 121.75V121.75Z" fill="#EDA600" />
        <path d="M475.61 82.5V92.24C475.61 100.167 479.427 104.13 487.06 104.13C488.692 104.142 490.322 104.005 491.93 103.72C493.443 103.453 495.14 103.097 497.02 102.65C498.9 102.2 500.54 101.88 501.93 101.65C502.382 101.63 502.829 101.748 503.213 101.987C503.596 102.226 503.899 102.576 504.08 102.99C506.02 113.15 506.987 118.577 506.98 119.27C506.946 119.709 506.79 120.129 506.528 120.483C506.266 120.837 505.91 121.11 505.5 121.27C499.7 124.197 491.473 125.66 480.82 125.66C471.053 125.66 463.387 122.413 457.82 115.92C452.253 109.427 449.467 101.003 449.46 90.65V83.96C449.46 73.6 452.247 65.1734 457.82 58.68C463.393 52.1867 471.06 48.94 480.82 48.94C491.487 48.94 499.713 50.4267 505.5 53.4C505.909 53.5435 506.267 53.8037 506.53 54.1484C506.793 54.4932 506.95 54.9075 506.98 55.34C506.98 56.0067 506.013 61.4334 504.08 71.62C503.905 72.044 503.606 72.4054 503.223 72.657C502.839 72.9085 502.389 73.0385 501.93 73.03C500.246 72.7881 498.577 72.4543 496.93 72.03C495.08 71.56 493.39 71.2 491.93 70.95C490.319 70.6942 488.691 70.5704 487.06 70.58C479.433 70.6 475.617 74.5734 475.61 82.5Z" fill="#EDA600" />
        <path d="M545.81 48.97C566.817 48.97 577.323 60.3467 577.33 83.1V91.64C577.33 114.393 566.823 125.743 545.81 125.69C524.797 125.637 514.24 114.287 514.14 91.64V83.1C514.14 60.3533 524.697 48.9767 545.81 48.97ZM551.31 97.89V76.89C551.352 75.2679 550.78 73.6897 549.71 72.47C549.236 71.8954 548.641 71.4338 547.966 71.1189C547.291 70.8041 546.555 70.6439 545.81 70.65C545.065 70.6439 544.329 70.8041 543.654 71.1189C542.979 71.4338 542.384 71.8954 541.91 72.47C540.84 73.6897 540.268 75.2679 540.31 76.89V97.89C540.268 99.5122 540.84 101.09 541.91 102.31C542.384 102.885 542.979 103.346 543.654 103.661C544.329 103.976 545.065 104.136 545.81 104.13C546.555 104.136 547.291 103.976 547.966 103.661C548.641 103.346 549.236 102.885 549.71 102.31C550.78 101.09 551.352 99.5122 551.31 97.89V97.89Z" fill="#EDA600" />
        <path d="M31.7401 53.74C46.58 53.74 58.6101 41.7099 58.6101 26.87C58.6101 12.0301 46.58 0 31.7401 0C16.9002 0 4.87012 12.0301 4.87012 26.87C4.87012 41.7099 16.9002 53.74 31.7401 53.74Z" fill="#EDA600" />
        <path d="M99.39 169.59C114.23 169.59 126.26 157.56 126.26 142.72C126.26 127.88 114.23 115.85 99.39 115.85C84.5501 115.85 72.52 127.88 72.52 142.72C72.52 157.56 84.5501 169.59 99.39 169.59Z" fill="#EDA600" />
        <path d="M23.0001 44.08H20.8201V41.66C20.8254 38.7664 21.9779 35.9929 24.0249 33.9478C26.0719 31.9026 28.8465 30.7526 31.7401 30.75C34.6337 30.7526 37.4082 31.9026 39.4552 33.9478C41.5022 35.9929 42.6549 38.7664 42.6602 41.66V44.08H40.4801" fill="#010101" />
        <path d="M24.48 15.45V26.33C24.48 28.2661 25.249 30.1228 26.618 31.4919C27.9871 32.8609 29.8439 33.63 31.78 33.63C32.7382 33.63 33.6869 33.4411 34.572 33.0741C35.4571 32.7071 36.2613 32.1693 36.9384 31.4913C37.6154 30.8133 38.1522 30.0085 38.5179 29.1228C38.8837 28.2372 39.0713 27.2882 39.0699 26.33V15.45H24.48Z" fill="white" />
        <path d="M31.7501 34.72C29.525 34.72 27.3909 33.836 25.8175 32.2626C24.2441 30.6892 23.3601 28.5552 23.3601 26.33V15.45C23.3601 15.1609 23.4749 14.8836 23.6793 14.6792C23.8837 14.4748 24.161 14.36 24.4501 14.36H39.05C39.1927 14.36 39.334 14.3882 39.4657 14.4431C39.5974 14.498 39.717 14.5785 39.8174 14.6798C39.9178 14.7812 39.9971 14.9014 40.0508 15.0336C40.1045 15.1658 40.1314 15.3073 40.1301 15.45V26.33C40.1301 28.5534 39.2475 30.686 37.6763 32.2591C36.105 33.8322 33.9736 34.7174 31.7501 34.72V34.72ZM25.5402 16.53V26.33C25.5402 27.977 26.1944 29.5565 27.359 30.7211C28.5236 31.8857 30.1031 32.54 31.7501 32.54C33.3963 32.5373 34.9743 31.8822 36.1383 30.7182C37.3023 29.5542 37.9574 27.9762 37.9601 26.33V16.53H25.5402Z" fill="#010101" />
        <path d="M39.04 22.21L31.74 25.95L24.45 22.21V16.05H39.04V22.21Z" fill="white" />
        <path d="M31.7402 27.03C31.5693 27.0325 31.4006 26.9912 31.2502 26.91L23.9501 23.18C23.7683 23.0849 23.6168 22.9409 23.5125 22.7641C23.4082 22.5874 23.3554 22.3851 23.3602 22.18V16.02C23.3602 15.7335 23.4739 15.4588 23.6764 15.2563C23.879 15.0538 24.1537 14.94 24.4401 14.94H39.0402C39.1829 14.9386 39.3243 14.9656 39.4565 15.0193C39.5887 15.073 39.709 15.1523 39.8103 15.2527C39.9117 15.3532 39.9921 15.4727 40.047 15.6044C40.1019 15.736 40.1302 15.8773 40.1302 16.02V22.18C40.1351 22.3864 40.0814 22.5899 39.9751 22.767C39.8689 22.944 39.7146 23.0872 39.5302 23.18L32.2402 26.91C32.0852 26.9885 31.9139 27.0297 31.7402 27.03ZM25.5302 21.55L31.7402 24.72L37.9501 21.55V17.14H25.5302V21.55Z" fill="#010101" />
        <path d="M31.7401 9.37L19.5801 15.45L31.7401 21.53L43.9101 15.45L31.7401 9.37Z" fill="#010101" />
        <path d="M31.7401 22.61C31.5734 22.6139 31.4084 22.5761 31.2601 22.5L19.0901 16.42C18.9071 16.3254 18.7541 16.1817 18.6482 16.0051C18.5422 15.8285 18.4875 15.6259 18.4901 15.42C18.4875 15.214 18.5422 15.0114 18.6482 14.8348C18.7541 14.6582 18.9071 14.5146 19.0901 14.42L31.2601 8.34C31.4149 8.26099 31.5863 8.21979 31.7601 8.21979C31.9339 8.21979 32.1053 8.26099 32.2601 8.34L44.42 14.42C44.6045 14.5127 44.7589 14.656 44.8651 14.833C44.9713 15.01 45.0251 15.2136 45.0201 15.42C45.0251 15.6264 44.9713 15.83 44.8651 16.007C44.7589 16.184 44.6045 16.3272 44.42 16.42L32.2601 22.5C32.0999 22.5829 31.9202 22.6209 31.7401 22.61ZM22.0101 15.45L31.7401 20.31L41.4701 15.45L31.7401 10.58L22.0101 15.45Z" fill="#010101" />
        <path d="M119.48 111.73C119.204 111.729 118.93 111.668 118.68 111.55C118.249 111.337 117.919 110.962 117.764 110.506C117.608 110.051 117.639 109.552 117.85 109.12C122.185 100.307 124.281 90.5609 123.953 80.7447C123.625 70.9285 120.883 61.3444 115.97 52.84C110.7 43.7604 103.142 36.2208 94.0498 30.9728C84.9575 25.7248 74.6484 22.9517 64.1502 22.93C63.9116 22.93 63.6754 22.8828 63.4551 22.7913C63.2349 22.6997 63.0349 22.5655 62.8667 22.3963C62.6985 22.2272 62.5654 22.0265 62.475 21.8057C62.3846 21.585 62.3388 21.3485 62.3401 21.11C62.3401 20.6299 62.5308 20.1695 62.8703 19.8301C63.2097 19.4907 63.6701 19.3 64.1502 19.3V19.3C75.2918 19.3179 86.2339 22.2573 95.8847 27.8249C105.535 33.3925 113.558 41.3937 119.15 51.03C124.361 60.0497 127.269 70.2141 127.618 80.6248C127.966 91.0355 125.745 101.372 121.15 110.72C120.996 111.03 120.758 111.289 120.462 111.468C120.166 111.647 119.826 111.738 119.48 111.73V111.73Z" fill="#175A84" />
        <path d="M128.24 106.39L119.93 108.96L117.36 100.66L114.93 105.28L117.5 113.58L125.81 111L128.24 106.39Z" fill="#175A84" />
        <path d="M64.1102 146.21C52.9687 146.197 42.0256 143.261 32.3742 137.695C22.7228 132.128 14.701 124.127 9.11016 114.49C3.89972 105.47 0.991572 95.3059 0.642748 84.8952C0.293923 74.4845 2.51517 64.1482 7.11016 54.8C7.3212 54.3692 7.6946 54.0398 8.14836 53.8841C8.60213 53.7285 9.09918 53.7594 9.5302 53.97C9.9617 54.183 10.2912 54.5582 10.4467 55.0136C10.6022 55.469 10.5712 55.9675 10.3602 56.4C6.02939 65.2148 3.93641 74.9606 4.26604 84.7764C4.59567 94.5921 7.33779 104.176 12.2502 112.68C17.5156 121.764 25.0726 129.307 34.1659 134.555C43.2593 139.804 53.5707 142.574 64.0701 142.59C64.5502 142.59 65.0106 142.781 65.35 143.12C65.6895 143.46 65.8802 143.92 65.8802 144.4C65.8778 144.872 65.6917 145.325 65.3614 145.663C65.0311 146.001 64.5824 146.197 64.1102 146.21V146.21Z" fill="#175A84" />
        <path d="M0 59.13L8.3 56.55L10.87 64.86L13.31 60.24L10.73 51.94L2.43 54.52L0 59.13Z" fill="#175A84" />
        <path d="M109.22 154.43V152.02C109.217 149.414 108.181 146.915 106.338 145.072C104.495 143.229 101.996 142.193 99.3901 142.19C96.7831 142.19 94.2827 143.226 92.4392 145.069C90.5957 146.913 89.5601 149.413 89.5601 152.02V154.43" fill="#010101" />
        <path d="M110.3 154.44H108.13V152.02C108.127 149.703 107.206 147.481 105.567 145.843C103.929 144.204 101.707 143.283 99.39 143.28C97.0711 143.28 94.847 144.2 93.2063 145.839C91.5656 147.478 90.6427 149.701 90.64 152.02V154.44H88.48V152.02C88.4826 149.125 89.634 146.349 91.6813 144.301C93.7286 142.254 96.5047 141.103 99.4 141.1C102.294 141.105 105.067 142.258 107.112 144.305C109.157 146.352 110.307 149.126 110.31 152.02L110.3 154.44Z" fill="#010101" />
        <path d="M99.3901 143.99C103.422 143.99 106.69 140.722 106.69 136.69C106.69 132.658 103.422 129.39 99.3901 129.39C95.3584 129.39 92.0901 132.658 92.0901 136.69C92.0901 140.722 95.3584 143.99 99.3901 143.99Z" fill="white" />
        <path d="M99.39 145.07C97.1683 145.067 95.0384 144.184 93.4674 142.613C91.8964 141.042 91.0127 138.912 91.01 136.69C91.01 134.467 91.8926 132.334 93.4639 130.761C95.0351 129.188 97.1666 128.303 99.39 128.3C101.614 128.303 103.747 129.187 105.32 130.76C106.893 132.333 107.777 134.466 107.78 136.69C107.775 138.913 106.889 141.043 105.316 142.613C103.744 144.184 101.613 145.067 99.39 145.07V145.07ZM99.39 130.48C97.7438 130.483 96.1657 131.138 95.0017 132.302C93.8377 133.466 93.1826 135.044 93.1799 136.69C93.1826 138.336 93.8377 139.914 95.0017 141.078C96.1657 142.242 97.7438 142.897 99.39 142.9C101.036 142.897 102.614 142.242 103.778 141.078C104.942 139.914 105.597 138.336 105.6 136.69C105.597 135.044 104.942 133.466 103.778 132.302C102.614 131.138 101.036 130.483 99.39 130.48V130.48Z" fill="#010101" />
        <path d="M77.1101 116.37H72.8301C72.6046 116.37 72.3884 116.28 72.229 116.121C72.0696 115.962 71.9801 115.745 71.9801 115.52V108.33H68.3501V115.52C68.3527 116.707 68.8256 117.845 69.6652 118.685C70.5048 119.525 71.6427 119.997 72.8301 120H77.1101C77.592 119.997 78.0534 119.805 78.3942 119.464C78.7349 119.123 78.9274 118.662 78.9301 118.18C78.9274 117.699 78.7346 117.239 78.3936 116.9C78.0525 116.56 77.5911 116.37 77.1101 116.37Z" fill="#010101" />
        <path d="M54.8201 108.33V115.52C54.8201 115.745 54.7306 115.962 54.5712 116.121C54.4118 116.28 54.1955 116.37 53.9701 116.37H49.6901C49.2091 116.37 48.7478 116.56 48.4067 116.9C48.0657 117.239 47.8728 117.699 47.8701 118.18C47.8727 118.662 48.0654 119.123 48.4061 119.464C48.7469 119.805 49.2082 119.997 49.6901 120H53.9701C55.1575 119.997 56.2954 119.525 57.135 118.685C57.9746 117.845 58.4474 116.707 58.4501 115.52V108.33H54.8201Z" fill="#010101" />
        <path d="M93.7201 109.9H33.0801C31.0962 109.895 29.1951 109.104 27.7932 107.7C26.3913 106.296 25.6027 104.394 25.6001 102.41V65.08C25.6027 63.097 26.3916 61.196 27.7938 59.7938C29.196 58.3916 31.0971 57.6026 33.0801 57.6H93.7201C95.7031 57.6026 97.6041 58.3916 99.0063 59.7938C100.409 61.196 101.197 63.097 101.2 65.08V102.41C101.197 104.394 100.409 106.296 99.007 107.7C97.605 109.104 95.704 109.895 93.7201 109.9ZM33.0801 61.22C32.0581 61.2226 31.0789 61.6305 30.3572 62.3541C29.6354 63.0777 29.2301 64.058 29.2301 65.08V102.41C29.2301 103.432 29.6354 104.412 30.3572 105.136C31.0789 105.859 32.0581 106.267 33.0801 106.27H93.7201C94.7421 106.267 95.7214 105.859 96.4431 105.136C97.1648 104.412 97.5701 103.432 97.5701 102.41V65.08C97.5701 64.058 97.1648 63.0777 96.4431 62.3541C95.7214 61.6305 94.7421 61.2226 93.7201 61.22H33.0801Z" fill="#010101" />
        <path d="M34.77 64.16C34.2396 64.16 33.7308 64.3707 33.3557 64.7458C32.9806 65.1208 32.77 65.6295 32.77 66.16V95.24H93.97V66.16C93.97 65.6295 93.7592 65.1208 93.3842 64.7458C93.0091 64.3707 92.5004 64.16 91.97 64.16H34.77Z" fill="#EDA600" />
        <path d="M99.3902 101.65H27.3902C26.9101 101.65 26.4497 101.459 26.1103 101.12C25.7708 100.78 25.5801 100.32 25.5801 99.84C25.5788 99.6015 25.6246 99.365 25.715 99.1443C25.8054 98.9235 25.9385 98.7228 26.1067 98.5537C26.2749 98.3845 26.4748 98.2503 26.6951 98.1588C26.9153 98.0672 27.1516 98.02 27.3902 98.02H99.3902C99.6287 98.02 99.8649 98.0672 100.085 98.1588C100.305 98.2503 100.505 98.3845 100.673 98.5537C100.842 98.7228 100.975 98.9235 101.065 99.1443C101.156 99.365 101.201 99.6015 101.2 99.84C101.2 100.32 101.009 100.78 100.67 101.12C100.331 101.459 99.8702 101.65 99.3902 101.65Z" fill="#010101" />
        <path d="M74.9401 120H51.8601C51.3791 119.997 50.9187 119.804 50.5796 119.463C50.2404 119.122 50.05 118.661 50.05 118.18C50.05 117.7 50.2408 117.24 50.5802 116.9C50.9196 116.561 51.3801 116.37 51.8601 116.37H74.9401C75.421 116.37 75.8825 116.56 76.2235 116.9C76.5645 117.239 76.7575 117.699 76.7601 118.18C76.7575 118.662 76.5649 119.123 76.2241 119.464C75.8834 119.805 75.4219 119.997 74.9401 120V120Z" fill="#010101" />
        <path d="M67.7001 72.15C67.3627 71.8142 66.9061 71.6256 66.4301 71.6256C65.954 71.6256 65.4975 71.8142 65.1602 72.15L53.8301 83.48C53.4942 83.8173 53.3057 84.274 53.3057 84.75C53.3057 85.2261 53.4942 85.6827 53.8301 86.02C53.995 86.1896 54.1925 86.324 54.4108 86.4151C54.629 86.5062 54.8636 86.5521 55.1001 86.55C55.338 86.5517 55.574 86.5057 55.7938 86.4146C56.0137 86.3236 56.213 86.1894 56.3801 86.02L67.7001 74.7C67.8691 74.5335 68.0034 74.335 68.095 74.1161C68.1866 73.8972 68.2338 73.6623 68.2338 73.425C68.2338 73.1877 68.1866 72.9528 68.095 72.7339C68.0034 72.5151 67.8691 72.3166 67.7001 72.15Z" fill="white" />
        <path d="M74.4199 78.72C74.2534 78.551 74.0549 78.4168 73.8361 78.3252C73.6172 78.2336 73.3823 78.1864 73.145 78.1864C72.9077 78.1864 72.6727 78.2336 72.4539 78.3252C72.235 78.4168 72.0365 78.551 71.87 78.72L67.36 83.23C67.1913 83.3957 67.0573 83.5933 66.9658 83.8114C66.8743 84.0295 66.8271 84.2636 66.8271 84.5C66.8271 84.7365 66.8743 84.9706 66.9658 85.1886C67.0573 85.4067 67.1913 85.6043 67.36 85.77C67.5249 85.9396 67.7224 86.074 67.9407 86.1651C68.159 86.2562 68.3935 86.3021 68.63 86.3C68.8665 86.3021 69.1009 86.2562 69.3192 86.1651C69.5375 86.074 69.7351 85.9396 69.9 85.77L74.4199 81.26C74.7558 80.9227 74.9445 80.466 74.9445 79.99C74.9445 79.514 74.7558 79.0574 74.4199 78.72Z" fill="white" />
        <path d="M562.62 38.12H560.24C560.07 38.12 559.97 38.05 559.96 37.89V36.47C559.957 36.4329 559.962 36.3954 559.975 36.3605C559.988 36.3256 560.009 36.294 560.036 36.2681C560.062 36.2422 560.095 36.2227 560.13 36.2109C560.165 36.1991 560.203 36.1954 560.24 36.2H566.9C567.05 36.2 567.13 36.29 567.13 36.47V37.89C567.135 37.9214 567.133 37.9535 567.123 37.9838C567.113 38.0141 567.097 38.0416 567.074 38.0641C567.052 38.0866 567.024 38.1034 566.994 38.1131C566.963 38.1228 566.931 38.1252 566.9 38.12H564.48V44.83C564.48 44.98 564.39 45.06 564.23 45.06H562.9C562.72 45.06 562.64 44.98 562.64 44.83L562.62 38.12ZM573.48 43.12H572.34C572.2 43.12 572.08 43.02 571.95 42.81L570.33 39.56V44.83C570.33 44.98 570.26 45.06 570.13 45.06H568.77C568.59 45.06 568.5 44.98 568.49 44.83V36.44C568.49 36.26 568.58 36.17 568.77 36.17H570.46C570.57 36.17 570.69 36.26 570.81 36.44L572.92 40.65L575.02 36.44C575.133 36.26 575.243 36.17 575.35 36.17H577.06C577.097 36.1651 577.134 36.1686 577.169 36.1804C577.204 36.1923 577.236 36.212 577.262 36.2382C577.288 36.2643 577.308 36.2961 577.32 36.3311C577.331 36.3661 577.335 36.4034 577.33 36.44V44.83C577.33 44.98 577.24 45.06 577.06 45.06H575.7C575.57 45.06 575.5 44.98 575.49 44.83V39.54L573.86 42.79C573.74 43.03 573.61 43.13 573.48 43.13V43.12Z" fill="#EDA600" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="577.33" height="169.6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </Link>
};
