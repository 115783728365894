import { FC } from 'react';
import { Review } from '../../services/graphql';
import { ReviewSummary } from './ReviewSummary';

interface ReviewCardProps {
  review: Review
}

export const ReviewCard: FC<ReviewCardProps> = ({ review }) => {
  return <ReviewSummary review={review}/>
};
