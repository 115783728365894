import { useEffect } from 'react';
import { FC } from 'react';
import { BsInboxes } from 'react-icons/bs';
import { useAuthenticationService } from '../authentication/authentication-service';
import { Report as IReport, useGetReportedConversationsQuery } from '../services/graphql';
import { Loader } from '../shared/Loader';
import i18n from '../translations/Conversation';
import { Route, useHistory, useParams } from 'react-router-dom';
import { useReportService } from './reports-service';
import { ReportListItem } from './ReportListItem';
import { Report } from './Report';
import { PageHelmet } from '../shared/Helmet';

interface ReportsPageParams {
  id?: string
}

export const ReportsPage: FC = () => {
  const user = useAuthenticationService(state => state.user);
  const groupId = user?.group?.id;
  const { data, refetch } = useGetReportedConversationsQuery({
    fetchPolicy: "no-cache",
    variables: { groupId }
  });

  const [reports, setReports] = useReportService(state => [state.reports, state.setReports]);

  useEffect(() => {
    // Every 5 seconds, reload the conversation list.
    const id = setInterval(() => {
      refetch()
    }, 5000)
    return () => {
      clearInterval(id);
    };
  }, [refetch]);

  const history = useHistory();
  const { id } = useParams<ReportsPageParams>();

  useEffect(() => {
    let reports = data?.reports as IReport[];

    const compareReport = (a: IReport, b: IReport) => {
      let aUpdatedAt = new Date(a.updated_at).getTime();
      let bUpdatedAt = new Date(b.updated_at).getTime();

      return bUpdatedAt - aUpdatedAt;
    }

    if (reports) {
      const reportsToSort = [...reports];
      reportsToSort.sort(compareReport);
      reports = reportsToSort;
    }

    setReports(reports);
  }, [data, setReports])


  return <div className="h-full w-full flex">
    <PageHelmet pageTitle={i18n.helmets.reports}/>
    <div className="w-full flex flex-col divide-y z-0 sm:w-72 lg:w-96 bg-white border-r max-h-full overflow-auto"
      onClick={() => history.push('/reports')}
    >
      {reports && user
        ? reports.map((report) => {
          return <ReportListItem
            key={report.id}
            report={report}
            onClick={() => history.push(`/reports/${report.id}`)}
            selected={report.id === id}
          />
        })
        : <div className="m-8 flex justify-center items-center">
          <Loader className="w-8" />
        </div>
      }
    </div>
    <Route path="/reports/:id" component={Report} />
    <Route path="/reports" exact>
      <div className="text-center flex-grow h-full hidden sm:flex items-center flex-col justify-center">
        <BsInboxes className="w-12 h-12" />
        <h3 className="mt-2 font-medium text-gray-900">{i18n.inbox.emptyState.title}</h3>
        <p className="mt-1 text-sm text-gray-500">{i18n.inbox.emptyState.description}</p>
      </div>
    </Route>
  </div>
};
