import { FC } from 'react';
import { Report } from '../services/graphql';
import { Card } from '../shared/Card';
import i18n from "../translations/Moderators";
import { getProblemSummary } from './ReportProblemSummary';

interface ReportSummaryCardProps {
    report: Report,
}

export const ReportSummaryCard: FC<ReportSummaryCardProps> = ({ report }) => {
    return <Card className="self-center flex flex-col items-center gap-2 m-5 p-5">
        <span className="text-primary-600 font-bold text-lg">{i18n.summary.title(getProblemSummary(report.reportType!))}</span>
        <div className="whitespace-pre-line">
            {report.issue}
        </div>
    </Card>
};
