import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    helmets: {
      login: "Login",
      register: "Register",
      forgottenPassword: "Forgotten Password",
      resetPasswordSent: "Password Reset Sent",
      resetPassword: "Reset Password",
      resetPasswordConfirmed: "Reset Password Confirmed",
      onboarding: "Onboarding"
    },
    emailAddress: {
      label: 'Email address',
      placeholder: 'you@example.com',
      requiredMessage: 'Username is required'
    },
    password: {
      label: 'Password',
      requiredMessage: 'Password is required'
    },
    newPassword: {
      label: 'New Password',
      requiredMessage: 'New password is required'
    },
    confirmPassword: {
      label: 'Confirm Password',
      requiredMessage: 'Confirm password is required'
    },
    login: {
      welcomeMessage: 'Welcome!',
      loginButton: 'Login',
      createAnAccountButton: 'Create an account',
      forgottenPasswordButton: 'Forgotten password',
      notConfirmed: 'You have not confirmed your account. We have sent a new confirmation email to you.',
      pleaseConfirm: `We've sent you an email to confirm your email address. Please continue from the link in that email.`
    },
    register: {
      message: 'Create an account',
      registerButton: 'Register',
      cancelButton: "Cancel"
    },
    forgotten: {
      title: "Forgotten Password",
      confirm: "Request Reset",
      cancel: "Cancel",
      resetRequestedTitle: "Password Reset Requested",
      resetRequestedText: "Check your email for a link to reset your password."
    },
    resetPassword: {
      title: "Reset Password",
      confirmButton: "Reset",
      confirmedTitle: "Password Reset Confirmed",
      loginButton: "Login"
    },
    route: {
      notAllowed: 'Not allowed!'
    },
    onboarding: {
      almostThere: "You're almost there 🎉",
      alreadyGotInfo: "Your information has already been given to us by your University so all you need to do is quickly check it is all correct and we can get started.",
      emailFromOrg: "Your email is verified as from one of the organisations that use Tyfy but we haven't been given your information. Just quickly fill in your details and then we can get started.",
      getStarted: "Get started",
      back: "Back",
      next: "Next",
      submit: "Submit",
      chatLater: "Chat later",
      matchingUp: "We're matching you up",
      mentorsIntro: "Here are some mentors who have done the same modules as you're currently doing:"
    }
  }
})
