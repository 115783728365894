import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { merge } from '../../helpers/ui';

interface MentorSearchCellProps extends HTMLAttributes<HTMLDivElement> {
  header?: boolean
}

export const MentorSearchCell: FC<MentorSearchCellProps> = ({ header, className, children }) => {
  const backgroundColor = header ? "bg-gray-100 pt-6" : "bg-white border-b";

  return <div className={merge(backgroundColor, className, "px-5 pb-3 pt-3")}>
    {children}
  </div>
};
