import { NavLink } from 'react-router-dom';
import { FC } from 'react';

interface HeaderLinkProps {
  label: string,
  link: string,
  icon?: JSX.Element
}

export const HeaderLink: FC<HeaderLinkProps> = (props: HeaderLinkProps) => {
  return <NavLink
    to={props.link}
    className='inline-flex justify-center items-center space-x-1.5 font-medium p-3 text-primary-600 hover:bg-primary-50 rounded focus:outline-none focus:ring-2 focus:ring-primary-500'
    activeClassName='font-semibold'
    aria-label={props.label}
  >
    {props.icon}
    <span className="hidden sm:block">{props.label}</span>
  </NavLink>;
};
