import create from 'zustand';
import { Message, Report } from '../services/graphql';

interface ReportService {
  reports?: Report[]
  selected?: {
    id: string
    report?: Report
    messages?: Message[]
  }
  setReports: (reports: Report[]) => void
  setSelected: (selectedId: string) => void
  setSelectedReport: (report: Report) => void
}

export const useReportService = create<ReportService>(set => ({
  setReports: (reports) => set(state => ({ ...state, reports })),
  setSelected: (selectedId) => set(state => ({
    ...state,
    selected: {
      id: selectedId,
    }
  })),
  setSelectedReport: (report) => set(state => {
    if (state.selected?.id && state.selected.id !== report.id) return state;
    const unsortedMessages = report.conversation?.messages as Message[]
    const messages = unsortedMessages?.slice(0).sort((a, b) => {
      if (!a || !b) return 0;
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });
    return {
      ...state,
      selected: {
        id: report.id,
        report,
        messages
      }
    }
  })
}))
