import { FC } from 'react';
import { Card } from '../shared/Card';

interface HomeRuleCardProps {
  title: string,
  text: string
}

export const HomeRuleCard: FC<HomeRuleCardProps> = ({ title, text }) => {
  return <Card className="p-5 flex flex-col gap-2 items-center text-primary-700">
    <div className="font-bold text-lg">{title}</div>
    <div className="whitespace-pre-line">{text}</div>
  </Card>
};
