import { FC } from 'react';

export interface Item {
  section: string
  id: string
  name: string
}

export interface SearchItemProps {
  item: Item
  index: [number, number]
  selectedIndex?: [number, number]

  onClick: () => void
}

const SearchItem: FC<SearchItemProps> = ({ item, index, selectedIndex = [], onClick }) => {
  const [selectedSectionIndex, selectedItemIndex] = selectedIndex;
  const [sectionIndex, itemIndex] = index;

  const isSelected = (selectedSectionIndex === sectionIndex && selectedItemIndex === itemIndex);

  return <div key={item.name} className={`${isSelected ? 'bg-indigo-100 hover:bg-indigo-200' : 'hover:bg-gray-50'} px-3 py-2 cursor-pointer`} onClick={onClick}>
    {item.name}
  </div>
}

export default SearchItem;
