import { FC } from 'react';
import { Link } from 'react-router-dom';
import { getDateAndTimeToDisplay } from '../../../helpers/date';
import { Conversation } from '../../../services/graphql';
import i18n from "../../../translations/Conversation";

interface ConversationAttachmentProps {
  conversation: Conversation
}

export const ConversationAttachment: FC<ConversationAttachmentProps> = ({conversation}) => {
  return <Link to={`/messages/${conversation.id}`} className="p-2 my-2 flex flex-col bg-opacity-30 bg-gray-400 w-full rounded-md">
    <span className="font-bold">{i18n.attachment.conversation.names(conversation.members![0], conversation.members![1])}</span>
    <span>{getDateAndTimeToDisplay(conversation.created_at)}</span>
  </Link>;
};
