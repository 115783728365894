import { FC } from 'react';
import { UsersPermissionsUser } from '../../services/graphql';
import { MentorSearchCell } from './MentorSearchCell';
import { MentorSearchRow } from './MentorSearchRow';
import i18n from "../../translations/Mentors";
import { HTMLAttributes } from 'react';

interface MentorSearchTableProps extends HTMLAttributes<HTMLDivElement> {
  mentors: UsersPermissionsUser[]
}

export const MentorSearchTable: FC<MentorSearchTableProps> = ({ mentors, className }) => {
  return <div className={className}>
    <div
      className={"grid"}
      style={{ gridTemplateColumns: "3fr 1fr 1fr 2fr" }}
    >
      <MentorSearchCell header className="text-xs uppercase">{i18n.search.headers.person}</MentorSearchCell>
      <MentorSearchCell header className="text-xs uppercase">{i18n.search.headers.course}</MentorSearchCell>
      <MentorSearchCell header className="text-xs uppercase">{i18n.search.headers.year}</MentorSearchCell>
      <MentorSearchCell header className="text-xs uppercase">{i18n.search.headers.modules}</MentorSearchCell>
      {mentors.map(mentor => <MentorSearchRow mentor={mentor} key={mentor.id} />)}
    </div>
  </div>
};
