import { FC } from 'react';
import { useGetModeratorMetricsQuery } from '../../services/graphql';
import { ContentLoader } from '../../shared/ContentLoader';
import { Card } from '../../shared/Card';
import { Title } from '../../shared/Title';
import i18n from "../../translations/Home";
import { dateToGQLFormat } from '../../helpers/date';

interface ModeratorHomeStatsProps {
  groupId: string
}

export const ModeratorHomeStats: FC<ModeratorHomeStatsProps> = ({ groupId }) => {
  const lastWeek = new Date();
  lastWeek.setDate(new Date().getDate() - 7);

  const userCountResponse = useGetModeratorMetricsQuery({
    variables: {
      where: { group: groupId },
      date: dateToGQLFormat(lastWeek)
    }
  });

  return <ContentLoader loading={userCountResponse.loading} error={userCountResponse.error}>
    <div className="text-xl text-primary-500 font-bold mt-5 mb-2">
      {i18n.moderator.stats.title}
    </div>
    <div className="w-full grid grid-cols-3 gap-4">
      <Card className="text-center p-4">
        <Title className="mb-3" size="5xl">{userCountResponse.data?.userCount}</Title>
        <Title size="lg">{i18n.moderator.stats.users.number}</Title>
        <p>{i18n.moderator.stats.users.course}</p>
      </Card>
      <Card className="text-center p-4">
        <Title className="mb-3" size="5xl">{userCountResponse.data?.newConversationCount}</Title>
        <Title size="lg">{i18n.moderator.stats.conversations.number}</Title>
        <p>{i18n.moderator.stats.conversations.thisWeek}</p>
      </Card>
      <Card className="text-center p-4">
        <Title className="mb-3" size="5xl">{userCountResponse.data?.newMessageCount}</Title>
        <Title size="lg">{i18n.moderator.stats.messages.number}</Title>
        <p>{i18n.moderator.stats.messages.thisWeek}</p>
      </Card>
      <Card className="text-center p-4">
        <Title className="mb-3" size="5xl">{userCountResponse.data?.newReviewCount}</Title>
        <Title size="lg">{i18n.moderator.stats.reviews.number}</Title>
        <p>{i18n.moderator.stats.reviews.thisWeek}</p>
      </Card>
    </div>
  </ContentLoader>
};
