import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { log } from '../../helpers/log';
import { Button } from '../../shared/Button';
import { registerWithUsernameAndPassword, useAuthenticationService } from '../authentication-service';
import { TextField } from '../../shared/inputs/TextInput';
import { Form } from '../../shared/Form';
import { PasswordField } from '../../shared/inputs/PasswordInput';
import { useState } from 'react';
import { State } from '../../helpers/state';
import { StrapiError } from '../../shared/errors/StrapiError';

import i18n from '../../translations/Authentication';
import { Welcome } from './Welcome';
import { Title } from '../../shared/Title';
import { PageHelmet } from '../../shared/Helmet';

interface RegisterProps { }

interface RegisterFormFields {
  email: string
  password: string
}

export const Register: FC<RegisterProps> = () => {
  const [authenticationState, setUserInfo] = useAuthenticationService(state => [state.state, state.setUserInfo]);
  const [state, setState] = useState<State>(State.Idle)
  const [errors, setErrors] = useState<any>()
  const [isNotConfirmed, setConfirmedState] = useState<boolean>(false)
  const location = useLocation<{ restore?: string }>();
  const history = useHistory();

  const methods = useForm<RegisterFormFields>()

  useEffect(() => {
    (async () => {
      try {
        log(`User is ${authenticationState}. Logging in regardless of previous state.`)

      } catch (e) {
        log('Log in was unsuccessful.', e)
      }
    })()
  }, [authenticationState])

  const register = async (data: RegisterFormFields) => {
    setState(State.Loading);

    try {
      const { jwt, user } = await registerWithUsernameAndPassword(
        data.email,
        data.password
      )

      console.log(user);

      if (user.confirmed !== true) {
        setState(State.Idle);
        setConfirmedState(true);
        return;
      }

      setUserInfo(jwt, user);

      const targetLocation = (() => {
        if (location?.state?.restore) return location.state.restore;
        return '/'
      })()
      history.push(targetLocation);

    } catch (e) {
      log('Log in was unsuccessful.', e);
      setState(State.Failure);
      setErrors(e);
    }
  }

  return <Welcome>
    <div className="flex flex-col w-full max-w-md p-8">
      <PageHelmet pageTitle={i18n.helmets.register} />
      <Title size="2xl" className="mb-4">{i18n.register.message}</Title>
      <Form methods={methods} onSubmit={register}>
        <TextField name="email" title={i18n.emailAddress.label} placeholder={i18n.emailAddress.placeholder} requiredMessage={i18n.emailAddress.requiredMessage} />
        <PasswordField name="password" title={i18n.password.label} placeholder="********" requiredMessage={i18n.password.requiredMessage} />
        {errors ? <StrapiError errors={errors} /> : null}
        {isNotConfirmed ? <div className="flex flex-col bg-orange-100 border border-orange-300 rounded mt-4 text-orange-700 text-sm mb-2 py-3 px-4">
          {i18n.login.pleaseConfirm}
        </div> : <>
          <Button loading={state === State.Loading} className="mt-2 w-full" primary submit>{i18n.register.registerButton}</Button>
          <Button className="mt-2 w-full" onClick={() => history.goBack()}>{i18n.register.cancelButton}</Button>
        </>}
      </Form>
    </div>
  </Welcome>;
};
