import { FC, HTMLAttributes, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { merge } from '../helpers/ui';
import { Button } from './Button';
import i18n from "../translations/Shared";
import { DropdownInput } from './inputs/DropdownInput';
import { TextInput } from './inputs/TextInput';
import { useHistory } from 'react-router-dom';

enum SiteSearchType {
  FORUM = "Forum",
  MENTOR = "Mentor"
}

interface SiteSearchOption {
  name: string,
  id: SiteSearchType
}

interface SiteSearchProps extends HTMLAttributes<HTMLDivElement> {
}

export const SiteSearch: FC<SiteSearchProps> = ({ className }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const history = useHistory();

  const search = () => {
    history.push(`mentors/${searchTerm}`);
  }

  const dropdownOptions = [
    /*{
      id: SiteSearchType.FORUM,
      name: i18n.siteSearch.types.forum.label
    },*/
    {
      id: SiteSearchType.MENTOR,
      name: i18n.siteSearch.types.mentor.label
    },
  ];

  const [searchTypeOption, setSearchTypeOption] = useState<SiteSearchOption>(dropdownOptions[0]);


  let searchPlaceholder: string;
  switch (searchTypeOption.id) {
    case (SiteSearchType.FORUM):
      searchPlaceholder = i18n.siteSearch.types.forum.placeholder;
      break;
    case (SiteSearchType.MENTOR):
      searchPlaceholder = i18n.siteSearch.types.mentor.placeholder;
      break;
  }

  return <div className={merge("w-full flex flex-col gap-2", className)}>
    <div className="flex items-center mt-1">
      <FiSearch className="h-8 w-8 mr-5" />
      <div className="mr-5 w-48">
        <DropdownInput
          name="searchType"
          options={dropdownOptions}
          value={searchTypeOption}
          onChange={(value) => setSearchTypeOption(value as SiteSearchOption)}
        />
      </div>
      <div className="flex-1">
        <TextInput
          name="searchTerm"
          className="flex-1 hidden sm:block"
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={setSearchTerm}
        />
      </div>
      <Button className="ml-5" onClick={search}>
        {i18n.siteSearch.button}
      </Button>
    </div>
    <TextInput
          name="searchTerm"
          className="sm:hidden"
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={setSearchTerm}
        />
  </div>
};
