import { FC } from "react";
import { useParams } from "react-router-dom";
import { ForumPost as IForumPost, useCreateCommentMutation, useGetForumPostQuery, useUpdatePostMutation } from "../services/graphql";
import { ContentLoader } from "../shared/ContentLoader";
import { ErrorMessage } from "../shared/ErrorMessage";
import { PageContainer } from "../shared/PageContainer";
import { ForumPost } from "./ForumPost";
import i18n from "../translations/Forum";
import { useAuthenticationService } from "../authentication/authentication-service";
import { ForumPostHeader } from "./ForumPostHeader";
import { PageHelmet } from "../shared/Helmet";

interface ForumPostPageParams {
    forumId: string
}

interface ForumPostPageProps { }

export const ForumPostPage: FC<ForumPostPageProps> = () => {
    const { forumId } = useParams<ForumPostPageParams>();
    const user = useAuthenticationService(state => state.user);

    const { data, loading, error, refetch } = useGetForumPostQuery({
        variables: {
            id: forumId
        }
    });

    const [createComment, createCommentStatus] = useCreateCommentMutation();

    const postComment = async (comment: string, replyTo?: string) => {
        await createComment({
            variables: {
                input: {
                    data: {
                        text: comment,
                        postedBy: user!.id as string,
                        forumPost: forumId,
                        replyTo
                    }
                }
            }
        });
        refetch();
    }

    const [updatePost, updatePostStatus] = useUpdatePostMutation();

    const upvotePost = async () => {
        if (!data?.forumPost) return;

        const existingUpvotes = data.forumPost.upvotedBy;
        const upvoteIds: string[] = existingUpvotes?.map(user => user.id as string) || [];
        const alreadyUpvotedIndex = upvoteIds.findIndex(id => user?.id === id);

        let newUpvoteIds = [...upvoteIds];
        if (alreadyUpvotedIndex >= 0) {
            ;
            newUpvoteIds.splice(alreadyUpvotedIndex, 1);
        } else {
            newUpvoteIds.push(user?.id as string);
        }

        await updatePost({
            variables: {
                input: {
                    where: {
                        id: data?.forumPost!.id
                    },
                    data: {
                        upvotedBy: newUpvoteIds
                    }
                }
            }
        });
        refetch();
    }

    return <ContentLoader loading={loading} error={error}>
        {data && data.forumPost && <ForumPostHeader post={data.forumPost as IForumPost} />}
        <PageHelmet pageTitle={i18n.helmets.forumPost(data?.forumPost?.title || "")}/>
        <PageContainer>
            {data && data.forumPost && <div className="flex flex-col gap-5">
                <ForumPost
                    post={data?.forumPost as IForumPost}
                    onUpvote={upvotePost}
                    onSend={postComment}
                    votesLoading={updatePostStatus.loading}
                    loading={createCommentStatus.loading}
                />
            </div>}
            {data && !data.forumPost && <ErrorMessage message={i18n.postNotFound} />}
        </PageContainer>
    </ContentLoader>;
}
