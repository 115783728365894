import React, { useRef, LegacyRef } from 'react';
import { FC } from 'react';
import { FiUpload } from 'react-icons/fi';
import { InputProps, withField } from '.';
import { merge } from '../../helpers/ui';
import { useUploadFileMutation } from '../../services/graphql';
import i18n from '../../translations/Shared';

export interface ImageDetails {
    id: string,
    url: string
}

interface ImageInputProps extends InputProps<ImageDetails> {
}

export const ImageInput: FC<ImageInputProps> = ({ name, onChange, value, className, ...props }) => {
    const inputElement = useRef<HTMLInputElement>();

    const [uploadFile] = useUploadFileMutation({
        onCompleted: (data) => {
            onChange({
                id: data.upload.id,
                url: data.upload.url
            });
        }
    });

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const newFile = event.target.files[0];
            if (newFile) {
                uploadFile({
                    variables: {
                        file: newFile,
                    }
                })
            }
        }
    }

    return <div {...props} className={merge('flex h-full justify-center items-center', className)}>
        <button
          type="button"
          className="h-full w-full bg-gray-50 rounded-lg border border-gray-200 bg-no-repeat bg-cover bg-center cursor-pointer flex items-center justify-center relative focus:outline-none focus:ring-2 focus:ring-primary-400"
          onClick={() => inputElement?.current?.click()}
          style={{
              backgroundImage: value ? `url(${value.url})` : undefined
          }}
        >
            {!value && i18n.inputs.image.empty}
            {value && <div className="opacity-0 hover:opacity-100 bg-opacity-40 absolute w-full h-full bg-black rounded-lg flex items-center justify-center">
                <FiUpload className="w-12 h-12 text-white"/>
            </div>}
        </button>
        <input
            type="file"
            ref={inputElement as LegacyRef<HTMLInputElement>}
            name={name}
            id={name}
            className="hidden"
            onChange={handleOnChange}
        />
    </div>
};

export const ImageField = withField(ImageInput);
