import { FC, HTMLAttributes } from 'react';
import { Enum_Notification_Type, Enum_Userspermissionsuser_Mentorstatus, useGetContentQuery, useGetMyNotificationsQuery, UsersPermissionsUser } from '../services/graphql';
import { PageContainer } from '../shared/PageContainer';
import { PointsCount } from '../shared/PointsCount';
import i18n from "../translations/Home";
import { NotificationBox, NotificationType } from './NotificationBox';

interface HomeHeaderProps extends HTMLAttributes<HTMLDivElement> {
  user: UsersPermissionsUser
}

export const HomeHeader: FC<HomeHeaderProps> = ({ user }) => {
  const userIsMentor = user?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;

  const { data } = useGetMyNotificationsQuery();
  const contentResponse = useGetContentQuery();


  const notifications = data?.me?.user?.notifications;

  const unreadConversations = notifications?.filter(notification => notification.type === Enum_Notification_Type.NewMessage);
  const newReviews = notifications?.filter(notification => notification.type === Enum_Notification_Type.NewReview);


  return <div
    className="w-full pb-10 text-white bg-primary-700 bg-cover bg-no-repeat bg-center bg-blend-soft-light"
    style={{ backgroundImage: `url("/assets/hero-image.jpg")` }}
  >
    <PageContainer>
      <div className="w-full flex flex-col items-start gap-2 relative">
        <div className="flex flex-col items-start">
          <span className="font-bold text-2xl sm:text-3xl">
            {user.firstName ? i18n.header.welcomeBack(user.firstName) : i18n.header.welcome}
          </span>
          <span className="font-bold bg-secondary-400 px-2 py-1 text-black mt-1 rounded">
            {userIsMentor ? i18n.header.mentor.label : i18n.header.mentee.label}
          </span>
        </div>
        {user.points !== undefined && <div className="absolute top-0 right-0 hidden sm:flex">
          <PointsCount points={user.points} />
        </div>}
        <div className="flex flex-row gap-3 my-2 h-12">
          <NotificationBox type={NotificationType.MESSAGE} count={unreadConversations?.length} />
          {userIsMentor && <NotificationBox secondary type={NotificationType.REVIEW} count={newReviews?.length} />}
          <NotificationBox type={NotificationType.COMMENT} count={undefined} />
        </div>
        <div className="whitespace-pre-line">{userIsMentor ? contentResponse?.data?.mentorOverview?.content : contentResponse?.data?.menteeOverview?.content}</div>
      </div>
    </PageContainer>
  </div>

};
