import { FC } from 'react';
import i18n from "../translations/Home";
import { SiteSearch } from '../shared/SiteSearch';
import { MentorRecommendations } from '../mentors/MentorRecommendations';
import { RecommendedPosts } from './RecommendedPosts';
import { useAuthenticationService } from '../authentication/authentication-service';

interface HomeContentsMenteesProps { }

export const HomeContentsMentees: FC<HomeContentsMenteesProps> = () => {
  const user = useAuthenticationService(state => state.user);

  return <>
    <div className="bg-white rounded-lg border p-5">
      <SiteSearch />
    </div>
    <div>
      <div className="font-bold text-lg mb-2 text-primary-600">{i18n.page.mentors.title}</div>
      <MentorRecommendations responsiveMode />
    </div>
    { user?.organisation.hideForum ? null : <div>
      <div className="font-bold text-lg mb-2 text-primary-600">{i18n.page.posts.title}</div>
      <RecommendedPosts />
    </div> }
  </>
};
