import { FC, HTMLAttributes } from 'react';
import { UsersPermissionsUser } from '../../services/graphql';
import { PageContainer } from '../../shared/PageContainer';
import i18n from "../../translations/Home";

interface HomeHeaderProps extends HTMLAttributes<HTMLDivElement> {
  user: UsersPermissionsUser
}

export const ModeratorHomeHeader: FC<HomeHeaderProps> = ({ user }) => {

  return <div
    className="w-full text-white bg-primary-700 bg-cover bg-no-repeat bg-center bg-blend-soft-light"
    style={{ backgroundImage: `url("/assets/hero-image.jpg")` }}
  >
    <PageContainer>
      <div className="w-full flex flex-col items-start gap-2 relative">
        <div className="flex flex-col items-start">
          <span className="font-bold text-2xl sm:text-3xl">
            {user.firstName ? i18n.header.welcomeBack(user.firstName) : i18n.header.welcome}
          </span>
          <span className="font-bold bg-secondary-400 px-2 py-1 text-black mt-1 rounded">
            {i18n.moderator.title}
          </span>
        </div>
      </div>
    </PageContainer>
  </div>

};
