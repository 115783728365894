import { FC, HTMLAttributes } from 'react';
import { Group } from '../services/graphql';
import { breadcrumb, PageHeader } from '../shared/PageHeader';
import i18n from "../translations/Forum";

interface ForumHeaderProps extends HTMLAttributes<HTMLDivElement> {
  course: Group
}

export const ForumHeader: FC<ForumHeaderProps> = ({course}) => {
  const breadcrumbs: breadcrumb[] = [
    {
      name: course.organisation?.name as string
    },
    {
      name: course.name as string,
      link: `/course/${course.id}`
    }
  ];

  return <PageHeader 
    title={i18n.forum.title}
    breadcrumbs={breadcrumbs}
  />
};