import { FC } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { useStartConversation } from '../conversations/useStartConversation';
import { Conversation, UsersPermissionsUser } from '../services/graphql';
import { Button } from '../shared/Button';
import i18n from "../translations/Moderators";

interface ReportResponseChatButtonProps {
    user: UsersPermissionsUser,
    mentor?: boolean,
    conversation: Conversation
}

export const ReportResponseChatButton: FC<ReportResponseChatButtonProps> = ({ user, mentor, conversation }) => {
    const {startChat, loading: startChatLoading} = useStartConversation();

    const contact = () => {
        startChat(user.id, {conversationToAttach: conversation});
    }

    const text = mentor ? i18n.response.message(user.firstName || "") :  i18n.response.message(user.firstName || "");

    return <Button onClick={contact} loading={startChatLoading}>
        <FiMessageCircle className="w-6 h-6 mr-2" />
        {text}
    </Button>
};