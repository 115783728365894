import { FC, HTMLAttributes } from "react";
import { merge } from "../helpers/ui";
import { ForumPost, useGetMyTopPostsQuery } from "../services/graphql";
import { ContentLoader } from "../shared/ContentLoader";
import { PostSummary } from "../forum/PostSummary";

interface RecommendedPostsProps extends HTMLAttributes<HTMLDivElement> {
}

export const RecommendedPosts: FC<RecommendedPostsProps> = ({ className }) => {
    const { data, loading, error } = useGetMyTopPostsQuery();

    const topPosts = data?.topPosts;

    return <ContentLoader loading={loading} error={error}>
        <div className={merge(className, "grid lg:grid-cols-2 gap-5")}>
            {topPosts && topPosts.map((post, index) => <PostSummary post={post as ForumPost} key={index} />)}
        </div>
    </ContentLoader>
}
