import { FC, useState } from 'react';
import { useRevokeMentorStatusMutation, UsersPermissionsUser } from '../services/graphql';
import { Button } from '../shared/Button';
import { Modal } from '../shared/Modal';
import i18n from "../translations/Moderators";

interface ReportResponseRevokeButtonProps {
    user: UsersPermissionsUser,
    onRevoke: () => void
}

export const ReportResponseRevokeButton: FC<ReportResponseRevokeButtonProps> = ({ user, onRevoke }) => {
    const [confirmationPromptOpen, setConfirmationPromptOpen] = useState<boolean>(false);

    const [revoke, revokeStatus] = useRevokeMentorStatusMutation();

    const onConfirm = async () => {
        await revoke({
            variables: {
                userId: user.id
            }
        });
        setConfirmationPromptOpen(false);
        onRevoke();
    }
    
    return <>
        <Button secondary onClick={() => setConfirmationPromptOpen(true)}>
            {i18n.response.revokeMentor.button}
        </Button>
        <Modal open={confirmationPromptOpen} onClose={() => setConfirmationPromptOpen(false)}>
            <div className="p-5">
            <div>
                {i18n.response.revokeMentor.clarify(user)}
            </div>
            <div className="flex justify-center gap-5">
                <Button primary loading={revokeStatus.loading} onClick={onConfirm}>
                    {i18n.response.revokeMentor.confirm}
                </Button>
                <Button onClick={() => setConfirmationPromptOpen(false)}>
                    {i18n.response.revokeMentor.cancel}
                </Button>
            </div>
            </div>
        </Modal>
    </>
};