import { FC } from 'react';
import { merge } from '../helpers/ui';

export interface SpinnerProps {
  size?: string
  className?: string
}

// Options: h-10 w-10 h-8 w-8 h-12 w-12 w-4 h-4 h-6 w-6
 
export const Spinner: FC<SpinnerProps> = ({ size = 10, className }) => {
  return <svg className={merge(className, `animate-spin h-${size} w-${size} text-primary-500`)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>;
}