import create from 'zustand';
import { User } from '../authentication/authentication-service';
import { Conversation, Message, Review, UsersPermissionsUser } from '../services/graphql';

export enum ConversationState {
  Idle = 'Idle',
  Open = 'Open',
  Ended = 'Ended'
}

interface ConversationService {
  conversations?: Conversation[]
  drafts: {[key: string]: string}
  selected?: {
    id: string
    draft: string
    state: ConversationState
    conversation?: Conversation
    messages?: Message[]
    otherMember?: UsersPermissionsUser
    review?: Review
  }

  setConversations: (conversations: Conversation[]) => void
  setSelected: (selectedId: string) => void
  setSelectedConversation: (conversation: Conversation, user: User) => void
  setDraft: (value: string) => void
}

export const useConversationService = create<ConversationService>(set => ({
  drafts: {},
  setConversations: (conversations) => set(state => ({ ...state, conversations })),
  setSelected: (selectedId) => set(state => ({
    ...state,
    selected: {
      id: selectedId,
      state: ConversationState.Idle,
      draft: state.drafts[selectedId] ?? ''
    }
  })),
  setSelectedConversation: (conversation, user) => set(state => {
    if (state.selected?.id && state.selected.id !== conversation.id) return state;
    const unsortedMessages = conversation.messages as Message[]
    const messages = unsortedMessages.slice(0).sort((a, b) => {
      if (!a || !b) return 0;
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    });
    const endedById = conversation?.endedBy?.id;
    const members = conversation?.members as (UsersPermissionsUser[] | undefined);
    if (!members) return state;
    const [otherMember] = members.filter(member => member.id !== user?.id.toString());
    let review: Review | undefined;
    if (conversation.review) {
      review = conversation.review;
    }
    return {
      ...state,
      selected: {
        id: conversation.id,
        draft: state.drafts[conversation.id] ?? '',
        conversation,
        messages,
        review,
        state: endedById ? ConversationState.Ended : ConversationState.Open, otherMember 
      }
    }
  }),
  setDraft: (value) => set(state => {
    if (!state.selected) return state;
    return { ...state, drafts: { ...state.drafts, [state.selected.id]: value }, selected: { ...state.selected, draft: value } }
  })
}))
