import { FC } from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Report } from '../../services/graphql';
import { Button } from '../../shared/Button';
import i18n from "../../translations/Moderators";
import { useReportEmailSummary } from './useReportEmailSummary';

interface ReportResponseForwardButtonProps {
    report: Report
}

export const ReportResponseForwardButton: FC<ReportResponseForwardButtonProps> = ({ report }) => {
    const {body, subject} = useReportEmailSummary(report);

    const forward = () => {
        window.open(`mailto:?subject=${subject}&body=${body}`);
    }

    return <Button onClick={forward}>
        <FiArrowRight className="w-6 h-6 mr-2" />
        {i18n.response.forwardViaEmail}
    </Button>
};