import { FC } from 'react';
import { UsersPermissionsUser } from '../services/graphql';
import { Card } from '../shared/Card';
import i18n from "../translations/Courses";
import { HTMLAttributes } from 'react';
import { ProfileIcon } from '../shared/ProfileIcon';
import { Link } from 'react-router-dom';
import { merge } from '../helpers/ui';

interface CourseDetailsCardProps extends HTMLAttributes<HTMLDivElement> {
  mods: UsersPermissionsUser[],
  courseId: string
}

export const CourseDetailsCard: FC<CourseDetailsCardProps> = ({ mods, courseId, className }) => {
  return <Card className={merge(className, "flex flex-col")}>
    <div className="p-5 border-b">
      <div className="text-primary-500">{i18n.home.quickLinks.title}</div>
      <div className="flex flex-col">
        <Link to={`/forum/${courseId}`} className="">
          {i18n.home.quickLinks.links.forum}
        </Link>
        <Link to={`/mentors`} className="">
          {i18n.home.quickLinks.links.mentors}
        </Link>
      </div>
    </div>
    <div className="p-5 border-b">
      <div className="text-primary-500">{i18n.home.moderators.title}</div>
      {mods.map((mod) => <Link to={`/profile/${mod.id}`} className="flex gap-2 items-center py-2">
        <ProfileIcon user={mod} className="w-10 h-10" />
        <span>{`${mod.firstName} ${mod.lastName}`}</span>
      </Link>)}
    </div>
  </Card>;
};
