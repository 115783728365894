import { FC, useEffect, useLayoutEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetReportQuery, Message, Report as IReport, Enum_Report_Reportsubject } from '../services/graphql';
import { ContentLoader } from '../shared/ContentLoader';
import { useReportService } from './reports-service';
import { ReportHeader } from './ReportHeader';
import { ReportChatHistory } from './ReportChatHistory';
import { ReportResponse } from './ReportResponse';
import { ReportPostSummary } from './ReportPostSummary';
import { ReportCommentSummary } from './ReportCommentSummary';

interface ReportParams {
    id: string
}

export const Report: FC = () => {
    const { id } = useParams<ReportParams>();
    const [
        selected,
        setSelected,
        setSelectedReport
    ] = useReportService(state => [state.selected, state.setSelected, state.setSelectedReport])

    const ref = useRef<HTMLDivElement>(null);

    const { data, loading, error, refetch } = useGetReportQuery({ variables: { id: selected?.id! }, skip: !selected });

    useEffect(() => {
        setSelected(id)
    }, [id, setSelected])

    useEffect(() => {
        // When the data changes, set it as the report
        if (!data?.report) return;
        setSelectedReport(data.report as IReport);
    }, [data, setSelectedReport])

    const getMoreMessages = () => {

    }

    useLayoutEffect(() => {
        // When the message list changes, scroll the message list to the bottom.
        if (!ref.current) return;
        ref.current.scrollTop = ref.current.scrollHeight
    }, [selected?.report?.conversation])

    const reporter = selected?.report?.reporter;
    const reportee = selected?.report?.reportee;

    let content: JSX.Element = <></>;

    if (selected?.report) {
        switch (selected.report.reportSubject) {
            case Enum_Report_Reportsubject.Conversation:
                content = <ReportChatHistory
                    report={selected.report}
                    messages={selected?.messages as Message[]}
                    menteeId={reporter?.id}
                    getMoreMessages={getMoreMessages}
                />;
                break;
            case Enum_Report_Reportsubject.Post:
                content = <ReportPostSummary
                    report={selected.report}
                    post={selected.report.forumPost!}
                />;
                break;
            case Enum_Report_Reportsubject.Comment:
                content = <ReportCommentSummary
                    report={selected.report}
                    comment={selected.report.comment!}
                />;
                break;
        }
    }

    return <div className="flex-grow z-10 h-full fixed inset-0 sm:inset-auto sm:relative bg-primary-50">
        <div className="w-full h-full flex flex-col">
            {reporter && reportee && <ReportHeader mentor={reportee} mentee={reporter} />}
            <div ref={ref} className="flex-1 w-full overflow-auto relative">
                <ContentLoader loading={loading} error={error}>
                    {content}
                </ContentLoader>
            </div>
            {selected?.report && <ReportResponse refetch={refetch} report={selected.report} />}
        </div>
    </div>
};
