import { useEffect } from 'react';
import { FC, HTMLAttributes, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { merge } from '../../../helpers/ui';
import { Activity, Scalars, useGetActivitiesQuery, } from '../../../services/graphql';
import { Label } from '../../../shared/Label';
import SearchBar from '../../../shared/search/AutocompleteSearchBar';
import { Title } from '../../../shared/Title';
import i18n from "../../../translations/User";

interface ActivityConfigurationSectionProps extends HTMLAttributes<HTMLDivElement> {
  compact?: boolean
}

export const ActivityConfigurationSection: FC<ActivityConfigurationSectionProps> = ({ children, compact, ...props }) => {
  const [activities, setActivities] = useState<Pick<Activity, 'id' | 'name'>[]>();

  const methods = useFormContext();
  const selectedGroupId = methods.watch('group.id');

  useGetActivitiesQuery({
    variables: { groupId: selectedGroupId },
    onCompleted: data => {
      const activities = data?.activities;
      if (!activities) return;
      setActivities(activities);
    }
  })

  useEffect(() => {
    methods.register('currentActivities');
    methods.register('completedActivities');
  }, [methods])

  const currentActivities: Scalars['ID'][] = methods.watch('currentActivities') ?? []
  const completedActivities: Scalars['ID'][] = methods.watch('completedActivities') ?? []


  return <div className="sm:col-span-3">
    <Title size="xl" className="mb-3">{i18n.profile.courseSummary.modules}</Title>
    <div className="grid sm:grid-cols-2 text-left sm:text-center gap-4 mb-3">
      <div>
        <Label>{i18n.profile.courseSummary.enrolledModules}</Label>
        <div className={merge('min-h-36 rounded mt-1 p-3 border-gray-300 border text-left')}>
          <SearchBar onSearchChange={(item) => {
            methods.setValue('currentActivities', [...currentActivities, item.id])
          }} options={[{
            id: 'Modules',
            items: activities?.map(a => ({
              id: a.id,
              name: a.name!,
              section: 'Modules'
            })) ?? []
          }]} />

          <div className="w-full flex py-3 space-x-3 flex-wrap">
            <div className={merge('flex flex-wrap gap-2 text-sm')}>
              {currentActivities.map(a => activities?.find(ac => ac.id === a)).map((activity) => {
                return <div key={activity?.id} className={merge("px-2 py-1 flex items-center justify-center bg-primary-200 font-semibold rounded max-w-2xs")}>
                  {activity?.name}
                  <button onClick={() => methods.setValue('currentActivities', currentActivities.filter(a => a !== activity?.id))}><FiX className="mt-0.5 ml-1" /></button>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>

      <div>
        <Label>{i18n.profile.courseSummary.completedModules}</Label>
        <div className={merge('min-h-36 rounded mt-1 p-3 border-gray-300 border text-left')}>
          <SearchBar onSearchChange={(item) => {
            methods.setValue('completedActivities', [...completedActivities, item.id])
          }} options={[{
            id: 'Modules',
            items: activities?.map(a => ({
              id: a.id,
              name: a.name!,
              section: 'Modules'
            })) ?? []
          }]} />

          <div className="w-full flex py-3 space-x-3 flex-wrap">
            <div className={merge('flex flex-wrap gap-2 text-sm')}>
              {completedActivities.map(a => activities?.find(ac => ac.id === a)).map((activity) => {
                return <div key={activity?.id} className={merge("px-2 py-1 flex items-center justify-center bg-primary-200 font-semibold rounded max-w-2xs")}>
                  {activity?.name}
                  <button onClick={() => methods.setValue('completedActivities', completedActivities.filter(a => a !== activity?.id))}><FiX className="mt-0.5 ml-1" /></button>
                </div>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
};
