import { FC, HTMLAttributes } from 'react';
import { UseFormReturn, FormProvider, SubmitHandler } from 'react-hook-form';
import { merge } from '../helpers/ui';

interface FormProps extends Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'> {
  methods: UseFormReturn<any>
  onSubmit: SubmitHandler<any>
}

export const Form: FC<FormProps> = ({ children, methods, onSubmit, className, ...props }) => {
  return <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(onSubmit)} className={merge('', className)} {...props}>
      { children }
    </form>
  </FormProvider>
};
