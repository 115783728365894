import { FC } from 'react';
import { useAuthenticationService } from '../authentication/authentication-service';
import { useGetContentQuery } from '../services/graphql';
import i18n from "../translations/Home";
import { HomeRuleCard } from './HomeRuleCard';
import { RecommendedPosts } from './RecommendedPosts';

interface HomeContentsMentorsProps { }

export const HomeContentsMentors: FC<HomeContentsMentorsProps> = () => {

  const { data } = useGetContentQuery();
  const user = useAuthenticationService(state => state.user);

  return <>
    <div className="flex flex-col md:flex-row gap-5">
      { data?.mentorOverview?.rules?.map((rule) => <HomeRuleCard
        title={rule.name}
        text={rule.text}
      />) }
    </div>
    { user?.organisation.hideForum ? null : <div className="my-8">
      <div className="font-bold text-lg mb-2 text-primary-600">{i18n.page.posts.title}</div>
      <RecommendedPosts />
    </div> }
  </>
};
