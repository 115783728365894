import { FC } from 'react';
import { merge } from '../helpers/ui';
import { getDateToDisplay } from '../helpers/date';
import { Conversation, Message, UsersPermissionsUser } from '../services/graphql';
import { ProfileIcon } from '../shared/ProfileIcon';
import i18n from "../translations/Conversation";
import { FiLock } from 'react-icons/fi';

interface ConversationListItemProps {
    conversation: Conversation
    onClick: () => void,
    selected?: boolean,
    userId: string,
    notification?: boolean
}

export const ConversationListItem: FC<ConversationListItemProps> = ({ conversation, notification, onClick, selected, userId }) => {
    const members = conversation.members as UsersPermissionsUser[];
    const otherMembers = members.filter(member => member.id !== userId);
    const conversationEnded = !!conversation.endedBy;

    const lastMessage = conversation.latestMessage as Message;

    const getMembersNamesToDisplay = () => {
        if (otherMembers.length > 1) {
            const firstNames = otherMembers.map(member => member.firstName);
            return firstNames.join(", ");
        } else {
            return `${otherMembers[0].firstName} ${otherMembers[0].lastName}`
        }
    }

    const messageToDisplay = () => {
        if (conversationEnded) {
            return i18n.conversation.endedConversation;
        }

        if (!lastMessage) {
            return i18n.conversation.noMessages;
        }

        let sentByString = lastMessage.sentBy?.firstName;
        if (lastMessage.sentBy?.id === userId) {
            sentByString = "You";
        }

        return `${sentByString}: ${lastMessage.text}`;
    }

    let buttonClass = "py-2 border-b-2 px-4 flex items-center";
    if (selected) {
        buttonClass = merge(buttonClass, "bg-primary-200");
    } else if (conversationEnded) {
        buttonClass = merge(buttonClass, "bg-gray-100");
    }
    
    if (notification) {
        buttonClass = merge(buttonClass, "font-bold");
    }

    if (!selected) {
        buttonClass = merge(buttonClass, "hover:bg-primary-100");
    }

    const timeToShow = conversation.latestMessage?.created_at || conversation.updated_at;

    return <button className={buttonClass} onClick={(e) => {
        e.stopPropagation();
        onClick();
    }}>
        {otherMembers.length > 0 && <ProfileIcon className="w-8 h-8 mr-4" user={otherMembers[0]} />}
        <div className="py-2 flex flex-col justify-center text-left flex-1 overflow-hidden">
            <div className="flex">
                <div className="flex-1">{getMembersNamesToDisplay()}</div>
                <div>{getDateToDisplay(new Date(timeToShow))}</div>
            </div>
            <div className="flex items-center">
                <div className="flex-1 whitespace-nowrap overflow-ellipsis max-w-full overflow-hidden">
                    {messageToDisplay()}
                </div>
                {conversationEnded && <FiLock/>}
            </div>
        </div>
    </button>
};
