import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    helmetPrefix: `Tyfy`,
    inputs: {
      image: {
        empty: "Click to upload a photo"
      },
      maxLengthError: (max: number) => `Must be ${max} characters or fewer`,
      optional: "Optional"
    },
    points: "Points",
    siteSearch: {
      button: "Search",
      types: {
        forum: {
          label: "Forum",
          placeholder: "Search for posts about topics, issues or events"
        },
        mentor: {
          label: "Mentor",
          placeholder: "Search for a mentor"
        }
      }
    },
    errors: {
      strapi: "We're sorry, something went wrong"
    },
    reports: {
      typeSelector: {
        label: "Type of issue",
        notSure: "I'm not sure about the advice given.",
        unsafe: "This person made me feel uncomfortable or unsafe.",
        worried: "I'm worried about this person.",
        required: "Please choose an issue type."
      },
      description: {
        label: "Issue description",
        placeholder: "Give a description of why you want to report this.",
        required: "Please explain the situation."
      },
      form: {
        title: "Report an issue",
        description: `If you're unsure about something, please tell us and our moderators will be in touch. 
        
        {0} won't see anything you write, but a moderator may contact them to discuss the issue.`,
        submit: "Submit",
        cancel: "Cancel"
      },
    }
  }
})
