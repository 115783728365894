import { FC, useEffect, useRef, useLayoutEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useHistory, useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import { dateToGQLFormat } from '../helpers/date';
import { Conversation as IConversation, Enum_Report_Reportsubject, Enum_Userspermissionsuser_Mentorstatus, Review, useCreateMessageMutation, useGetConversationQuery } from '../services/graphql';
import { Button } from '../shared/Button';
import { ContentLoader } from '../shared/ContentLoader';
import { ConversationMessage } from './messages/ConversationMessage';
import { ConversationTextInput } from './messages/ConversationTextInput';

import i18n from '../translations/Conversation';
import { ReviewConversationModal } from './review/ReviewConversationModal';
import { Role, useAuthenticationService } from '../authentication/authentication-service';
import { ProfileIcon } from '../shared/ProfileIcon';
import { ConfirmConversationEndAlert } from './messages/ConfirmConversationEndAlert';
import { ConversationState, useConversationService } from './conversation-service';
import { ReviewCard } from './review/ReviewCard';
import { PrivacyWarning } from './messages/PrivacyWarning';
import { ReportModal } from '../shared/ReportModal';

interface ConversationProps { }

interface ConversationParams {
  id: string
}

export const Conversation: FC<ConversationProps> = (props: ConversationProps) => {
  const { id } = useParams<ConversationParams>();
  const user = useAuthenticationService(state => state.user);

  const [
    selected,
    setSelected,
    setSelectedConversation,
    setDraft
  ] = useConversationService(state => [state.selected, state.setSelected, state.setSelectedConversation, state.setDraft])

  const [showEndConversationAlert, setEndConversationAlertState] = useState<boolean>(false);
  const [reportingIssue, setReportingIssue] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null)
  const history = useHistory();

  const { data, loading, error, refetch } = useGetConversationQuery({ variables: { id: selected?.id! }, skip: !selected });
  const [createMessage, mutationStatus] = useCreateMessageMutation();

  const userIsMentor = user?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;
  const userIsModerator = user?.role.id.toString() === Role.Moderator;
  const otherMemberIsModerator = selected?.otherMember?.role?.id.toString() === Role.Moderator;
  const chatHasMod = userIsModerator || otherMemberIsModerator;

  useEffect(() => {
    setSelected(id)
  }, [id, setSelected])

  useEffect(() => {
    // When the data changes, set it as the conversation
    if (!data?.conversation || !user) return;
    const conversation = data.conversation as IConversation;
    setSelectedConversation(conversation, user);
  }, [data, user, setSelectedConversation])

  useEffect(() => {
    // Every 5 seconds, reload the message feed.
    const id = setInterval(() => {
      refetch()
    }, 5000)
    return () => {
      clearInterval(id);
    };
  }, [refetch]);

  useLayoutEffect(() => {
    // When the message list changes, scroll the message list to the bottom.
    if (!ref.current) return;
    ref.current.scrollTop = ref.current.scrollHeight
  }, [selected?.messages])


  const onSend = async () => {
    if (!selected) return;
    if (selected.draft.length === 0) {
      return;
    }

    const now = new Date();
    await createMessage(
      {
        variables: {
          input: {
            data: {
              text: selected.draft,
              conversation: id,
              sentAt: dateToGQLFormat(now),
              sentBy: user?.id.toString()
            }
          }
        }
      }
    );

    refetch();
    setDraft('');
  }

  const getMoreMessages = async () => {

  }

  const showReviewSummary = (review: Review) => {
    const worstRating = Math.min(review.knowledgeRating || 0, review.helpfulRating || 0, review.speedRating || 0);

    if (review.by?.id === user?.id.toString()) {
      return true;
    } else if (worstRating < 3) {
      return false;
    }

    return true;
  }

  return <div className="flex-grow z-10 h-full fixed inset-0 sm:inset-auto sm:relative bg-primary-50">
    <div className="w-full h-full flex flex-col">
      <ConfirmConversationEndAlert id={id} firstName={selected?.otherMember?.firstName} open={showEndConversationAlert} onClose={() => { setEndConversationAlertState(false); setReportingIssue(false); refetch() }} onSuccess={() => { setEndConversationAlertState(false); refetch() }} />
      {selected?.otherMember?.firstName &&
        <ReviewConversationModal
          id={id}
          firstName={selected?.otherMember?.firstName}
          open={(selected?.state === ConversationState.Ended && !selected?.conversation?.review && !userIsMentor)}
          onClose={() => { refetch() }}
        />
      }
      {selected?.otherMember && selected.otherMember.firstName && user ?
        <ReportModal
          id={id}
          subject={Enum_Report_Reportsubject.Conversation}
          reportee={selected.otherMember}
          open={reportingIssue}
          onClose={() => { setReportingIssue(false); refetch() }}
        /> : null
      }
      <div className="bg-white flex w-full justify-between relative items-center border-b border-gray-100">
        <div className="flex w-full mx-auto max-w-3xl justify-between items-center px-4 py-3">
          <div className="shadow-lg opacity-25 inset-0 absolute pointer-events-none"></div>
          <div className="w-48">
            <button onClick={() => history.push('/messages')} className="block sm:hidden p-2 cursor-pointer rounded hover:bg-gray-50">
              <FiChevronLeft className="w-7 h-7" />
            </button>
          </div>
          <Link to={`/profile/${selected?.otherMember?.id}`} className="flex flex-col text-center items-center">
            <ProfileIcon user={selected?.otherMember} className="w-11 h-11" />
            {selected?.otherMember ? <span className="text-sm mt-1">{selected.otherMember.firstName} {selected.otherMember.lastName}</span> : <Skeleton width={80} />}
          </Link>
          <div className="w-48 flex justify-end">
            {!chatHasMod && <div className="w-1/2 sm:w-full gap-2 flex flex-col sm:flex-row justify-end">
              <Button secondary onClick={() => setReportingIssue(true)}>{i18n.report.button.label}</Button>
              {selected?.state === ConversationState.Open ? <Button onClick={() => setEndConversationAlertState(true)}>{i18n.conversation.end}</Button> : null}
            </div>}
          </div>
        </div>
      </div>
      <div ref={ref} className="flex-1 w-full overflow-auto relative">
        <ContentLoader loading={loading} error={error}>
          <div className="flex mx-auto max-w-3xl my-4 px-4 flex-col items-start">
            <PrivacyWarning/>
            {selected?.messages && user ? <>
              {selected.messages.length >= 20 ? <Button compact className="w-full mb-4" onClick={() => getMoreMessages()}>{i18n.conversation.loadMore}</Button> : null}
              {selected.messages.map(message => {
                return <ConversationMessage
                  key={message!.id}
                  message={message}
                />
              })}
            </> : null}
            {selected?.review && showReviewSummary(selected.review) && <ReviewCard review={selected.review} />}
          </div>
        </ContentLoader>
      </div>
      <div className="w-full flex justify-center items-center h-24 p-5 bg-white">
        <div className="max-w-3xl w-full px-4">
          {(() => {
            switch (selected?.state) {
              case ConversationState.Open:
                return <ConversationTextInput value={selected.draft} onChange={setDraft} onSend={onSend} loading={mutationStatus.loading} />

              case ConversationState.Ended:
                return <p className="text-center">{i18n.conversation.endedTextBox}</p>
            }
          })()}
        </div>
      </div>
    </div>
  </div>
};
