import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { log } from '../../helpers/log';
import { Button } from '../../shared/Button';
import { Form } from '../../shared/Form';
import { useState } from 'react';
import { State } from '../../helpers/state';

import { Welcome } from './Welcome';
import { Title } from '../../shared/Title';
import { ImageDetails } from '../../shared/inputs/ImageInput';
import { useGetMeQuery, UsersPermissionsUser, useUpdateUserMutation } from '../../services/graphql';
import { PersonalDetailsSection } from '../../user/editProfile/forms/PersonalDetailsSection';
import { OrganisationDetailsSection } from '../../user/editProfile/forms/OrganisationDetailsSection';
import { merge } from '../../helpers/ui';
import { ActivityConfigurationSection } from '../../user/editProfile/forms/ActivityConfigurationSection';
import { useAuthenticationService, User } from '../authentication-service';
import { MentorRecommendations } from '../../mentors/MentorRecommendations';
import { useHistory } from 'react-router-dom';
import i18n from "../../translations/Authentication";
import { PageHelmet } from '../../shared/Helmet';

interface OnboardingProps { }

enum OnboadingPages {
  FOUND,
  PERSONAL,
  ORGANISATION,
  MODULES,
  MATCHES
}

interface OnboardingFormFields {
  firstName: string,
  lastName: string,
  email: string,
  profilePicture?: ImageDetails,
  organisation: {
    id: string
  },
  level: {
    id: string
  },
  group: {
    id: string
  },
  completedActivities: string[],
  currentActivities: string[]
}

export const Onboarding: FC<OnboardingProps> = () => {
  const [user, setUser] = useState<UsersPermissionsUser>();
  const [state, setState] = useState<State>(State.Idle)
  const [page, setPage] = useState<OnboadingPages>(OnboadingPages.FOUND);
  const history = useHistory();

  const updateUserInfo = useAuthenticationService(state => state.updateUserInfo);

  const methods = useForm<OnboardingFormFields>({ shouldUnregister: false })

  const { loading, error } = useGetMeQuery(
    {
      onCompleted: data => {
        const details = data?.me?.user as UsersPermissionsUser;
        if (!details) return;

        setUser(details);
        methods.reset({
          ...details,
          completedActivities: details.completedActivities?.map(d => d.id),
          currentActivities: details.currentActivities?.map(d => d.id),
        })
      }
    }
  );

  const [updateUser] = useUpdateUserMutation();

  const onboard = async (input: OnboardingFormFields) => {
    if (!user) return;
    setState(State.Loading);

    try {
      const { data } = await updateUser(
        {
          variables: {
            input: {
              where: {
                id: user.id
              },
              data: {
                firstName: input.firstName,
                lastName: input.lastName,
                email: input.email,
                profilePicture: input.profilePicture?.id,
                organisation: input.organisation.id,
                level: input.level.id,
                group: input.group.id,
                isOnboarded: true,
                completedActivities: input.completedActivities,
                currentActivities: input.currentActivities
              }
            }
          }
        }
      );
      const updatedUser = data?.updateUser?.user as User;
      if (updatedUser) updateUserInfo(updatedUser);
      setState(State.Success);
      setPage(OnboadingPages.MATCHES);
    } catch (e) {
      log('Log in was unsuccessful.', e);
      setState(State.Failure);
    }
  }

  return <Welcome loading={loading} error={error}>
    <PageHelmet pageTitle={i18n.helmets.onboarding}/>
    <div className="flex flex-col w-full max-w-2xl sm:p-8">
      {/* <Title size="2xl" className="mb-4">{i18n.Onboarding.message}</Title> */}
      <Form className="text-center" methods={methods} onSubmit={onboard}>
        <div className={merge('space-y-4', page !== OnboadingPages.FOUND ? 'hidden' : null)}>
          <Title size="xl">{i18n.onboarding.almostThere}</Title>
          { user?.firstName
            ? <p>{i18n.onboarding.alreadyGotInfo}</p>
            : <p>{i18n.onboarding.emailFromOrg}</p>
          }

          <Button className="w-full" primary onClick={() => setPage(page + 1)}>{i18n.onboarding.getStarted}</Button>
        </div>
        <div className={merge('grid grid-cols-1 gap-2', page !== OnboadingPages.PERSONAL ? 'hidden' : null)}>
          <PersonalDetailsSection compact />
          <div className="flex justify-between">
            <Button className="mt-2 w-1/3" onClick={() => setPage(page - 1)}>{i18n.onboarding.back}</Button>
            <Button className="mt-2 w-1/3" primary onClick={() => setPage(page + 1)}>{i18n.onboarding.next}</Button>
          </div>
        </div>
        <div className={merge( page !== OnboadingPages.ORGANISATION ? 'hidden' : null)}>
          <OrganisationDetailsSection />
          <div className="flex justify-between">
            <Button className="mt-2 w-1/3" onClick={() => setPage(page - 1)}>{i18n.onboarding.back}</Button>
            <Button className="mt-2 w-1/3" primary onClick={() => setPage(page + 1)}>{i18n.onboarding.next}</Button>
          </div>
        </div>
        <div className={merge( page !== OnboadingPages.MODULES ? 'hidden' : null)}>
          <ActivityConfigurationSection compact />
          <div className="flex justify-between">
            <Button className="mt-2 w-1/3" onClick={() => setPage(page - 1)}>{i18n.onboarding.back}</Button>
            <Button loading={state === State.Loading} className="mt-2 w-1/3" primary submit>{i18n.onboarding.submit}</Button>
          </div>
        </div>
        <div className={merge( page !== OnboadingPages.MATCHES ? 'hidden' : null)}>
          <Title size="xl">{i18n.onboarding.matchingUp}</Title>
          <p className="mb-4">{i18n.onboarding.mentorsIntro}</p>
          <MentorRecommendations />
          <Button className="mt-2" onClick={() => history.push("/")}>{i18n.onboarding.chatLater}</Button>
        </div>
      </Form>
    </div>
  </Welcome>;
};
