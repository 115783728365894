import { FC } from 'react';
import { PageContainer } from '../shared/PageContainer';
import { Enum_Userspermissionsuser_Mentorstatus, useGetMeQuery, UsersPermissionsUser } from '../services/graphql';
import { HomeHeader } from './HomeHeader';
import { ContentLoader } from '../shared/ContentLoader';
import { HomeContentsMentees } from './HomeContentsMentees';
import { HomeContentsMentors } from './HomeContentsMentors';
import { PageHelmet } from '../shared/Helmet';
import i18n from '../translations/Home';

interface HomeProps { }

export const Home: FC<HomeProps> = () => {
  const { data, loading, error } = useGetMeQuery();

  const user = data?.me?.user as UsersPermissionsUser;
  const userIsMentor = user?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;

  return <ContentLoader loading={loading} error={error}>
    {user && <>
      <PageHelmet pageTitle={i18n.helmets.home}/>
      <HomeHeader user={user} />
      <PageContainer
        className="flex flex-col gap-5 -mt-10"
        style={{paddingTop: 0}}
      >
        {userIsMentor ? <HomeContentsMentors/> : <HomeContentsMentees/>}
      </PageContainer>
    </>}
  </ContentLoader>
};
