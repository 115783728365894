import LocalizedStrings from 'react-localization';
import { getDateAndTimeToDisplay } from '../helpers/date';
import { Message, UsersPermissionsUser } from '../services/graphql';

export default new LocalizedStrings({
  en: {
    helmets: {
      reports: "Reports"
    },
    reportList: {
      problem: "Problem: ",
    },
    subjects: {
      conversation: "Chat",
      forum: "Forum"
    },
    reasons: {
      inaccurate: "Unsure about advice",
      inappropriate: "Uncomfortable or unsafe",
      worried: "Worried about mentee",
      poorReview: "Bad review"
    },
    roles: {
      mentor: "Reportee",
      mentee: "Reporter"
    },
    chat: {
      getMore: "Load more"
    },
    summary: {
      title: (problem: string) => `Report - ${problem}`,
      quote: (issue: string) => `You said: ${issue}`,
      explanation: (firstName: string) => `You submitted a report to a moderator. ${firstName} can't see this, but they may be contacted by a moderator.`
    },
    response: {
      message: (firstName: string) => `Message ${firstName}`,
      forwardViaEmail: "Forward",
      revokeMentor: {
        button: "Revoke Mentor Status",
        clarify: (user: UsersPermissionsUser) => `Are you sure you want to revoke ${user.firstName} ${user.lastName}'s status as a mentor?`,
        confirm: "Revoke",
        cancel: "Cancel"
      },
      mentorRevoked: (user: UsersPermissionsUser) => `${user.firstName} ${user.lastName}'s status as a mentor has been revoked.`
    },
    emailReport: {
      subject: (about: UsersPermissionsUser, problem: string) => `Issue regarding ${about.firstName} ${about.lastName}: ${problem}`,
      problemSummary: "Problem summary",
      reporter: (reporter: UsersPermissionsUser) => `Report made by: ${reporter.firstName} ${reporter.lastName} (${reporter.email})`,
      reportee: (reportee: UsersPermissionsUser) => `Report made about: ${reportee.firstName} ${reportee.lastName} (${reportee.email})`,
      madeAt: (time: Date) => `Made at: ${getDateAndTimeToDisplay(time)}`,
      reason: (reason: string) => `Reason for report: ${reason}`,
      description: (description: string) => `Description: ${description}`,
      conversation: {
        chatHistory: "Chat History",
        message: (message: Message) => `${message.sentBy?.firstName} ${message.sentBy?.lastName} (${getDateAndTimeToDisplay(message.sentAt)}): ${message.text}`,
      },
      forumPost: {
        title: "Forum post",
        postTitle: (title: string) => `Title: ${title}`,
        postText: (text: string) => `Text: ${text}`,
        postedBy: (postedBy: UsersPermissionsUser) => `Posted by: ${postedBy.firstName} ${postedBy.lastName}`
      },
      comment: {
        title: "Comment",
        originalPostTitle: (title: string) => `Original Post Title: ${title}`,
        originalPostText: (text: string) => `Original Post Text: ${text}`,
        comment: (text: string) => `Comment: ${text}`,
        postedBy: (postedBy: UsersPermissionsUser) => `Posted by: ${postedBy.firstName} ${postedBy.lastName}`
      },
      divider: "----------------------------------"
    },
    speakToModerator: {
      title: "Contact Moderator",
      message: "Would you like to start a chat with the moderator?"
    },
    forum: {
      post: {
        cardLabel: "Reported Forum Post",
        title: (title: string) => `Title: ${title}`,
        text: (text: string) => `Text: ${text}`
      },
      comment: {
        cardLabel: "Reported Comment",
        originalPost: "Original Post:",
        comment: "Reported Comment:"
      }
    }
  }
})
