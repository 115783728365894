import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ForumPostTitleAndText } from '../forum/ForumPostTitleAndText';
import { Enum_Userspermissionsuser_Mentorstatus, Comment, Report } from '../services/graphql';
import { Card } from '../shared/Card';
import i18n from "../translations/Moderators";
import { ReportSummaryCard } from './ReportSummaryCard';

interface ReportCommentSummaryProps {
    comment: Comment,
    report: Report
}

export const ReportCommentSummary: FC<ReportCommentSummaryProps> = ({ comment, report }) => {
    const revokedMentor = report.reportee?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Revoked;

    return <div className="flex mx-auto max-w-3xl my-4 px-4 flex-col items-center">
        <Card className="p-5 w-full flex flex-col gap-2">
            <div className="font-bold text-lg w-full text-center text-primary-500">
                {i18n.forum.comment.cardLabel}
            </div>
            <div className="text-lg text-primary-500">
                {i18n.forum.comment.originalPost}
            </div>
            <Link to={`/forum/posts/${comment.forumPost!.id}`}>
                <div className="border p-2 rounded">
                    <ForumPostTitleAndText post={comment.forumPost!} />
                </div>
            </Link>
            <div className="text-lg text-primary-500">
                {i18n.forum.comment.comment}
            </div>
            <div className="border p-2 rounded">
                <div className="font-bold">
                    {`${comment.postedBy?.firstName} ${comment.postedBy?.lastName}`}
                </div>
                <div>
                    {comment.text}
                </div>
            </div>
        </Card>
        <ReportSummaryCard report={report}/>
        {revokedMentor && <Card className="self-center flex flex-col items-center gap-2 m-5 p-5">
            <span className="text-primary-600 font-bold text-lg">{i18n.response.mentorRevoked(report.reportee!)}</span>
        </Card>}
    </div>
};
