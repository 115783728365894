import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { log } from '../../helpers/log';
import { Button } from '../../shared/Button';
import { resetPassword, useAuthenticationService } from '../authentication-service';
import { Form } from '../../shared/Form';
import { PasswordField } from '../../shared/inputs/PasswordInput';
import { useState } from 'react';
import { State } from '../../helpers/state';

import i18n from '../../translations/Authentication';
import { Welcome } from './Welcome';
import { Title } from '../../shared/Title';
import { PageHelmet } from '../../shared/Helmet';

interface ResetPasswordFormFields {
  password: string,
  confirmPassword: string
}

export const ResetPassword: FC = () => {
  const location = useLocation();
  const [state, setState] = useState<State>(State.Idle)
  const [errors, setErrors] = useState<any>();
  const history = useHistory();

  const [setUserInfo, clearUserInfo] = useAuthenticationService(state => [state.setUserInfo, state.clearUserInfo]);
  const methods = useForm<ResetPasswordFormFields>()

  const register = async (data: ResetPasswordFormFields) => {
    setState(State.Loading);

    const params = new URLSearchParams(location.search)
    const code = params.get('code');

    if (!code) throw new Error('No code!');
    clearUserInfo();


    try {
      const { jwt, user } = await resetPassword(
        code,
        data.password,
        data.confirmPassword
      );

      setUserInfo(jwt, user);
      setState(State.Success);

      history.push('/');
    } catch (e) {
      log('Reset password was unsuccessful.', e);
      setState(State.Failure);
      setErrors(e);
    }
  }

  return <Welcome>
    <div className="flex flex-col w-full max-w-md p-8">
      <PageHelmet pageTitle={i18n.helmets.resetPassword} />
      <Title size="2xl" className="mb-4">{i18n.resetPassword.title}</Title>
      <Form methods={methods} onSubmit={register}>
        <PasswordField name="password" title={i18n.newPassword.label} placeholder="********" requiredMessage={i18n.newPassword.requiredMessage} />
        <PasswordField name="confirmPassword" title={i18n.confirmPassword.label} placeholder="********" requiredMessage={i18n.confirmPassword.requiredMessage} />
        {errors ? <div className="text-red-500">{errors.message}</div> : null}
        <Button loading={state === State.Loading} className="mt-2 w-full" primary submit>{i18n.resetPassword.confirmButton}</Button>
      </Form>
    </div>
  </Welcome>;
};
