import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    helmets: {
      mentors: "Mentors"
    },
    mentorCard: {
      hasStudied: (firstName: string) => `${firstName} has studied:`,
      studentsHelped: (count: number) => `And they've helped ${count} student${count > 1 ? "s" : ""} so far!`,
      studentsHelpedNoCourseList: (count: number) => `They've helped ${count} student${count > 1 ? "s" : ""} so far!`,
      askAQuestion: "Ask a question",
      viewProfile: "View profile",
    },
    noMentors: "There are no mentors available at the moment. Check back later to see if any have become available!",
    search: {
      title: "Mentor Search",
      searchButton: {
        label: "Search",
        placeHolder: "Search for a mentor"
      },
      headers: {
        person: "Person",
        course: "Course",
        year: "Year",
        modules: "Modules"
      },
      paging: {
        prev: "Previous page",
        next: "Next page",
        count: (page: number, total: number) => `Page ${page} of ${total}`
      },
      noResults: "There are no mentors matching your search criteria."
    }
  }
})
