import { ApolloError } from '@apollo/client';
import { FC } from 'react';
import { ContentLoader } from '../../shared/ContentLoader';

interface WelcomeProps {
  loading?: boolean
  error?: ApolloError
}

export const TyfyLogo = <svg className="w-full" viewBox="0 0 242 71" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path d="M65.1008 21.3101H56.7113C56.5544 21.3161 56.3982 21.2874 56.2536 21.226C56.1091 21.1646 55.9799 21.072 55.8753 20.9548C55.6681 20.7421 55.5513 20.4575 55.5493 20.1606V13.1715C55.5493 12.3605 55.9339 11.9592 56.6988 11.9592H84.4506C85.2155 11.9592 85.6001 12.3772 85.6001 13.1715V20.1606C85.5981 20.4575 85.4812 20.7421 85.274 20.9548C85.1695 21.072 85.0403 21.1646 84.8958 21.226C84.7513 21.2874 84.5949 21.3161 84.438 21.3101H76.0151V50.8927C76.0213 51.0485 75.9939 51.2038 75.9347 51.348C75.8756 51.4923 75.7861 51.6221 75.6724 51.7288C75.5709 51.832 75.4498 51.9139 75.3163 51.9699C75.1829 52.0259 75.0396 52.0547 74.8948 52.0548H66.288C65.4812 52.0548 65.0757 51.6702 65.0757 50.9053L65.1008 21.3101Z" fill="currentColor" />
    <path d="M96.8991 48.5311L88.2003 22.1964C88.1922 22.0328 88.2215 21.8694 88.286 21.7187C88.3504 21.5681 88.4484 21.4341 88.5724 21.327C88.8051 21.1273 89.1017 21.0175 89.4084 21.0176H97.9818C98.5419 21.0176 98.9265 21.4106 99.1313 22.1964L103.203 39.0715L107.274 22.1964C107.483 21.3897 107.864 20.9967 108.424 21.0176H117.001C117.307 21.0201 117.603 21.1295 117.837 21.327C117.966 21.4312 118.068 21.5642 118.136 21.7152C118.203 21.8663 118.234 22.0311 118.226 22.1964L109.448 49.2626C108.79 51.3877 107.905 53.4354 106.806 55.3697C106.019 56.7824 105.046 58.0829 103.913 59.2363C102.983 60.1293 101.892 60.8385 100.699 61.3264C99.7077 61.7547 98.6651 62.0526 97.5972 62.2126C96.5548 62.3265 95.5065 62.3781 94.4579 62.3672C93.1828 62.4115 91.9068 62.312 90.654 62.0704C89.5925 61.7478 88.5671 61.3163 87.5942 60.783C87.3879 60.6109 87.2202 60.3972 87.1021 60.1559C86.984 59.9146 86.9181 59.6511 86.9087 59.3826L86.9714 59.1067C87.0132 58.9395 87.1386 58.3501 87.3434 57.3344C87.5482 56.3186 87.8283 54.9183 88.1794 53.1543C88.2488 52.9804 88.3687 52.8312 88.5237 52.7262C88.6787 52.6211 88.8617 52.5649 89.0489 52.5649C90.4303 53.0421 91.8767 53.3043 93.3377 53.3424C94.8314 53.3424 95.7942 52.9592 96.2261 52.1928C96.6817 51.3986 96.8991 50.1906 96.8991 48.5311Z" fill="currentColor" />
    <path d="M134.303 52.0422H125.662C125.37 52.04 125.089 51.9298 124.872 51.7329C124.754 51.6289 124.661 51.5 124.598 51.3554C124.536 51.2108 124.507 51.0542 124.513 50.8969V30.0256H121.967C121.801 30.033 121.635 30.0031 121.481 29.938C121.328 29.8729 121.191 29.7743 121.081 29.6494C120.873 29.4205 120.757 29.1227 120.755 28.8134V22.1252C120.756 21.9797 120.787 21.8359 120.846 21.7028C120.905 21.5697 120.99 21.4503 121.098 21.3519C121.211 21.2381 121.347 21.1489 121.497 21.09C121.646 21.031 121.806 21.0035 121.967 21.0091H124.513V18.4593C124.513 15.2267 125.549 12.6684 127.623 10.7846C129.712 8.89259 132.451 7.88027 135.268 7.95884C136.938 7.95052 138.604 8.11725 140.238 8.45628C140.561 8.5019 140.857 8.66036 141.074 8.90355C141.287 9.10388 141.415 9.37722 141.434 9.66851V15.9763C141.432 16.2856 141.316 16.5834 141.108 16.8123C140.999 16.9399 140.863 17.0409 140.709 17.1076C140.556 17.1743 140.389 17.2048 140.222 17.1969C139.673 17.0719 139.112 17.0102 138.55 17.013C136.643 17.013 135.608 17.8309 135.444 19.4667V21.0217H139.578C139.891 21.0208 140.192 21.1441 140.414 21.3644C140.525 21.4608 140.614 21.5795 140.676 21.7128C140.738 21.846 140.771 21.9908 140.773 22.1378V28.8259C140.771 29.1329 140.66 29.4292 140.46 29.662C140.351 29.7931 140.213 29.8966 140.057 29.9642C139.901 30.0318 139.731 30.0614 139.561 30.0507H135.427V50.9303C135.433 51.0873 135.404 51.2436 135.343 51.3881C135.281 51.5326 135.189 51.6618 135.072 51.7664C134.857 51.9487 134.584 52.0468 134.303 52.0422V52.0422Z" fill="currentColor" />
    <path d="M152.193 48.5311L143.49 22.1964C143.482 22.0323 143.512 21.8686 143.577 21.7178C143.642 21.567 143.741 21.4333 143.866 21.327C144.099 21.1269 144.396 21.0172 144.702 21.0176H153.28C153.84 21.0176 154.225 21.4106 154.43 22.1964L158.501 39.0715L162.572 22.1964C162.777 21.3897 163.162 20.9967 163.722 21.0176H172.3C172.606 21.0208 172.901 21.1301 173.136 21.327C173.263 21.432 173.364 21.5653 173.431 21.7162C173.497 21.8672 173.528 22.0316 173.52 22.1964L164.742 49.2626C164.088 51.3891 163.202 53.4371 162.1 55.3697C161.315 56.7822 160.343 58.0827 159.212 59.2363C158.281 60.1293 157.191 60.8385 155.997 61.3264C155.009 61.7598 153.97 62.0633 152.904 62.2293C151.861 62.3432 150.813 62.3949 149.765 62.384C148.49 62.4285 147.213 62.3289 145.961 62.0872C144.898 61.7639 143.871 61.3325 142.897 60.7997C142.691 60.6274 142.524 60.4136 142.406 60.1723C142.289 59.931 142.224 59.6676 142.215 59.3994L142.278 59.1235C142.32 58.9563 142.441 58.3669 142.65 57.3511C142.859 56.3353 143.135 54.935 143.486 53.171C143.556 52.9968 143.677 52.8475 143.833 52.7425C143.988 52.6374 144.172 52.5814 144.36 52.5816C145.741 53.0592 147.188 53.3215 148.649 53.3591C150.137 53.3591 151.102 52.9745 151.537 52.2096C151.972 51.4446 152.193 50.1906 152.193 48.5311Z" fill="currentColor" />
    <path d="M174.377 50.8928C173.803 50.3444 173.348 49.6834 173.041 48.9512C172.734 48.219 172.581 47.4313 172.592 46.6374C172.583 45.8407 172.737 45.0504 173.044 44.3151C173.35 43.5798 173.804 42.9149 174.377 42.3612C174.931 41.7887 175.595 41.3352 176.329 41.0283C177.064 40.7214 177.853 40.5676 178.649 40.5763C179.445 40.5676 180.235 40.7214 180.969 41.0283C181.704 41.3352 182.368 41.7887 182.921 42.3612C183.495 42.9146 183.95 43.5793 184.257 44.3146C184.565 45.05 184.719 45.8404 184.71 46.6374C184.721 47.4316 184.567 48.2194 184.259 48.9516C183.952 49.6837 183.496 50.3446 182.921 50.8928C182.367 51.463 181.702 51.9142 180.968 52.2189C180.233 52.5236 179.444 52.6755 178.649 52.6651C177.854 52.6755 177.065 52.5236 176.331 52.2189C175.596 51.9142 174.932 51.463 174.377 50.8928V50.8928Z" fill="#EDA600" />
    <path d="M198.81 34.486V38.5574C198.81 41.8708 200.405 43.5275 203.596 43.5275C204.278 43.5325 204.96 43.4752 205.632 43.3561C206.264 43.2447 206.974 43.0956 207.76 42.9089C208.545 42.7208 209.231 42.587 209.812 42.4909C210.001 42.4826 210.188 42.5317 210.348 42.6317C210.508 42.7316 210.635 42.8778 210.711 43.051C211.522 47.298 211.926 49.5664 211.923 49.8562C211.909 50.0396 211.843 50.2154 211.734 50.3633C211.624 50.5111 211.476 50.6252 211.304 50.6922C208.88 51.9156 205.441 52.5273 200.988 52.5273C196.905 52.5273 193.7 51.1701 191.373 48.4559C189.047 45.7416 187.882 42.2205 187.879 37.8927V35.0963C187.879 30.7657 189.044 27.2432 191.373 24.529C193.703 21.8147 196.908 20.4575 200.988 20.4575C205.446 20.4575 208.885 21.079 211.304 22.3219C211.475 22.3818 211.625 22.4906 211.735 22.6347C211.845 22.7788 211.91 22.952 211.923 23.1328C211.923 23.4115 211.519 25.6799 210.711 29.938C210.637 30.1153 210.513 30.2663 210.352 30.3715C210.192 30.4766 210.004 30.531 209.812 30.5274C209.108 30.4263 208.41 30.2867 207.722 30.1094C206.949 29.9129 206.242 29.7624 205.632 29.6579C204.959 29.551 204.278 29.4993 203.596 29.5033C200.408 29.5116 198.813 31.1725 198.81 34.486Z" fill="#EDA600" />
    <path d="M228.154 20.47C236.935 20.47 241.327 25.2255 241.33 34.7367V38.3065C241.33 47.8176 236.938 52.562 228.154 52.5397C219.371 52.5174 214.958 47.773 214.916 38.3065V34.7367C214.916 25.2283 219.329 20.4728 228.154 20.47ZM230.453 40.919V32.1408C230.471 31.4627 230.232 30.803 229.785 30.2932C229.587 30.053 229.338 29.8601 229.056 29.7284C228.774 29.5968 228.466 29.5299 228.154 29.5324C227.843 29.5299 227.535 29.5968 227.253 29.7284C226.971 29.8601 226.722 30.053 226.524 30.2932C226.077 30.803 225.838 31.4627 225.855 32.1408V40.919C225.838 41.5971 226.077 42.2568 226.524 42.7666C226.722 43.0068 226.971 43.1998 227.253 43.3314C227.535 43.463 227.843 43.53 228.154 43.5274C228.466 43.53 228.774 43.463 229.056 43.3314C229.338 43.1998 229.587 43.0068 229.785 42.7666C230.232 42.2568 230.471 41.5971 230.453 40.919V40.919Z" fill="#EDA600" />
    <path d="M13.2676 22.4639C19.4708 22.4639 24.4995 17.4352 24.4995 11.2319C24.4995 5.02871 19.4708 0 13.2676 0C7.06436 0 2.03564 5.02871 2.03564 11.2319C2.03564 17.4352 7.06436 22.4639 13.2676 22.4639Z" fill="#EDA600" />
    <path d="M41.5459 70.8904C47.7491 70.8904 52.7778 65.8617 52.7778 59.6585C52.7778 53.4552 47.7491 48.4265 41.5459 48.4265C35.3427 48.4265 30.314 53.4552 30.314 59.6585C30.314 65.8617 35.3427 70.8904 41.5459 70.8904Z" fill="#EDA600" />
    <path d="M9.61441 18.4258H8.70312V17.4142C8.70534 16.2047 9.1871 15.0454 10.0428 14.1905C10.8985 13.3356 12.0583 12.8549 13.2678 12.8538C14.4774 12.8549 15.6371 13.3356 16.4928 14.1905C17.3485 15.0454 17.8303 16.2047 17.8325 17.4142V18.4258H16.9212" fill="#010101" />
    <path d="M10.2329 6.45825V11.0062C10.2329 11.8155 10.5544 12.5917 11.1266 13.1639C11.6989 13.7362 12.4751 14.0577 13.2844 14.0577C13.6849 14.0577 14.0815 13.9787 14.4515 13.8253C14.8215 13.6719 15.1576 13.4471 15.4406 13.1637C15.7237 12.8803 15.948 12.5438 16.1009 12.1736C16.2538 11.8034 16.3322 11.4067 16.3317 11.0062V6.45825H10.2329Z" fill="white" />
    <path d="M13.2718 14.5134C12.3416 14.5134 11.4496 14.1439 10.7919 13.4862C10.1342 12.8285 9.76465 11.9364 9.76465 11.0063V6.45832C9.76465 6.33747 9.81264 6.22158 9.89808 6.13613C9.98353 6.05069 10.0994 6.00269 10.2203 6.00269H16.3232C16.3828 6.00268 16.4419 6.0145 16.497 6.03745C16.552 6.0604 16.602 6.09402 16.644 6.13639C16.6859 6.17875 16.7191 6.22902 16.7415 6.28428C16.764 6.33953 16.7752 6.39868 16.7747 6.45832V11.0063C16.7747 11.9357 16.4058 12.8271 15.749 13.4847C15.0921 14.1423 14.2012 14.5123 13.2718 14.5134V14.5134ZM10.6759 6.90977V11.0063C10.6759 11.6947 10.9494 12.355 11.4362 12.8418C11.923 13.3286 12.5833 13.6021 13.2718 13.6021C13.9599 13.601 14.6195 13.3272 15.1061 12.8406C15.5926 12.354 15.8665 11.6944 15.8676 11.0063V6.90977H10.6759Z" fill="#010101" />
    <path d="M16.319 9.28393L13.2675 10.8473L10.2202 9.28393V6.70898H16.319V9.28393Z" fill="white" />
    <path d="M13.2677 11.2989C13.1963 11.3 13.1257 11.2827 13.0629 11.2487L10.0114 9.68955C9.93538 9.64981 9.87203 9.5896 9.82845 9.51573C9.78487 9.44186 9.7628 9.35728 9.76477 9.27154V6.6966C9.76477 6.57686 9.81232 6.46204 9.89698 6.37737C9.98164 6.29271 10.0965 6.24514 10.2162 6.24514H16.3192C16.3788 6.24458 16.4379 6.25586 16.4932 6.2783C16.5485 6.30074 16.5988 6.3339 16.6411 6.37588C16.6835 6.41786 16.7171 6.46782 16.7401 6.52286C16.763 6.57791 16.7748 6.63696 16.7748 6.6966V9.27154C16.7769 9.35782 16.7544 9.4429 16.71 9.5169C16.6656 9.5909 16.6011 9.65078 16.524 9.68955L13.4767 11.2487C13.4119 11.2816 13.3403 11.2988 13.2677 11.2989ZM10.6719 9.00819L13.2677 10.3333L15.8635 9.00819V7.16476H10.6719V9.00819Z" fill="#010101" />
    <path d="M13.2676 3.91675L8.18457 6.45825L13.2676 8.99976L18.3548 6.45825L13.2676 3.91675Z" fill="#010101" />
    <path d="M13.2677 9.45129C13.198 9.45293 13.129 9.43713 13.067 9.40532L7.97984 6.8638C7.90337 6.82426 7.83941 6.76421 7.79512 6.69039C7.75083 6.61657 7.72795 6.53188 7.72904 6.44579C7.72795 6.35971 7.75083 6.27502 7.79512 6.2012C7.83941 6.12737 7.90337 6.06733 7.97984 6.02778L13.067 3.48628C13.1318 3.45326 13.2034 3.43604 13.276 3.43604C13.3487 3.43604 13.4203 3.45326 13.4851 3.48628L18.568 6.02778C18.6451 6.06655 18.7097 6.12643 18.7541 6.20043C18.7985 6.27443 18.8209 6.35952 18.8189 6.44579C18.8209 6.53207 18.7985 6.61717 18.7541 6.69117C18.7097 6.76517 18.6451 6.82503 18.568 6.8638L13.4851 9.40532C13.4181 9.43997 13.343 9.45585 13.2677 9.45129ZM9.20045 6.45833L13.2677 8.48987L17.3349 6.45833L13.2677 4.42262L9.20045 6.45833Z" fill="#010101" />
    <path d="M49.9437 46.7043C49.8281 46.7039 49.714 46.6782 49.6093 46.6291C49.4289 46.54 49.2912 46.3832 49.2262 46.1928C49.1612 46.0025 49.1742 45.7941 49.2624 45.6133C51.0744 41.9293 51.9506 37.8555 51.8136 33.7522C51.6765 29.6489 50.5303 25.6426 48.4765 22.0877C46.2736 18.2923 43.1143 15.1407 39.3136 12.947C35.5129 10.7533 31.2036 9.59407 26.8152 9.58501C26.7155 9.58501 26.6168 9.5653 26.5247 9.52702C26.4326 9.48873 26.349 9.43263 26.2787 9.36193C26.2084 9.29123 26.1528 9.20733 26.115 9.11505C26.0772 9.02277 26.0581 8.92393 26.0586 8.82422C26.0586 8.62356 26.1383 8.43111 26.2802 8.28922C26.4221 8.14733 26.6146 8.06763 26.8152 8.06763V8.06763C31.4725 8.0751 36.0465 9.3038 40.0806 11.6311C44.1147 13.9585 47.468 17.303 49.8058 21.3311C51.9838 25.1014 53.1995 29.3503 53.3453 33.702C53.4911 38.0538 52.5626 42.3745 50.6418 46.2822C50.5776 46.4115 50.4778 46.52 50.3541 46.5947C50.2305 46.6695 50.0882 46.7075 49.9437 46.7043V46.7043Z" fill="currentColor" />
    <path d="M53.6057 44.4721L50.132 45.5464L49.0578 42.0769L48.042 44.0081L49.1163 47.4776L52.59 46.3991L53.6057 44.4721Z" fill="currentColor" />
    <path d="M26.7988 61.1172C22.1416 61.1119 17.5672 59.8845 13.5329 57.5578C9.49847 55.231 6.14527 51.8864 3.80824 47.858C1.63022 44.0876 0.414587 39.8388 0.268775 35.487C0.122963 31.1353 1.05147 26.8146 2.97222 22.9069C3.06044 22.7268 3.21652 22.5891 3.4062 22.5241C3.59588 22.459 3.80365 22.4719 3.98382 22.56C4.16419 22.649 4.30191 22.8059 4.36693 22.9962C4.43195 23.1866 4.41896 23.3949 4.33075 23.5757C2.52045 27.2604 1.64556 31.3343 1.78335 35.4373C1.92114 39.5404 3.06737 43.5464 5.1208 47.1014C7.32181 50.8984 10.4807 54.0514 14.2818 56.2454C18.083 58.4394 22.3932 59.5975 26.7821 59.6041C26.9827 59.6041 27.1752 59.6838 27.3171 59.8256C27.459 59.9675 27.5387 60.16 27.5387 60.3607C27.5377 60.5581 27.4599 60.7475 27.3218 60.8887C27.1838 61.0299 26.9962 61.1118 26.7988 61.1172V61.1172Z" fill="currentColor" />
    <path d="M0 24.7169L3.46949 23.6385L4.54377 27.1121L5.56372 25.1809L4.48525 21.7114L1.01577 22.7899L0 24.7169Z" fill="currentColor" />
    <path d="M45.6551 64.5535V63.5461C45.654 62.4566 45.2207 61.4121 44.4504 60.6418C43.68 59.8714 42.6355 59.4381 41.5461 59.437C40.4563 59.437 39.4111 59.8699 38.6405 60.6405C37.8699 61.4111 37.437 62.4563 37.437 63.5461V64.5535" fill="#010101" />
    <path d="M46.1063 64.5575H45.1993V63.5459C45.1982 62.5773 44.8129 61.6487 44.128 60.9638C43.4431 60.2788 42.5145 59.8936 41.5459 59.8925C40.5765 59.8925 39.6468 60.2772 38.961 60.9623C38.2752 61.6473 37.8894 62.5766 37.8883 63.5459V64.5575H36.9854V63.5459C36.9865 62.3356 37.4677 61.1752 38.3235 60.3194C39.1793 59.4636 40.3398 58.9823 41.55 58.9812C42.7596 58.9834 43.9189 59.4652 44.7738 60.3209C45.6287 61.1766 46.1094 62.3363 46.1105 63.5459L46.1063 64.5575Z" fill="#010101" />
    <path d="M41.5461 60.1894C43.2314 60.1894 44.5976 58.8232 44.5976 57.1379C44.5976 55.4526 43.2314 54.0864 41.5461 54.0864C39.8608 54.0864 38.4946 55.4526 38.4946 57.1379C38.4946 58.8232 39.8608 60.1894 41.5461 60.1894Z" fill="white" />
    <path d="M41.5459 60.6406C40.6172 60.6395 39.7269 60.2701 39.0702 59.6134C38.4135 58.9568 38.0441 58.0664 38.043 57.1377C38.043 56.2083 38.4119 55.3169 39.0687 54.6593C39.7255 54.0017 40.6165 53.6317 41.5459 53.6306C42.4757 53.6317 43.3671 54.0016 44.0246 54.659C44.682 55.3165 45.0519 56.2079 45.053 57.1377C45.0508 58.0668 44.6804 58.9571 44.0231 59.6137C43.3657 60.2703 42.475 60.6395 41.5459 60.6406V60.6406ZM41.5459 54.5419C40.8578 54.543 40.1981 54.8168 39.7115 55.3034C39.225 55.79 38.9511 56.4496 38.95 57.1377C38.9511 57.8258 39.225 58.4855 39.7115 58.972C40.1981 59.4586 40.8578 59.7325 41.5459 59.7336C42.234 59.7325 42.8936 59.4586 43.3802 58.972C43.8668 58.4855 44.1406 57.8258 44.1417 57.1377C44.1406 56.4496 43.8668 55.79 43.3802 55.3034C42.8936 54.8168 42.234 54.543 41.5459 54.5419V54.5419Z" fill="#010101" />
    <path d="M32.2326 48.6438H30.4435C30.3492 48.6438 30.2589 48.6063 30.1922 48.5397C30.1256 48.4731 30.0882 48.3827 30.0882 48.2885V45.283H28.5708V48.2885C28.5719 48.7848 28.7696 49.2605 29.1205 49.6114C29.4715 49.9624 29.9472 50.16 30.4435 50.1611H32.2326C32.434 50.16 32.6269 50.0795 32.7693 49.9371C32.9118 49.7947 32.9922 49.6018 32.9933 49.4004C32.9922 49.1993 32.9116 49.0069 32.7691 48.8651C32.6265 48.7233 32.4336 48.6438 32.2326 48.6438Z" fill="#010101" />
    <path d="M22.9154 45.283V48.2885C22.9154 48.3827 22.878 48.4731 22.8114 48.5397C22.7447 48.6063 22.6543 48.6438 22.5601 48.6438H20.771C20.57 48.6438 20.3771 48.7233 20.2346 48.8651C20.092 49.0069 20.0114 49.1993 20.0103 49.4004C20.0114 49.6018 20.0919 49.7947 20.2343 49.9371C20.3767 50.0795 20.5696 50.16 20.771 50.1611H22.5601C23.0564 50.16 23.5321 49.9624 23.8831 49.6114C24.234 49.2605 24.4317 48.7848 24.4328 48.2885V45.283H22.9154Z" fill="#010101" />
    <path d="M39.176 45.9393H13.8279C12.9986 45.9371 12.2039 45.6064 11.6179 45.0196C11.0319 44.4328 10.7023 43.6377 10.7012 42.8084V27.2041C10.7023 26.3752 11.032 25.5806 11.6182 24.9944C12.2043 24.4083 12.999 24.0785 13.8279 24.0774H39.176C40.005 24.0785 40.7996 24.4083 41.3857 24.9944C41.9719 25.5806 42.3016 26.3752 42.3027 27.2041V42.8084C42.3016 43.6377 41.972 44.4328 41.386 45.0196C40.8 45.6064 40.0053 45.9371 39.176 45.9393ZM13.8279 25.5906C13.4007 25.5917 12.9914 25.7622 12.6897 26.0647C12.388 26.3671 12.2186 26.7769 12.2186 27.2041V42.8084C12.2186 43.2357 12.388 43.6454 12.6897 43.9479C12.9914 44.2504 13.4007 44.4209 13.8279 44.422H39.176C39.6032 44.4209 40.0126 44.2504 40.3143 43.9479C40.616 43.6454 40.7854 43.2357 40.7854 42.8084V27.2041C40.7854 26.7769 40.616 26.3671 40.3143 26.0647C40.0126 25.7622 39.6032 25.5917 39.176 25.5906H13.8279Z" fill="#010101" />
    <path d="M14.5343 26.8196C14.3125 26.8196 14.0999 26.9077 13.9431 27.0644C13.7863 27.2212 13.6982 27.4339 13.6982 27.6556V39.8114H39.2805V27.6556C39.2805 27.4339 39.1924 27.2212 39.0356 27.0644C38.8788 26.9077 38.6662 26.8196 38.4444 26.8196H14.5343Z" fill="#EDA600" />
    <path d="M41.5463 42.4908H11.4495C11.2488 42.4908 11.0564 42.411 10.9145 42.2692C10.7726 42.1273 10.6929 41.9348 10.6929 41.7342C10.6923 41.6345 10.7115 41.5356 10.7493 41.4433C10.787 41.3511 10.8427 41.2672 10.913 41.1965C10.9833 41.1258 11.0669 41.0697 11.159 41.0314C11.251 40.9931 11.3498 40.9734 11.4495 40.9734H41.5463C41.646 40.9734 41.7447 40.9931 41.8368 41.0314C41.9288 41.0697 42.0124 41.1258 42.0827 41.1965C42.153 41.2672 42.2087 41.3511 42.2465 41.4433C42.2842 41.5356 42.3034 41.6345 42.3028 41.7342C42.3028 41.9348 42.2231 42.1273 42.0812 42.2692C41.9393 42.411 41.7469 42.4908 41.5463 42.4908Z" fill="#010101" />
    <path d="M31.3257 50.1612H21.678C21.477 50.1601 21.2845 50.0794 21.1427 49.9369C21.001 49.7943 20.9214 49.6014 20.9214 49.4004C20.9214 49.1997 21.0011 49.0073 21.143 48.8654C21.2849 48.7235 21.4773 48.6438 21.678 48.6438H31.3257C31.5267 48.6438 31.7196 48.7234 31.8622 48.8651C32.0047 49.0069 32.0854 49.1994 32.0865 49.4004C32.0854 49.6018 32.0049 49.7947 31.8624 49.9371C31.72 50.0796 31.5271 50.1601 31.3257 50.1612V50.1612Z" fill="#010101" />
    <path d="M28.2992 30.1596C28.1582 30.0192 27.9673 29.9404 27.7684 29.9404C27.5694 29.9404 27.3785 30.0192 27.2375 30.1596L22.5014 34.8957C22.361 35.0367 22.2822 35.2276 22.2822 35.4266C22.2822 35.6256 22.361 35.8164 22.5014 35.9574C22.5704 36.0283 22.6529 36.0845 22.7442 36.1226C22.8354 36.1607 22.9335 36.1799 23.0323 36.179C23.1318 36.1797 23.2304 36.1605 23.3223 36.1224C23.4142 36.0843 23.4975 36.0283 23.5674 35.9574L28.2992 31.2256C28.3699 31.1559 28.426 31.073 28.4643 30.9815C28.5026 30.89 28.5223 30.7918 28.5223 30.6926C28.5223 30.5934 28.5026 30.4952 28.4643 30.4037C28.426 30.3122 28.3699 30.2293 28.2992 30.1596Z" fill="white" />
    <path d="M31.1084 32.9059C31.0388 32.8353 30.9559 32.7792 30.8644 32.7409C30.7729 32.7026 30.6747 32.6829 30.5755 32.6829C30.4763 32.6829 30.3781 32.7026 30.2866 32.7409C30.1951 32.7792 30.1122 32.8353 30.0425 32.9059L28.1573 34.7911C28.0868 34.8604 28.0308 34.943 27.9925 35.0342C27.9543 35.1253 27.9346 35.2232 27.9346 35.322C27.9346 35.4209 27.9543 35.5187 27.9925 35.6099C28.0308 35.701 28.0868 35.7836 28.1573 35.8529C28.2262 35.9238 28.3088 35.98 28.4 36.018C28.4913 36.0561 28.5893 36.0753 28.6882 36.0744C28.7871 36.0753 28.885 36.0561 28.9763 36.018C29.0675 35.98 29.1501 35.9238 29.2191 35.8529L31.1084 33.9677C31.2488 33.8267 31.3277 33.6358 31.3277 33.4368C31.3277 33.2378 31.2488 33.0469 31.1084 32.9059Z" fill="white" />
    <path d="M235.181 15.9347H234.186C234.115 15.9347 234.073 15.9054 234.069 15.8385V15.245C234.068 15.2294 234.07 15.2138 234.075 15.1992C234.081 15.1846 234.09 15.1714 234.101 15.1606C234.112 15.1497 234.126 15.1416 234.14 15.1367C234.155 15.1317 234.171 15.1302 234.186 15.1321H236.97C237.033 15.1321 237.066 15.1697 237.066 15.245V15.8385C237.069 15.8517 237.068 15.8651 237.063 15.8778C237.059 15.8904 237.052 15.9019 237.043 15.9113C237.034 15.9207 237.022 15.9277 237.009 15.9318C236.997 15.9359 236.983 15.9369 236.97 15.9347H235.959V18.7395C235.959 18.8022 235.921 18.8357 235.854 18.8357H235.298C235.223 18.8357 235.19 18.8022 235.19 18.7395L235.181 15.9347ZM239.721 18.0247H239.244C239.186 18.0247 239.136 17.9829 239.081 17.8952L238.404 16.5366V18.7395C238.404 18.8022 238.375 18.8357 238.32 18.8357H237.752C237.677 18.8357 237.639 18.8022 237.635 18.7395V15.2324C237.635 15.1572 237.673 15.1196 237.752 15.1196H238.458C238.504 15.1196 238.554 15.1572 238.605 15.2324L239.487 16.9922L240.364 15.2324C240.412 15.1572 240.458 15.1196 240.502 15.1196H241.217C241.233 15.1175 241.248 15.119 241.263 15.1239C241.277 15.1289 241.291 15.1371 241.302 15.148C241.313 15.159 241.321 15.1723 241.326 15.1869C241.331 15.2015 241.332 15.2171 241.33 15.2324V18.7395C241.33 18.8022 241.292 18.8357 241.217 18.8357H240.649C240.594 18.8357 240.565 18.8022 240.561 18.7395V16.5283L239.88 17.8868C239.829 17.9871 239.775 18.0289 239.721 18.0289V18.0247Z" fill="#EDA600" />
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="241.33" height="70.8946" fill="white" />
    </clipPath>
  </defs>
</svg>


export const Welcome: FC<WelcomeProps> = ({ children, loading = false, error }) => {

  return <div className="w-screen h-screen grid grid-cols-1 grid-rows-4 sm:grid-cols-3 sm:grid-rows-1">
    <div className="row-span-3 relative sm:row-span-1 sm:col-span-2 flex flex-col justify-start sm:justify-center items-center bg-white">
      <ContentLoader className="bg-white" loading={loading}>
        <div className="w-full flex flex-col items-center overflow-auto p-4 sm:p-10">
          <div className="w-64 h-20 hidden sm:block text-primary-500">
            {TyfyLogo}
          </div>
          {children}
        </div>
      </ContentLoader>
    </div>
    <div
      className="row-start-1 sm:row-start-auto flex flex-col justify-end items-center p-8 bg-primary-400 sm:bg-secondary-400 bg-cover bg-blend-soft-light"
      style={{ backgroundImage: `url("/assets/welcome-image.jpg")` }}
    >
      <span className="w-64 block sm:hidden text-white">
        {TyfyLogo}
      </span>
    </div>
  </div>
};
