import i18n from "../translations/Moderators";

const newline = "%0D%0A";

export const addLineToBody = (body: string, line: string) => {
    if (body.length > 0) {
        //Force a newline in the email.
        body += newline;
    }

    body += line;

    return body;
}

export const addHeadingToBody = (body: string, heading: string) => {
    if (body.length > 0) {
        body += newline;
        body += newline;
    }

    body += i18n.emailReport.divider;

    body += newline;
    body += heading;

    body += newline;
    body += i18n.emailReport.divider;

    return body;
}
