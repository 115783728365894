import { FC } from 'react';
import { InputProps, withField } from '.';
import { StarRating } from '../StarRating';

interface StarInputProps extends InputProps<number> {}

export const StarInput: FC<StarInputProps> = ({ className, onChange, value }) => {
  return <StarRating value={value} onRatingChange={onChange} className={className}/>
};

export const StarField = withField(StarInput);
