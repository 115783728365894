import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useAuthenticationService } from '../../authentication/authentication-service';
import { PostSummary } from '../../forum/PostSummary';
import { ForumPost, useGetModeratorHomeFeedQuery, UsersPermissionsUser } from '../../services/graphql';
import { Card } from '../../shared/Card';
import { ContentLoader } from '../../shared/ContentLoader';
import { ProfileIcon } from '../../shared/ProfileIcon';
import i18n from "../../translations/Home";
import { ProfileReviewStars, ReviewType } from '../../user/profilePage/ProfileReviewStars';

interface ModeratorHomeFeedProps {
  groupId: string
}

export const ModeratorHomeFeed: FC<ModeratorHomeFeedProps> = ({ groupId }) => {
  const userFeedResponse = useGetModeratorHomeFeedQuery({
    variables: {
      groupId: groupId
    }
  });

  const user = useAuthenticationService(state => state.user);

  const reviews = userFeedResponse.data?.reviews;
  const forumPosts = userFeedResponse.data?.forumPosts;

  return <ContentLoader loading={userFeedResponse.loading} error={userFeedResponse.error}>
    <div className="text-xl text-primary-500 font-bold mt-5 mb-2">
      {i18n.moderator.latestReviews}
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
      {reviews?.map(review => <Card className="p-5 gap-5 flex flex-col sm:flex-row items-center" key={review.id}>
        <Link className="flex flex-col items-center" to={`/profile/${review.about!.id}`}>
          <ProfileIcon className="h-16 w-16" user={review.about as UsersPermissionsUser} />
          <div className="font-bold text-center text-primary-500">
            {`${review.about?.firstName} ${review.about?.lastName}`}
          </div>
        </Link>
        <div className="flex-1 flex flex-col gap-5 items-center">
          <div className="flex flex-wrap justify-center gap-2">
            {review.speedRating ? <ProfileReviewStars type={ReviewType.Speed} stars={review.speedRating} /> : null}
            {review.knowledgeRating ? <ProfileReviewStars type={ReviewType.Knowledge} stars={review.knowledgeRating} /> : null}
            {review.helpfulRating ? <ProfileReviewStars type={ReviewType.Helpful} stars={review.helpfulRating} /> : null}
          </div>
          <div className="italic">
            {`"${review.text}" - ${review.by?.firstName} ${review.about?.lastName}`}
          </div>
        </div>
      </Card>)}
    </div>
    <div className="text-xl text-primary-500 font-bold mt-5 mb-2">
      {i18n.moderator.latestPosts}
    </div>
    { user?.organisation.hideForum ? null : <div className="grid lg:grid-cols-2 gap-5">
      {forumPosts?.map((post, index) => <PostSummary post={post as ForumPost} key={index} />)}
    </div> }
  </ContentLoader>
};
