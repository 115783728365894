import { FC } from 'react';
import { Report } from '../services/graphql';
import i18n from "../translations/Moderators";
import { ReportProblemSummary } from './ReportProblemSummary';

interface ReportListItemProps {
    report: Report
    onClick: () => void,
    selected?: boolean
}

export const ReportListItem: FC<ReportListItemProps> = ({ report, onClick, selected }) => {
    return <button onClick={(e) => {
        e.stopPropagation();
        onClick();
    }}>
        <div className="flex flex-col items-start p-2 text-left">
            {report.reportType && <ReportProblemSummary report={report} />}
            <div className="w-full flex justify-between">
                <div className="flex flex-col items-start text-sm">
                    <span className="font-bold">{`${report.reportee?.firstName} ${report.reportee?.lastName}`}</span>
                    <span>{i18n.roles.mentor}</span>
                </div>
                <div className="flex flex-col items-end text-sm">
                    <span className="font-bold">{`${report.reporter?.firstName} ${report.reporter?.lastName}`}</span>
                    <span>{i18n.roles.mentee}</span>
                </div>
            </div>
        </div>

    </button>
};
