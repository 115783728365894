import { FC } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import Skeleton from 'react-loading-skeleton';
import { Link, useHistory } from 'react-router-dom';
import { UsersPermissionsUser } from '../services/graphql';
import { ProfileIcon } from '../shared/ProfileIcon';
import i18n from "../translations/Moderators";

interface ReportHeaderProps {
    mentor: UsersPermissionsUser,
    mentee: UsersPermissionsUser
}

export const ReportHeader: FC<ReportHeaderProps> = ({mentor, mentee}) => {
    const history = useHistory();
    
    return <div className="bg-white flex w-full justify-between relative items-center border-b border-gray-100">
        <div className="w-full mx-auto max-w-3xl px-4 py-3 flex items-center justify-between relative">
            <div className="absolute">
                <button onClick={() => history.push('/reports')} className="block sm:hidden p-2 cursor-pointer rounded hover:bg-gray-50">
                    <FiChevronLeft className="w-7 h-7" />
                </button>
            </div>
            {mentor ? <Link to={`/profile/${mentor?.id}`} className="flex text-primary-600 gap-2 items-center ml-10 sm:ml-0">
                <ProfileIcon user={mentor} className="w-11 h-11" />
                <div className="flex flex-col items-start">
                    {mentor ? <span className="text-sm font-bold">{mentor.firstName} {mentor.lastName}</span> : <Skeleton width={80} />}
                    <span className="text-sm">{i18n.roles.mentor}</span>
                </div>
            </Link> : <div />}
            {mentee ? <Link to={`/profile/${mentee?.id}`} className="flex text-primary-600 gap-2 items-center mr-10 sm:mr-0">
                <div className="flex flex-col items-start">
                    {mentee ? <span className="text-sm font-bold">{mentee.firstName} {mentee.lastName}</span> : <Skeleton width={80} />}
                    <span className="text-sm">{i18n.roles.mentee}</span>
                </div>
                <ProfileIcon user={mentee} className="w-11 h-11" />
            </Link> : <div />}
        </div>
    </div>
};