import { FC } from 'react';
import { UsersPermissionsUser } from '../services/graphql';
import { ProfileIcon } from '../shared/ProfileIcon';
import { StarRating } from '../shared/StarRating';
import i18n from '../translations/Mentors';
import { Button } from '../shared/Button';
import { useHistory } from 'react-router-dom';
import { useStartConversation } from '../conversations/useStartConversation';
import { Card } from '../shared/Card';
import { useReviewStats } from '../user/useReviewAverage';

interface MentorCardProps {
  user: UsersPermissionsUser,
}

export const MentorCard: FC<MentorCardProps> = (props: MentorCardProps) => {
  const { startChat, loading: startChatLoading } = useStartConversation();
  const history = useHistory();

  const viewProfile = () => {
    history.push(`/profile/${props.user.id}`)
  }

  const stats = useReviewStats(props.user);

  const courseList = props.user.completedActivities?.map(activity => activity.name as string);

  return <Card className="flex flex-col items-center p-8 gap-2 h-142 mx-5">
    <ProfileIcon user={props.user} className="w-20 h-20" />
    <StarRating value={stats.average} className="pointer-events-none p-2" />
    <div className="flex flex-col flex-1 items-center gap-2">
      {courseList && courseList.length > 0 && <>
        <span className="font-bold">{i18n.mentorCard.hasStudied(props.user.firstName!)}</span>
        {courseList.slice(0, 4).map((course, index) => <span className="text-center" key={index}>
          {course}
        </span>)}
      </>}
      {stats.numberHelped > 0 && <div className="font-bold text-primary-400 text-center">
        {courseList?.length !== 0 ? i18n.mentorCard.studentsHelped(stats.numberHelped) : i18n.mentorCard.studentsHelpedNoCourseList(stats.numberHelped)}
      </div>}
    </div>
    <Button primary onClick={viewProfile}>
      {i18n.mentorCard.viewProfile}
    </Button>
    <Button secondary onClick={() => startChat(props.user.id)} loading={startChatLoading}>
      {i18n.mentorCard.askAQuestion}
    </Button>
  </Card>
};
