import LocalizedStrings from 'react-localization';
import { UsersPermissionsUser } from '../services/graphql';

export default new LocalizedStrings({
  en: {
    helmets: {
      inbox: "Inbox",
      reports: "Reports"
    },
    inbox: {
      emptyState: {
        title: 'Nothing to see here!',
        description: 'Select a conversation on the left.'
      }
    },
    message: {
      placeholder: 'Type a message'
    },
    report: {
      button: {
        label: "Report"
      },
      reportFromReview: 'Report an issue with {0}',
    },
    conversation: {
      end: 'End chat',
      profile: 'Profile',
      endConfirmation: {
        title: 'Are you sure you want to end the conversation?',
        menteeDescription: 'You will have to start a new conversation if you want to talk to {0} again.',
        mentorDescription: "You won't be able to send further messages. {0} will be prompted to provide feedback on your conversation."
      },
      start: 'Start new conversation',
      noMessages: 'No messages sent',
      endedConversation: 'Conversation ended',
      loadMore: "Load more",
      endedTextBox: "This conversation has ended."
    },
    review: {
      reviewTitle: 'How did {0} do?',
      speedRating: `Speed of {0}'s response`,
      knowledgeRating: `{0}'s knowledge of the topic`,
      helpfulRating: 'How helpful was {0}?',
      nextButton: 'Next',
      summaryTitle: 'Review',
      speed: 'Speed',
      knowledge: 'Knowledge',
      helpfulness: 'Helpfulness',
      privacyWarning: "Remember: Conversations are private, but moderators from you university will be able to see your messages if an issue gets reported.",
      badReviewWarning: (firstName: string) => `If you give a negative review, it will be reviewed by a moderator before being sent to ${firstName}.`,
      badReviewWarning2: (firstName: string) => `Remember- any comments here may be seen by ${firstName}, or passed on by a moderator.`,
      badReviewDisclaimer: (firstName: string) => `This review has been passed on to a moderator. ${firstName} won't be able to see it, but a moderator may contact them to discuss it.`,
      save: "Save"
    },
    attachment: {
      conversation: {
        names: (mentor: UsersPermissionsUser, mentee: UsersPermissionsUser) => `${mentor.firstName} ${mentor.lastName} & ${mentee.firstName} ${mentee.lastName}`,
        regarding: "Regarding this conversation:"
      }
    }
  }
})
