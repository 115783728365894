import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { merge } from '../../helpers/ui';
import { Review, UsersPermissionsUser } from '../../services/graphql';
import { Card } from '../../shared/Card';
import i18n from "../../translations/User";
import { ProfileReview } from './ProfileReview';

interface ProfileReviewsCardProps extends HTMLAttributes<HTMLDivElement> {
  reviews: Review[],
  user: UsersPermissionsUser
}

export const ProfileReviewsCard: FC<ProfileReviewsCardProps> = ({ reviews, user, className }) => {
  return <Card className={merge(className, "p-5 text-center")}>
    <span className="font-bold w-full">{i18n.profile.reviews.title}</span>
    {reviews?.length === 0 ? <div>{i18n.formatString(i18n.profile.reviews.noReviews, user.firstName ?? '')}</div> : <div>
      {reviews?.map((review, index) => <ProfileReview key={review.id} secondary={index % 2 === 1} review={review} />)}
    </div>}
  </Card>
};
