import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { log } from '../../helpers/log';
import { Button } from '../../shared/Button';
import { forgotPassword } from '../authentication-service';
import { TextField } from '../../shared/inputs/TextInput';
import { Form } from '../../shared/Form';
import { useState } from 'react';
import { State } from '../../helpers/state';

import i18n from '../../translations/Authentication';
import { Welcome } from './Welcome';
import { Title } from '../../shared/Title';
import { PageHelmet } from '../../shared/Helmet';

interface ForgottenPasswordFormFields {
  email: string
}

export const ForgottenPassword: FC = () => {
  const [state, setState] = useState<State>(State.Idle)
  const [errors, setErrors] = useState<any>()
  const history = useHistory();

  const methods = useForm<ForgottenPasswordFormFields>()

  const submit = async (data: ForgottenPasswordFormFields) => {
    setState(State.Loading);

    try {
      await forgotPassword(
        data.email
      )

      history.push('/request-sent');

    } catch (e) {
      log('Sending forgotten password was unsuccessful.', e);
      setState(State.Failure);
      setErrors(e);
    }
  }

  return <Welcome>
    <div className="flex flex-col w-full max-w-md p-8">
      <PageHelmet pageTitle={i18n.helmets.forgottenPassword} />
      <Title size="2xl" className="mb-4">{i18n.forgotten.title}</Title>
      <Form methods={methods} onSubmit={submit}>
        <TextField name="email" title={i18n.emailAddress.label} placeholder={i18n.emailAddress.placeholder} requiredMessage={i18n.emailAddress.requiredMessage} />
        {errors ? <div className="text-red-500">{errors.message}</div> : null}
        <Button loading={state === State.Loading} className="mt-2 w-full" primary submit>{i18n.forgotten.confirm}</Button>
        <Button className="mt-2 w-full" onClick={() => history.goBack()}>{i18n.forgotten.cancel}</Button>
      </Form>
    </div>
  </Welcome>;
};
