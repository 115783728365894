import { FC, useState } from 'react';
import { useGetMentorsQuery, UsersPermissionsUser } from '../services/graphql';
import { ContentLoader } from '../shared/ContentLoader';
import { useHistory, useParams } from 'react-router-dom';
import { PageContainer } from '../shared/PageContainer';
import { Card } from '../shared/Card';
import { TextInput } from '../shared/inputs/TextInput';
import i18n from "../translations/Mentors";
import { Button } from '../shared/Button';
import { MentorSearchTable } from './table/MentorSearchTable';
import { MentorSearchList } from './table/MentorSearchList';
import { Pager } from '../shared/Pager';
import { MentorSearchHeader } from './MentorSearchHeader';
import { useEffect } from 'react';
import { PageHelmet } from '../shared/Helmet';

interface MentorSearchParams {
  searchTerm: string
}

const numberPerPage = 10;

export const MentorSearch: FC = () => {
  const { searchTerm } = useParams<MentorSearchParams>();
  const [page, setPage] = useState<number>(1);
  const [searchInput, setSearchInput] = useState<string>(searchTerm);
  const history = useHistory();

  const onSearch = () => {
    history.push(`/mentors/${searchInput}`);
  }

  const onPageChange = (forward: boolean) => {
    setPage(forward ? page + 1 : page - 1);
  }

  const [mentorList, setMentorList] = useState<UsersPermissionsUser[] | undefined>();

  const { loading, error, data } = useGetMentorsQuery({
    variables: {
      where: {}
    }
  });

  useEffect(() => {
    if (!data?.mentors) return;

    const mentorList = data.mentors.filter((mentor) => {
      if (!searchTerm) {
        return true;
      }

      const searchTerms = searchTerm.split(" ").map(term => term.toLowerCase());
      return searchTerms.some(term => {
        if (!term) return false;

        if (mentor.firstName?.toLowerCase().includes(term) ||
          mentor.lastName?.toLowerCase().includes(term) ||
          mentor.bio?.toLowerCase().includes(term) ||
          mentor.group?.name.toLowerCase().includes(term)) {
          return true;
        }

        return mentor.completedActivities?.some(activity => activity.name?.toLowerCase().includes(term));
      });
    })
    setMentorList(mentorList as UsersPermissionsUser[]);
  }, [data, searchTerm])

  const mentorsToShow = mentorList?.slice((page - 1) * numberPerPage, page * numberPerPage);
  const totalMentors = mentorList?.length || 0;
  const totalPages = Math.ceil(totalMentors / numberPerPage);

  return <ContentLoader loading={loading} error={error}>
    <PageHelmet pageTitle={i18n.helmets.mentors} />
    <MentorSearchHeader />
    <PageContainer>
      <Card className="flex flex-col">
        <form onSubmit={onSearch} className="flex p-5 gap-5 w-full">
          <TextInput
            name="searchInput"
            value={searchInput}
            className="flex-1"
            onChange={setSearchInput}
            placeholder={i18n.search.searchButton.placeHolder}
          />
          <Button submit primary>
            {i18n.search.searchButton.label}
          </Button>
        </form>
        {totalMentors === 0 ? <div className="p-5 text-center w-full">
          {i18n.search.noResults}
        </div> : <>
          <MentorSearchTable
            mentors={mentorsToShow!}
            className="hidden md:block"
          />
          <MentorSearchList
            mentors={mentorsToShow!}
            className="md:hidden"
          />
          <Pager
            currentPage={page}
            totalPages={totalPages}
            onPage={onPageChange}
          />
        </>}
      </Card>
    </PageContainer>
  </ContentLoader>
};
