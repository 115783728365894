import { FC } from 'react';
import { Enum_Report_Reportsubject, Enum_Report_Reporttype, Report } from '../services/graphql';
import i18n from "../translations/Moderators";

interface ReportProblemSummaryProps {
  report: Report
}

export const getProblemSummary = (reportType: Enum_Report_Reporttype) => {
  switch (reportType) {
    case Enum_Report_Reporttype.Inaccurate:
      return i18n.reasons.inaccurate;
    case Enum_Report_Reporttype.Inappropriate:
      return i18n.reasons.inappropriate;
    case Enum_Report_Reporttype.PoorReview:
      return i18n.reasons.poorReview;
    case Enum_Report_Reporttype.Worried:
      return i18n.reasons.worried;  
    }
}

export const getProblemSubject = (reportSubject: Enum_Report_Reportsubject) => {
  switch (reportSubject) {
    case Enum_Report_Reportsubject.Conversation:
      return i18n.subjects.conversation;
    case Enum_Report_Reportsubject.Comment:
    case Enum_Report_Reportsubject.Post:
      return i18n.subjects.forum;  
    }
}

export const ReportProblemSummary: FC<ReportProblemSummaryProps> = ({ report }) => {
  return <div className="text-primary-500 flex justify-between w-full">
    <span className="font-bold">{getProblemSummary(report.reportType!)}</span>
    <span>{getProblemSubject(report.reportSubject!)}</span>
  </div>
};
