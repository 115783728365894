import { HTMLAttributes, useEffect } from "react";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useAuthenticationService } from "../authentication/authentication-service";
import { merge } from "../helpers/ui";
import { Group, Scalars, useCreatePostMutation } from "../services/graphql";
import { Button } from "../shared/Button";
import { Card } from "../shared/Card";
import { ChitList } from "../shared/ChitList";
import { Form } from "../shared/Form";
import { TextAreaField } from "../shared/inputs/TextAreaInput";
import { TextField } from "../shared/inputs/TextInput";
import { Label } from "../shared/Label";
import SearchBar from "../shared/search/AutocompleteSearchBar";
import i18n from "../translations/Forum";

interface ForumCreatePostProps extends HTMLAttributes<HTMLDivElement> {
    group: Group
}

interface ForumCreatePostFormFields {
    title: string,
    text: string,
    linkedActivities: string[]
}

export const ForumCreatePost: FC<ForumCreatePostProps> = ({ group, className }) => {
    const history = useHistory();
    const methods = useForm<ForumCreatePostFormFields>();
    const user = useAuthenticationService(state => state.user);

    useEffect(() => {
        methods.register('linkedActivities');
    }, [methods])

    const linkedActivities: Scalars['ID'][] = methods.watch('linkedActivities') ?? [];
    const possibleActivities = group.activities;

    const activityNames = linkedActivities.map(id => {
        const activity = possibleActivities?.find(item => item.id === id);

        return activity?.name as string;
    })

    const [createForumPost, createForumPostStatus] = useCreatePostMutation();

    const onSubmit = async () => {
        const result = await createForumPost({
            variables: {
                input: {
                    data: {
                        title: methods.getValues().title,
                        text: methods.getValues().text,
                        postedBy: user?.id as string,
                        activities: methods.getValues().linkedActivities,
                        group: group.id
                    }
                }
            }
        });

        if (result.data?.createForumPost?.forumPost) {
            history.push(`/forum/posts/${result.data?.createForumPost?.forumPost.id}`);
        }
    }

    return <Card className={merge(className, "flex flex-col items-center p-5")}>
        <Form className="w-full" methods={methods} onSubmit={onSubmit}>
            <TextField
                name="title"
                placeholder={i18n.forum.create.fields.title.placeholder}
                title={i18n.forum.create.fields.title.label}
                requiredMessage={i18n.forum.create.fields.title.required}
            />
            <TextAreaField
                name="text"
                placeholder={i18n.forum.create.fields.text.placeholder}
                title={i18n.forum.create.fields.text.label}
                requiredMessage={i18n.forum.create.fields.text.required}
            />
            <div>
                <Label>{i18n.forum.create.linkedModules}</Label>
                <SearchBar
                    className="mt-1 mb-2"
                    onSearchChange={(item) => {
                        methods.setValue('linkedActivities', [...linkedActivities, item.id])
                    }}
                    options={[{
                        id: 'Modules',
                        items: possibleActivities?.map(a => ({
                            id: a.id,
                            name: a.name!,
                            section: 'Modules'
                        })) ?? []
                    }]} />
                {activityNames && <ChitList chits={activityNames} />}
            </div>
            <div className="flex gap-2 justify-end mt-5">
                <Button submit primary loading={createForumPostStatus.loading}>{i18n.forum.create.fields.post.label}</Button>
                <Button onClick={() => history.goBack()}>{i18n.forum.create.fields.cancel.label}</Button>
            </div>
        </Form>
    </Card>
}
