import { ApplicationInsights, DistributedTracingModes } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import { ApolloLink } from '@apollo/client';
import { v4 } from 'uuid';

export const history = createBrowserHistory();
export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
  config: {
      customHeaders: [{
        header: 'environment',
        value: process.env.NODE_ENV
      }],
      distributedTracingMode: DistributedTracingModes.AI,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      disableFetchTracking: false,
      instrumentationKey: window.environment.appInstrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history }
      }
  }
});

export const dependencyReportingLink = new ApolloLink((operation, forward) => {
  operation.setContext({ start: new Date() });

  return forward(operation).map(response => {
    const context = operation.getContext();
    appInsights.trackDependencyData({
      id: v4(),
      responseCode: context.response.status,
      name: operation.operationName,
      startTime: context.start,
      duration: new Date().getTime() - context.start.getTime(),
      success: context.response.ok,
      type: 'GraphQL',
      target: context.response.url
    })
    return response;
  });
});

export const log = (message: string, ...args: any[]) => {
  console.log(message, ...args);
  appInsights.trackTrace({ message }, { args })
}
