import { FC, HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
}

export const PageContainer: FC<PageContainerProps> = ({ children, className, ...props }) => {
  return  <div {...props} className={merge('max-w-7xl w-full mx-auto px-4 py-4 sm:py-8 sm:px-4 lg:px-8', className)}>
    {children}
  </div>
};
