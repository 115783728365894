import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { merge } from '../../helpers/ui';
import { StarRating } from '../../shared/StarRating';
import i18n from "../../translations/User";

interface ProfileReviewStarsProps extends HTMLAttributes<HTMLDivElement> {
  stars: number,
  type: ReviewType
}

export enum ReviewType {
  Helpful,
  Knowledge,
  Speed
}

export const ProfileReviewStars: FC<ProfileReviewStarsProps> = ({ stars, type, className }) => {
  let label: string;

  switch (type) {
    case ReviewType.Helpful:
      label = i18n.profile.reviews.helpfulness;
      break;
    case ReviewType.Speed:
      label = i18n.profile.reviews.speed;
      break;
    case ReviewType.Knowledge:
      label = i18n.profile.reviews.knowledge;
      break;
  }
  
  return <div className={merge("my-1 mx-5 flex flex-col items-center", className)}>
    <span>{label}</span>
    <StarRating buttonSize={4} value={stars} />
  </div>;
};
