import { FC } from 'react'
import { useForm } from 'react-hook-form';
import { Button } from '../../shared/Button'
import { Form } from '../../shared/Form';
import { Modal, ModalProps } from '../../shared/Modal'
import { Title } from '../../shared/Title'
import { StarField } from '../../shared/inputs/StarInput'
import i18n from '../../translations/Conversation';
import { useState } from 'react';
import { TextAreaField } from '../../shared/inputs/TextAreaInput';
import { useReviewConversationMutation } from '../../services/graphql';

export interface ReviewConversationModalProps extends ModalProps {
  firstName: string
  id: string
}

export interface ReviewConversationFormFields {
  speedRating: number
  knowledgeRating: number
  helpfulRating: number
  text?: string
}

export const ReviewConversationModal: FC<ReviewConversationModalProps> = ({ open, onClose, firstName, id }) => {

  const methods = useForm<ReviewConversationFormFields>({
    shouldUnregister: false,
    defaultValues: {
      speedRating: 0,
      knowledgeRating: 0,
      helpfulRating: 0,
      text: ""
    }
  })
  const [page, setPage] = useState<'star' | 'text'>('star');

  const [reviewConversation, { loading }] = useReviewConversationMutation();

  const submitReview = async (data: ReviewConversationFormFields) => {

    await reviewConversation({ variables: { id, input: {
      data: {
        text: data.text,
        helpfulRating: data.helpfulRating,
        knowledgeRating: data.knowledgeRating,
        speedRating: data.speedRating
      }
    }}})
    onClose()
  }

  return <Modal open={open} onClose={onClose}>
    <Form methods={methods} onSubmit={submitReview} className="bg-white flex flex-col items-center justify-center px-4 pt-5 pb-4 sm:p-8">
      <Title tight size="2xl" className="w-full text-center text-primary-800 pb-4 mb-2 border-b border-gray-100">
        {i18n.formatString(i18n.review.reviewTitle, firstName)}
      </Title>
      {(() => {
        switch (page) {
          case 'star':
            return <>
              <div className="text-center">
                {i18n.review.badReviewWarning(firstName)}
              </div>
              <div className="my-4 space-y-4 text-center">
                <div>
                  <p className="text-lg font-semibold text-primary-800">{i18n.formatString(i18n.review.speedRating, firstName)}</p>
                  <StarField title="" name="speedRating" requiredMessage="Sorry, this rating is required." />
                </div>
                <div>
                  <p className="text-lg font-semibold text-primary-800">{i18n.formatString(i18n.review.knowledgeRating, firstName)}</p>
                  <StarField title="" name="knowledgeRating" requiredMessage="Sorry, this rating is required." />
                </div>
                <div>
                  <p className="text-lg font-semibold text-primary-800">{i18n.formatString(i18n.review.helpfulRating, firstName)}</p>
                  <StarField title="" name="helpfulRating" requiredMessage="Sorry, this rating is required." />
                </div>
              </div>
              <div className="w-full text-center mt-4 pt-4 border-t border-gray-100">
                <Button onClick={async () => {
                  const validated = await methods.trigger(['speedRating', 'helpfulRating', 'knowledgeRating']);
                  if (validated) setPage('text');
                }} className="mt-1" primary>{i18n.review.nextButton}</Button>
              </div>
            </>

          case 'text':
            return <>
              <div className="text-center">
                {i18n.review.badReviewWarning2(firstName)}
              </div>
              <TextAreaField className="w-full my-4" title="Testimonial" name="text" requiredMessage="You must provide a review." placeholder="Do you want to add anything about your conversation with Name?" />
              <div className="w-full text-center mt-4 pt-4 border-t border-gray-100">
                <Button loading={loading} submit className="mt-1" primary>{i18n.review.save}</Button>
              </div>
            </>
        }
      })()}
    </Form>
  </Modal>
}
