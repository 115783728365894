import { UsersPermissionsUser } from "../services/graphql"

//How many stars needed on a review before you're considered 'helpful'
const helpfulLimit = 3;

export const useReviewStats = (user: UsersPermissionsUser) => {
    if (!user.reviews || user.reviews.length === 0) {
        return {
            average: 0,
            numberHelped: 0
        };
    }

    let reviewTotal = 0;
    let positiveReviewCount = 0;
    let numberHelped = 0;

    user.reviews?.forEach(review => {
        if (!review.knowledgeRating || !review.speedRating || !review.helpfulRating) return;

        if ((review.helpfulRating + review.knowledgeRating + review.speedRating) <= 6) return;

        reviewTotal += review.knowledgeRating;
        reviewTotal += review.speedRating;
        reviewTotal += review.helpfulRating;
        positiveReviewCount++;

        if (review.helpfulRating && review.helpfulRating >= helpfulLimit ) {
            numberHelped++;
        }
    });

    return {
        average: reviewTotal / (3 * positiveReviewCount),
        numberHelped: numberHelped
    }
};
