import LocalizedStrings from 'react-localization';
import { UsersPermissionsUser } from '../services/graphql';

export default new LocalizedStrings({
  en: {
    helmets: {
      home: "Home"
    },
    header: {
      welcomeBack: (name: string) => `Welcome back, ${name}!`,
      welcome: "Welcome!",
      mentor: {
        label: "Mentor",
        spiel: `Mentoring helps you gain experience whilst you study. Using Tyfy, you can build up employability reviews from the people you help - and build your CV in your spare time.
        
        It's simple, too - you've already studied the modules, so you know how to help, and can answer all questions from your phone!
        
        There are three rules:`,
        rules: {
          rule1: {
            title: "If you can't help, refer",
            text: `If you don't know the answer to a question, try to refer them to the best source of help.
            
            Most importantly: if there are any worrying disclosures, report the conversation and let the university know.`
          },
          rule2: {
            title: "Don't cheat",
            text: `This means no selling your coursework, no inappropriate behaviour, and no false information.
            
            Your university can access your conversations, and they will find out.`
          },
          rule3: {
            title: "Always respond",
            text: `Leaving people hanging is rude, so if someone reaches out, make sure you get back to them!
            
            We recommend getting back to a mentee within 24 hours.`
          }
        }
      },
      mentee: {
        label: "Mentee",
        spiel: `Tyfy helps you settle in to campus life and get accustomed to your course by connecting you with mentors from your university. You can ask your mentors about anything from careers fairs and wellbeing services to how to borrow a book from the library.
        
        Search, ask, review- it's that simple!`
      }
    },
    page: {
      notifications: {
        title: "Notifications",
        conversations: {
          title: (count: number) => `${count} unread conversation${count === 1 ? "" : "s"}`,
          action: "Click here to reply now"
        },
        reviews: {
          title: (count: number) => `${count} new review${count === 1 ? "" : "s"}`,
          action: "Click here to view"
        },
        comments: {
          title: (count: number) => `${count} new comment${count === 1 ? "" : "s"}`,
          action: "Click here to see"
        }
      },
      mentors: {
        title: "Recommended Mentors"
      },
      posts: {
        title: "Top posts for you",
      }
    },
    moderator: {
      title: "Moderator",
      issuesAndMessages: "Issues and Messages",
      issues: {
        new: "New reported issues",
      },
      messages: {
        unread: "Unread direct messages",
      },
      stats: {
        title: "Usage Statistics",
        users: {
          number: "Number of users",
          course: "in your course"
        },
        conversations: {
          number: "New conversations",
          thisWeek: "started this week"
        },
        messages: {
          number: "Messages",
          thisWeek: "sent this week"
        },
        reviews: {
          number: "New reviews",
          thisWeek: "submitted this week"
        },
      },
      latestReviews: "Latest Reviews",
      latestPosts: "Latest Posts"
    },
    notifications: {
      messageFrom: (user: UsersPermissionsUser) => `New message from ${user.firstName} ${user.lastName}`
    }
  }
})
