import { useLayoutEffect, useState } from "react";

export function useWindowSize() {
    const [size, setSize] = useState<{width: number, height: number}>({width: 0, height: 0});
    useLayoutEffect(() => {
      function updateSize() {
        setSize({width: window.innerWidth, height: window.innerHeight});
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }