import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { merge } from '../../helpers/ui';
import { Button } from '../../shared/Button';
import i18n from "../../translations/User";

interface ProfileButtonsProps extends HTMLAttributes<HTMLDivElement> { 
  handleCancel: () => void,
  loading: boolean
}

export const ProfileButtons: FC<ProfileButtonsProps> = ({handleCancel, className, loading}) => {
  return <div className={merge("flex", className)}>
    <Button primary className="mx-2" submit loading={loading}>
      {i18n.editProfile.buttons.save.label}
    </Button>
    <Button onClick={handleCancel}>
      {i18n.editProfile.buttons.cancel.label}
    </Button>
  </div>
};
