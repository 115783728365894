import { FC } from 'react';
import { Helmet } from 'react-helmet';
import i18n from "../translations/Shared";

interface PageHelmetProps {
    pageTitle: string
}

export const PageHelmet: FC<PageHelmetProps> = ({ pageTitle }) => {
    return <Helmet>
        <title>{`${i18n.helmetPrefix} | ${pageTitle}`}</title>
    </Helmet>;
}