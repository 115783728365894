import { FC, useState } from 'react';
import { useGetMeQuery, UsersPermissionsUser, useUpdateUserMutation } from '../../services/graphql';
import { ContentLoader } from '../../shared/ContentLoader';
import { Card } from '../../shared/Card';
import { PageContainer } from '../../shared/PageContainer';
import { ProfileHeader } from '../ProfileHeader';
import i18n from "../../translations/User";
import { Form } from '../../shared/Form';
import { useForm } from 'react-hook-form';
import { ImageDetails } from '../../shared/inputs/ImageInput';
import { ProfileButtons } from './ProfileButtons';
import { PersonalDetailsSection } from './forms/PersonalDetailsSection';
import { OrganisationDetailsSection } from './forms/OrganisationDetailsSection';
import { ActivityConfigurationSection } from './forms/ActivityConfigurationSection';
import { PageHelmet } from '../../shared/Helmet';

interface ProfileFormFields {
  firstName: string,
  lastName: string,
  email: string,
  bio: string,
  profilePicture?: ImageDetails,
  organisation: {
    id: string
  },
  level: {
    id: string
  },
  group: {
    id: string
  },
  completedActivities: string[],
  currentActivities: string[]
}

interface EditProfilePageProps { }

export const EditProfilePage: FC<EditProfilePageProps> = () => {
  const [userId, setUserId] = useState<string | undefined>();
  const [userDetails, setUserDetails] = useState<UsersPermissionsUser | undefined>();

  const methods = useForm<ProfileFormFields>();

  const { loading, error, refetch } = useGetMeQuery(
    {
      onCompleted: data => {
        const details = data?.me?.user as UsersPermissionsUser;
        if (!details) return;

        setUserId(details.id as string);
        setUserDetails(details);
        methods.reset({
          ...details,
          completedActivities: details.completedActivities?.map(d => d.id),
          currentActivities: details.currentActivities?.map(d => d.id),
        })
      }
    }
  );

  const [updateUser, updateUserStatus] = useUpdateUserMutation();

  const updateProfile = async (data: ProfileFormFields) => {
    await updateUser(
      {
        variables: {
          input: {
            where: {
              id: userId!
            },
            data: {
              firstName: data.firstName,
              lastName: data.lastName,
              email: data.email,
              bio: data.bio,
              profilePicture: data.profilePicture?.id,
              organisation: data.organisation.id,
              level: data.level.id,
              group: data.group.id,
              completedActivities: data.completedActivities,
              currentActivities: data.currentActivities
            }
          }
        }
      }
    );

    refetch()
  }

  const handleCancel = () => {
    refetch()
  }
  return <ContentLoader loading={loading} error={error}>
    {userDetails && <>
      <PageHelmet pageTitle={i18n.helmets.editProfile}/>
      <ProfileHeader user={userDetails} myProfile />
      <PageContainer>
        <Card>
          <Form onSubmit={updateProfile} methods={methods} >
            <div className="flex justify-between items-center border-b p-6">
              <div className="w-full flex flex-col">
                <span className="text-xl font-bold">{i18n.editProfile.header.title}</span>
                <span>{i18n.editProfile.header.description}</span>
              </div>
              <ProfileButtons className="hidden sm:flex" handleCancel={handleCancel} loading={updateUserStatus.loading} />
            </div>
            <div className="p-4 sm:p-8 grid grid-cols-1 gap-3 sm:gap-5 sm:grid-cols-3">
              <PersonalDetailsSection />
              <OrganisationDetailsSection />
              <ActivityConfigurationSection />
              <ProfileButtons className="flex justify-center w-full sm:hidden" handleCancel={handleCancel} loading={updateUserStatus.loading} />
            </div>
          </Form>
        </Card>
      </PageContainer>
    </>}
  </ContentLoader >
};
