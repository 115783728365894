import { HTMLAttributes } from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { getDateAndTimeToDisplay } from "../helpers/date";
import { merge } from "../helpers/ui";
import { UsersPermissionsUser } from "../services/graphql";
import { ProfileIcon } from "../shared/ProfileIcon";

interface ForumPostDetailsProps extends HTMLAttributes<HTMLDivElement> {
    user: UsersPermissionsUser,
    postedAt: Date
}

export const ForumPostDetails: FC<ForumPostDetailsProps> = ({ user, postedAt, className }) => {
    return <div className={merge(className, "flex justify-between items-center")}>
        <Link className="flex gap-2 p-1" to={`/profile/${user.id}`}>
            <ProfileIcon user={user} className="w-6 h-6" />
            <div className="font-bold">{`${user.firstName} ${user.lastName}`}</div>
        </Link>
        <div>{getDateAndTimeToDisplay(postedAt)}</div>
    </div>;
}