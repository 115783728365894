import { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { log } from '../../helpers/log';
import { Button } from '../../shared/Button';
import { loginWithUsernameAndPassword, resendConfirmation, useAuthenticationService } from '../authentication-service';
import { TextField } from '../../shared/inputs/TextInput';
import { Form } from '../../shared/Form';
import { PasswordField } from '../../shared/inputs/PasswordInput';
import { useState } from 'react';
import { State } from '../../helpers/state';
import { StrapiError } from '../../shared/errors/StrapiError';

import i18n from '../../translations/Authentication';
import { Welcome } from './Welcome';
import { PageHelmet } from '../../shared/Helmet';

interface LoginProps { }

interface LoginFormFields {
  username: string
  password: string
}

export const Login: FC<LoginProps> = () => {
  const [authenticationState, setUserInfo] = useAuthenticationService(state => [state.state, state.setUserInfo]);
  const [state, setState] = useState<State>(State.Idle);
  const [isNotConfirmed, setConfirmedState] = useState<boolean>(false)
  const [errors, setErrors] = useState<any>()
  const location = useLocation<{ restore?: string }>();
  const history = useHistory();

  const methods = useForm<LoginFormFields>()

  useEffect(() => {
    (async () => {
      try {
        log(`User is ${authenticationState}. Logging in regardless of previous state.`)

      } catch (e) {
        log('Log in was unsuccessful.', e)
      }
    })()
  }, [authenticationState])

  const login = async (data: LoginFormFields) => {
    setState(State.Loading);

    try {
      log(`User is ${authenticationState}. Logging in regardless of previous state.`)

      const { jwt, user } = await loginWithUsernameAndPassword(
        data.username,
        data.password
      )

      setUserInfo(jwt, user);
      setState(State.Success);

      const target = (() => {
        if (location?.state?.restore) return location.state.restore;
        return '/'
      })()
      history.push(target);

    } catch (e: any) {
      log('Log in was unsuccessful.', e);

      if (e.id === 'Auth.form.error.confirmed') {
        await resendConfirmation(data.username);
        setConfirmedState(true);
        setState(State.Idle);
        return;
      }

      setState(State.Failure);
      setErrors(e);
    }
  }

  return <Welcome>
    <PageHelmet pageTitle={i18n.helmets.login}/>
    <div className="flex flex-col w-full max-w-md p-8">
      {/* <h1 className="text-3xl mb-2 font-bold tracking-tight">{i18n.login.welcomeMessage}</h1> */}
      <Form methods={methods} onSubmit={login}>
        <TextField name="username" title={i18n.emailAddress.label} placeholder={i18n.emailAddress.placeholder} requiredMessage={i18n.emailAddress.requiredMessage} />
        <PasswordField name="password" title={i18n.password.label} placeholder="********" requiredMessage={i18n.password.requiredMessage} />
        <Button loading={state === State.Loading} className="mt-2 w-full" primary submit>{i18n.login.loginButton}</Button>
      </Form>
      {errors ? <StrapiError errors={errors} /> : null}
      { isNotConfirmed ? <div className="flex flex-col bg-orange-100 border border-orange-300 rounded mt-4 text-orange-700 text-sm mb-2 py-3 px-4">
        {i18n.login.notConfirmed}
      </div> : null}
      <div className="mt-4 pt-4 border-t border-gray-200 flex flex-col gap-5">
        <Button className="w-full" onClick={() => {
          history.push('/register', { restore: location?.state?.restore });
        }}>{i18n.login.createAnAccountButton}</Button>
        <Button className="w-full" onClick={() => {
          history.push('/forgotten');
        }}>{i18n.login.forgottenPasswordButton}</Button>
      </div>
    </div>
  </Welcome>;
};
