import { FC } from 'react';
import { UsersPermissionsUser } from '../../services/graphql';
import { HTMLAttributes } from 'react';
import { MentorSearchCell } from './MentorSearchCell';
import { ChitList } from '../../shared/ChitList';
import { ProfileIcon } from '../../shared/ProfileIcon';
import { Link } from 'react-router-dom';

interface MentorSearchRowProps extends HTMLAttributes<HTMLDivElement> {
  mentor: UsersPermissionsUser
}

export const MentorSearchRow: FC<MentorSearchRowProps> = ({ mentor }) => {
  return <>
    <MentorSearchCell>
      <Link to={`/profile/${mentor.id}`} className="flex h-full gap-5 items-center">
        <ProfileIcon user={mentor} className="h-10 w-10" style={{minWidth: '2.5rem'}}/>
        <div className="flex flex-col flex-1 items-start">
          <span className="font-bold text-primary-500">{`${mentor.firstName} ${mentor.lastName}`}</span>
          <div className="text-sm lg:text-base">{mentor.bio}</div>
        </div>
      </Link>
    </MentorSearchCell>
    <MentorSearchCell className="text-sm lg:text-base">
      {mentor.group?.name}
    </MentorSearchCell>
    <MentorSearchCell className="text-sm lg:text-base">
      {mentor.level?.name}
    </MentorSearchCell>
    <MentorSearchCell>
      {mentor.completedActivities && <ChitList chits={mentor.completedActivities.map(activity => activity.name as string)}/>}
    </MentorSearchCell>
  </>
};
