import { ApolloError } from '@apollo/client';
import { FC, HTMLAttributes, useState, useEffect } from 'react';
import { merge } from '../helpers/ui';
import { ErrorMessage } from './ErrorMessage';
import { Loader } from "./Loader";

interface ContentLoaderProps extends HTMLAttributes<HTMLDivElement> {
  loading: boolean,
  error?: ApolloError
}

export const ContentLoader: FC<ContentLoaderProps> = ({ children, loading, error, className = 'bg-primary-50', ...props }) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  useEffect(() => {
    if (loading || error) {
      setShowOverlay(true);
      return;
    }

    setShowOverlay(false);
  }, [loading, error, setShowOverlay]);

  return <>
    {showOverlay ? <div {...props} className={merge(className, "w-full h-full absolute inset-0 flex items-center justify-center z-50")}>
      {error ? <ErrorMessage message={error.message} /> : <Loader className="w-8 h-8" />}
    </div> : null}
    {children}
  </>
};
