import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../shared/Button';

import i18n from '../../translations/Authentication';
import { Welcome } from './Welcome';
import { Title } from '../../shared/Title';
import { PageHelmet } from '../../shared/Helmet';

export const ResetPasswordConfirmed: FC = () => {
  const history = useHistory();
  return <Welcome>
    <div className="flex flex-col w-full max-w-md p-8">
      <PageHelmet pageTitle={i18n.helmets.resetPasswordConfirmed} />
      <Title size="2xl" className="mb-4">{i18n.resetPassword.confirmedTitle}</Title>
      <Button className="mt-2 w-full" primary onClick={() => history.push("")}>{i18n.resetPassword.loginButton}</Button>
    </div>
  </Welcome>;
};
