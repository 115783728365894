import { FC } from 'react';
import { merge } from "../helpers/ui";
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { HTMLAttributes } from 'react';

interface FillStarProps extends HTMLAttributes<HTMLDivElement> {
  value: number,
  buttonSize?: number,
}

export const FillStar: FC<FillStarProps> = ({ className, buttonSize, value }) => {
  const size: number = buttonSize || 12;
  const sizeClass = `h-${size} w-${size}`;

  const fillAmount = Math.min(1, Math.max(value, 0));
  const fillPercentage = Math.round(18 + fillAmount * 61);

  return <div className={merge(`h-${size} w-${size} relative`, className)}>
    <AiOutlineStar className="h-full w-full" />
    <div 
      className="absolute top-0 h-full overflow-hidden"
      style={{
        width: `${fillPercentage}%`
      }}
    >
      <AiFillStar className={sizeClass} /> 
    </div>
  </div>
};
