import { FC } from 'react'
import { useForm } from 'react-hook-form';
import { Form } from '../shared/Form';
import { Modal, ModalProps } from '../shared/Modal'
import { Title } from '../shared/Title'
import i18n from '../translations/Shared';
import { Enum_Report_Reportsubject, Enum_Report_Reporttype, Enum_Userspermissionsuser_Mentorstatus, ReportInput, useCreateReportMutation, UsersPermissionsUser } from '../services/graphql';
import { useAuthenticationService } from '../authentication/authentication-service';
import { Button } from '../shared/Button';
import { RadioField } from '../shared/inputs/RadioInput';
import { TextAreaField } from '../shared/inputs/TextAreaInput';

export interface ReportModalProps extends ModalProps {
  reportee: UsersPermissionsUser
  id: string
  subject: Enum_Report_Reportsubject
}

export interface ReportFormFields {
  issue: string
  reportType: { id: Enum_Report_Reporttype }
}

export const ReportModal: FC<ReportModalProps> = ({ open, onClose, id, subject, reportee }) => {
  const user = useAuthenticationService(state => state.user);
  const userIsMentor = user?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;

  const methods = useForm<ReportFormFields>({
    shouldUnregister: false,
    defaultValues: { issue: '' }
  })

  const [report, { loading }] = useCreateReportMutation();

  const submitReport = async (fieldsData: ReportFormFields) => {
    const dataToSubmit: ReportInput = {
      issue: fieldsData.issue,
      reportType: userIsMentor ? Enum_Report_Reporttype.Worried : fieldsData.reportType.id,
      reportee: reportee.id,
      reporter: user!.id.toString(),
      reportSubject: subject
    };

    switch (subject) {
      case Enum_Report_Reportsubject.Conversation:
        dataToSubmit.conversation = id;
        break;
      case Enum_Report_Reportsubject.Comment:
        dataToSubmit.comment = id;
        break;
      case Enum_Report_Reportsubject.Post:
        dataToSubmit.forumPost = id;
        break;
    }

    await report({
      variables: {
        input: {
          data: dataToSubmit
        }
      }
    })

    onClose();
  }

  return <Modal open={open} onClose={onClose}>
    <Form methods={methods} onSubmit={submitReport} className="bg-white flex flex-col items-center justify-center px-4 pt-5 pb-4 sm:p-8">
      <Title tight size="2xl" className="w-full text-center text-primary-800 pb-4 mb-2 border-b border-gray-100">
        {i18n.formatString(i18n.reports.form.title, reportee.firstName!)}
      </Title>
      <p className="text-left whitespace-pre-line my-3 leading-5">{i18n.formatString(i18n.reports.form.description, reportee.firstName!)}</p>
      {!userIsMentor && <RadioField
        className="w-full"
        title={i18n.reports.typeSelector.label}
        name="reportType"
        options={[
          { id: Enum_Report_Reporttype.Inaccurate, name: i18n.reports.typeSelector.notSure },
          { id: Enum_Report_Reporttype.Inappropriate, name: i18n.reports.typeSelector.unsafe }
        ]}
        requiredMessage={i18n.reports.typeSelector.required}
      />}
      <TextAreaField
        className="w-full my-4"
        title={i18n.reports.description.label}
        name="issue"
        placeholder={i18n.reports.description.placeholder}
        requiredMessage={i18n.reports.description.required}
      />
      <div className="w-full text-center flex justify-center gap-5 mt-4 pt-4 border-t border-gray-100">
        <Button onClick={onClose} className="mt-1">{i18n.reports.form.cancel}</Button>
        <Button loading={loading} submit className="mt-1" primary>{i18n.reports.form.submit}</Button>
      </div>
    </Form>
  </Modal>
}
