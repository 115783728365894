import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    inbox: {
      name: 'Inbox'
    },
    profile: {
      'name': 'Profile'
    },
    logout: {
      name: 'Logout'
    },
    mentors: {
      name: "Mentors"
    },
    forum: {
      name: "Forum"
    },
    reports: {
      name: "Reports"
    },
    contactMod: {
      name: "Contact moderator"
    }
  }
})
