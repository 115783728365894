import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { ForumPost as IForumPost, UsersPermissionsUser } from '../services/graphql';
import { ChitList } from '../shared/ChitList';
import { ForumPostDetails } from './ForumPostDetails';

interface ForumPostTitleAndTextProps extends HTMLAttributes<HTMLDivElement> {
  post: IForumPost
}

export const ForumPostTitleAndText: FC<ForumPostTitleAndTextProps> = ({ post }) => {
  const linkedModules: string[] = post.activities?.map(item => item.name as string) || [];

  return <div className="w-full">
    <ForumPostDetails
      user={post.postedBy as UsersPermissionsUser}
      postedAt={post.published_at}
      className="w-full"
    />
    <div className="font-bold self-start whitespace-pre-line">
      {post.title}
    </div>
    <div className="self-start whitespace-pre-line">
      {post.text}
    </div>
    {linkedModules.length > 0 && <ChitList chits={linkedModules} />}
  </div>
}