import { FC } from 'react';
import i18n from '../../translations/Authentication';
import { Welcome } from './Welcome';
import { Title } from '../../shared/Title';
import { PageHelmet } from '../../shared/Helmet';

export const ForgottenPasswordConfirmed: FC = () => {
  return <Welcome>
    <div className="flex flex-col w-full max-w-md p-8">
      <PageHelmet pageTitle={i18n.helmets.resetPasswordConfirmed} />
      <Title size="2xl" className="mb-4">{i18n.forgotten.resetRequestedTitle}</Title>
      <p>{i18n.forgotten.resetRequestedText}</p>
    </div>
  </Welcome>;
};
