import { useEffect, useState } from 'react';
import { FC } from 'react';
import { BsInboxes } from 'react-icons/bs';

import { useAuthenticationService } from '../authentication/authentication-service';
import { Conversation as IConversation, useGetMyConversationsQuery } from '../services/graphql';
import { Loader } from '../shared/Loader';
import { Conversation } from './Conversation';
import { ConversationListItem } from './ConversationListItem';

import i18n from '../translations/Conversation';
import { Route, useHistory, useParams } from 'react-router-dom';
import { useConversationService } from './conversation-service';
import { PageHelmet } from '../shared/Helmet';

interface ConversationPageProps { }

interface ConversationParams {
  id?: string
}

export const ConversationPage: FC<ConversationPageProps> = () => {

  const user = useAuthenticationService(state => state.user);
  const [conversations, setConversations] = useConversationService(state => [state.conversations, state.setConversations])
  const [conversationsWithNotifications, setConversationsWithNotifications] = useState<string[]>([]);
  
  
  const { data, refetch } = useGetMyConversationsQuery({
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    // Every 5 seconds, reload the conversation list.
    const id = setInterval(() => {
      refetch()
    }, 5000)
    return () => {
      clearInterval(id);
    };
  }, [refetch]);

  const history = useHistory();
  const { id } = useParams<ConversationParams>();

  useEffect(() => {
    let conversations = data?.me?.user?.conversations as IConversation[];

    const compareConv = (a: IConversation, b: IConversation) => {
      let aUpdatedAt = new Date(a.updated_at).getTime();
      let bUpdatedAt = new Date(b.updated_at).getTime();

      if (a.latestMessage) {
        aUpdatedAt = new Date(a.latestMessage.created_at).getTime()
      }

      if (b.latestMessage) {
        bUpdatedAt = new Date(b.latestMessage.created_at).getTime()
      }

      return bUpdatedAt - aUpdatedAt;
    }

    if (conversations) {
      const conversationsToSort = [...conversations];
      conversationsToSort.sort(compareConv);
      conversations = conversationsToSort;
    }

    if (data?.me?.user?.notifications) {   
      const withNotifications = data.me.user.notifications.map(notf => notf.customRelations.conversation?.toString());
      setConversationsWithNotifications(withNotifications);
    }
    
    setConversations(conversations);
  }, [data, setConversations])


  return <div className="h-full w-full flex">
    <PageHelmet pageTitle={i18n.helmets.inbox}/>
    <div className="w-full flex flex-col z-0 sm:w-72 lg:w-96 bg-white border-r max-h-full overflow-auto"
      onClick={() => history.push('/messages')}
    >
      {conversations && user
        ? conversations.map((conversation) => {
          return <ConversationListItem
            key={conversation.id}
            conversation={conversation}
            userId={user.id.toString()}
            onClick={() => history.push(`/messages/${conversation.id}`)}
            selected={conversation.id === id}
            notification={conversationsWithNotifications.includes(conversation.id)}
          />
        })
        : <div className="m-8 flex justify-center items-center">
          <Loader className="w-8" />
        </div>
      }
    </div>
    <Route path="/messages/:id" component={Conversation} />
    <Route path="/messages" exact>
      <div className="text-center flex-grow h-full hidden sm:flex items-center flex-col justify-center">
        <BsInboxes className="w-12 h-12" />
        <h3 className="mt-2 font-medium text-gray-900">{i18n.inbox.emptyState.title}</h3>
        <p className="mt-1 text-sm text-gray-500">{i18n.inbox.emptyState.description}</p>
      </div>
    </Route>
  </div>
};
