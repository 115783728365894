import { FC, HTMLAttributes } from 'react';
import { ForumPost } from '../services/graphql';
import { breadcrumb, PageHeader } from '../shared/PageHeader';
import i18n from "../translations/Forum";

interface ForumPostHeaderProps extends HTMLAttributes<HTMLDivElement> {
  post: ForumPost,
}

export const ForumPostHeader: FC<ForumPostHeaderProps> = ({post}) => {
  const breadcrumbs: breadcrumb[] = [
    {
      name: post.group?.organisation?.name as string
    },
    {
      name: post.group?.name as string,
      link: `/course/${post.group?.id}`
    },
    {
      name: i18n.forum.title,
      link: `/forum/${post.group?.id}`
    },
  ];

  return <PageHeader 
    title={post.title}
    breadcrumbs={breadcrumbs}
  />
};