import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { merge } from '../../helpers/ui';
import { Review } from '../../services/graphql';
import { ProfileReviewStars, ReviewType } from './ProfileReviewStars';

interface ProfileReviewProps extends HTMLAttributes<HTMLDivElement> {
  review: Review,
  secondary?: boolean
}

export const ProfileReview: FC<ProfileReviewProps> = ({ review, secondary }) => {
  const backgroundColor = secondary ? "bg-secondary-100" : "bg-primary-100";
  const padding = review.text ? "p-5" : "p-1";
  return <div className={merge(backgroundColor, padding, "rounded m-5")}>
    {review.text && <span className="italic">{`"${review.text}"`}</span>}
    <div className="flex flex-wrap justify-center">
      { review.speedRating ? <ProfileReviewStars type={ReviewType.Speed} stars={review.speedRating}/> : null }
      { review.knowledgeRating ? <ProfileReviewStars type={ReviewType.Knowledge} stars={review.knowledgeRating}/> : null }
      { review.helpfulRating ? <ProfileReviewStars type={ReviewType.Helpful} stars={review.helpfulRating}/> : null }
    </div>
  </div>
};
