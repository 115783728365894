import { Link } from 'react-router-dom';
import { FC } from 'react';

interface HeaderMenuItemProps {
  label: string,
  link: string
}

export const HeaderMenuItem: FC<HeaderMenuItemProps> = (props: HeaderMenuItemProps) => {
  return <Link
    to={props.link}
    className='inline-flex items-center px-2 py-2 w-full border text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:bg-primary-100'
    aria-label={props.label}
  >
    {props.label}
  </Link>;
};
