import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { merge } from '../../helpers/ui';
import { PostSummary } from '../../forum/PostSummary';
import { UsersPermissionsUser } from '../../services/graphql';
import { Card } from '../../shared/Card';
import i18n from "../../translations/User";

interface ProfilePostsCardProps extends HTMLAttributes<HTMLDivElement> {
  user: UsersPermissionsUser
}

export const ProfilePostsCard: FC<ProfilePostsCardProps> = ({ user, className }) => {
  const forumPosts = user.forumPosts?.map(post => <PostSummary key={post.id} post={post} />);
  
  return <Card className={merge(className, "p-5 flex flex-col gap-2 text-center")}>
    <span className="font-bold w-full">{i18n.profile.posts.title}</span>
    {user.forumPosts && user.forumPosts.length > 0 ? forumPosts : <div>
      {i18n.profile.posts.noPosts(user.firstName || "")}
    </div>}

  </Card>
};
