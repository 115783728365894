import { FC } from 'react';
import { UsersPermissionsUser } from '../../services/graphql';
import { MentorSearchListResult } from './MentorSearchListResult';
import { HTMLAttributes } from 'react';
import { merge } from '../../helpers/ui';

interface MentorSearchListProps extends HTMLAttributes<HTMLDivElement> {
  mentors: UsersPermissionsUser[]
}

export const MentorSearchList: FC<MentorSearchListProps> = ({ mentors, className }) => {
  return <div className={merge(className, "border-t-2")}>
    {mentors.map(mentor => <MentorSearchListResult mentor={mentor} key={mentor.id} />)}
  </div>
};
