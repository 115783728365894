import { FC, useState } from 'react';
import { useGetRecommendedMentorsQuery, UsersPermissionsUser } from '../services/graphql';
import { ContentLoader } from '../shared/ContentLoader';
import { MentorCard } from './MentorCard';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef } from 'react';
import { LegacyRef } from 'react';
import { Button } from '../shared/Button';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { HTMLAttributes } from 'react';
import { useWindowSize } from '../helpers/windowSize';
import i18n from "../translations/Mentors";
import { useEffect } from 'react';
interface MentorRecommendationsProps extends HTMLAttributes<HTMLDivElement> {
  responsiveMode?: boolean
}

const twoSlideMinWidth = 600;
const threeSlideMinWidth = 1200;

export const MentorRecommendations: FC<MentorRecommendationsProps> = ({ responsiveMode, className }) => {
  const [mentorList, setMentorList] = useState<UsersPermissionsUser[] | undefined>()
  const { loading, error, data } = useGetRecommendedMentorsQuery();

  useEffect(() => {
    if (!data?.recommendedMentors) return;
    let mentorList = [...data.recommendedMentors];
    setMentorList(mentorList as UsersPermissionsUser[]);
  }, [data])

  const { width } = useWindowSize();
  let slidesToShow: number;
  if (!responsiveMode || width < twoSlideMinWidth) {
    slidesToShow = 1;
  } else if (width < threeSlideMinWidth) {
    slidesToShow = 2;
  } else {
    slidesToShow = 3;
  }

  const showSlider = mentorList && mentorList.length > slidesToShow;

  const sliderElement = useRef<Slider>();

  const back = () => {
    sliderElement.current?.slickPrev();
  }

  const forward = () => {
    sliderElement.current?.slickNext();
  }

  const mentorCardList = mentorList?.map((mentor) => {
    return <MentorCard
      key={mentor.id}
      user={mentor}
    />
  });

  return <div className={className}>
    <ContentLoader loading={loading} error={error}>
      {mentorCardList?.length === 0 ? <div className="flex justify-center my-5">
        {i18n.noMentors}
      </div> :
        <div className="relative">
          {showSlider ? <>
            <span className="-left-3 absolute top-1/2 z-10" >
              <Button primary compact round className="-top-5" onClick={back}>
                <FiArrowLeft className="w-6 h-6" />
              </Button>
            </span>
            <div className="w-full">
              <Slider
                ref={sliderElement as LegacyRef<Slider>}
                arrows={false}
                slidesToShow={slidesToShow}
                adaptiveHeight
              >
                {mentorCardList}
              </Slider>
            </div>
            <span className="-right-3 absolute top-1/2  z-10" >
              <Button primary compact round className="-top-5" onClick={forward}>
                <FiArrowRight className="w-6 h-6" />
              </Button>
            </span>
          </> : <div className="flex w-full justify-around">
            {mentorCardList}
          </div>
          }
        </div>}
    </ContentLoader>
  </div>
};
