import { FC, HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';

interface CountIndicatorProps extends HTMLAttributes<HTMLDivElement> {
  count: number,
  secondary? : boolean
}

export const CountIndicator: FC<CountIndicatorProps> = ({ className, count, secondary }) => {
  const colors = secondary ? "bg-secondary-400 text-black" : "bg-red-600 text-white";
  return <div className={merge("w-6 h-6 flex items-center justify-center font-bold rounded-full text-sm border-white", colors, className)}>
    {count}
  </div>
};
