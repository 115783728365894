import { FC, HTMLAttributes } from 'react';
import { merge } from '../../helpers/ui';
import { Enum_Userspermissionsuser_Mentorstatus, UsersPermissionsUser } from '../../services/graphql';
import { Card } from '../../shared/Card';
import { StarRating } from '../../shared/StarRating';
import { OrgProfilePicture } from '../../shared/OrgProfilePicture';
import { ProfileCourseLevel } from './ProfileCourseLevel';
import i18n from "../../translations/User";
import { ProfileCourseModules } from './ProfileCourseModules';
import { PointsCount } from '../../shared/PointsCount';
import { useReviewStats } from '../useReviewAverage';
import { FiCheck, FiEdit } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Label } from '../../shared/Label';


interface ProfileCourseCardProps extends HTMLAttributes<HTMLDivElement> {
  user: UsersPermissionsUser,
  myProfile: boolean
}

export const ProfileCourseCard: FC<ProfileCourseCardProps> = ({ user, className, myProfile }) => {
  const userIsMentor = user.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;
  const reviewStats = useReviewStats(user);
  const history = useHistory();

  const currentCourses = user.currentActivities?.map(activity => activity.name as string);
  const completedCourses = user.completedActivities?.map(activity => activity.name as string);

  return <Card className={merge("flex flex-col", className)}>
    {user.organisation && <div className="w-full border-b flex items-center justify-center p-7">
      <OrgProfilePicture org={user.organisation} />
    </div>}
    {reviewStats.average && userIsMentor ? <div className="p-5 border-b">
      <div className="flex items-center gap-2">
        <span>{i18n.profile.courseSummary.verifiedMentor}</span>
        <FiCheck className="text-green-500 h-8 w-8"/>
      </div>
      <div className="w-full flex items-center justify-center">
        <StarRating value={reviewStats.average} buttonSize={8} withValue />
        <div className="sm:hidden ml-5">
          <PointsCount points={user.points} />
        </div>
      </div>
    </div> : null}
    <div className="w-full p-5 relative">
      {user.bio && <div className="flex flex-col mb-2">
        <Label className="text-primary-400">{i18n.profile.courseSummary.bio}</Label>
        <div>{user.bio}</div>
      </div>}
      {user.group && <div className="flex flex-col mb-2">
        <Label className="text-primary-400">{i18n.profile.courseSummary.course}</Label>
        <Link to={`/course/${user.group.id}`}>{user.group.name}</Link>
      </div>}
      {user.level && <div className="flex flex-col mb-2">
        <Label className="text-primary-400">{i18n.profile.courseSummary.year}</Label>
        <ProfileCourseLevel level={user.level} />
      </div>}
      {currentCourses && currentCourses.length ? <ProfileCourseModules title={i18n.profile.courseSummary.enrolledModules} names={currentCourses} /> : null}
      {completedCourses && completedCourses.length ? <ProfileCourseModules title={i18n.profile.courseSummary.completedModules} names={completedCourses} secondary /> : null}
      {myProfile && <button className="absolute p-2 top-3 right-3    text-primary-400" onClick={() => history.push("/edit_profile")}>
        <FiEdit className="w-5 h-5" />
      </button>}
    </div>
  </Card>
};
