import { useEffect } from 'react';
import { HTMLAttributes } from 'react';
import { FC } from 'react';
import { useAuthenticationService } from '../authentication/authentication-service';
import { ForumPost as IForumPost, Comment, Enum_Report_Reportsubject } from '../services/graphql';
import { Card } from '../shared/Card';
import { Vote, VoteButtons } from '../shared/VoteButtons';
import i18n from '../translations/Forum';
import { DepGraph } from 'dependency-graph';
import { useState } from 'react';
import { TextAreaInput } from '../shared/inputs/TextAreaInput';
import { Button } from '../shared/Button';
import { Menu } from '@headlessui/react';
import { FiFlag, FiMoreHorizontal } from 'react-icons/fi';
import { BsReply } from 'react-icons/bs';
import { ReportModal } from '../shared/ReportModal';
import { ForumPostTitleAndText } from './ForumPostTitleAndText';

interface ForumPostProps extends HTMLAttributes<HTMLDivElement> {
  post: IForumPost
  onUpvote?: () => void
  onSend: (comment: string, replyId?: string) => void
  loading?: boolean
  votesLoading?: boolean
}

export const ForumPost: FC<ForumPostProps> = ({ post, onUpvote, votesLoading, onSend, loading }) => {
  const myUser = useAuthenticationService(state => state.user);
  const [comments, setComments] = useState<CommentProps[]>();
  const [reportingOnPost, setReportingOnPost] = useState<boolean>(false);

  const getCurrentVote = () => {
    const userInVotesList = post.upvotedBy?.find(upvoter => upvoter.id === myUser?.id);
    return userInVotesList ? Vote.UP : Vote.NONE;
  }

  useEffect(() => {
    const graph = new DepGraph();
    post.comments?.forEach(c => { graph.addNode(c.id, c) })
    post.comments?.forEach(c => { if (c.replyTo) graph.addDependency(c.replyTo, c.id) });

    const commentMagic = (comments: string[], level: number = 0): any[] => {
      if (!comments || comments.length === 0) return [];
      return comments.map((commentId) => {
        const comment = graph.getNodeData(commentId) as Comment;
        const childComments = graph.directDependenciesOf(commentId);
        const count = graph.dependenciesOf(commentId).length;
        return {
          comment,
          postId: post.id,
          children: commentMagic(childComments, level + 1),
          level,
          count,
          onSend
        }
      }).sort((a, b) => b.count - a.count)
    }

    const topLevelComments = graph.entryNodes();
    // Recursively construct comments
    const comments = commentMagic(topLevelComments);
    setComments(comments);
  }, [post, onSend])


  return <Card className="flex flex-col items-start p-5 gap-2">
    <ForumPostTitleAndText post={post}/>
    <div className="flex gap-5">
      <VoteButtons
        onVote={(vote) => vote && onUpvote && onUpvote()}
        upVotes={post.upvotedBy?.length || 0}
        currentVote={getCurrentVote()}
        loading={votesLoading}
      />
      <Button onClick={() => setReportingOnPost(true)}>
        {i18n.forum.create.fields.report}
      </Button>
    </div>
    <div className="font-bold text-lg">
      {i18n.responses.title}
    </div>
    {comments && comments.length !== 0 ? comments.map((c) => <CommentView loading={loading} {...c} />) : <p className="w-full text-center mb-3">{i18n.responses.noResponses}</p>}
    <CommentForm loading={loading} onSend={onSend} postId={post.id} placeholder="Leave a comment" />
    <ReportModal
      id={post.id}
      subject={Enum_Report_Reportsubject.Post}
      reportee={post.postedBy!}
      open={reportingOnPost}
      onClose={() => setReportingOnPost(false)}
    />
  </Card>
}

interface CommentProps {
  comment: Comment,
  postId: string,
  children: CommentProps[]
  level: number,
  loading?: boolean
  count: number,
  onSend: (comment: string, replyId?: string) => void
}

export const CommentView: FC<CommentProps> = ({ postId, comment, onSend, children, level, loading, count }) => {
  const [isExpanded, setExpanded] = useState<boolean>(level < 1);
  const [canReply, setReplyState] = useState<boolean>(false);
  const [reportingComment, setReportingComment] = useState<Comment | undefined>();

  return <div className={`w-full mt-2 ${level === 0 ? 'mb-4' : ''}`}>
    <div className="flex items-start relative">
      <div className="flex">
        <div className={`mt-1 w-8 h-8 z-10 border overflow-hidden border-gray-300 rounded-full mr-3`}>
          {comment.postedBy?.profilePicture ? <img src={comment.postedBy?.profilePicture.url} alt={`${comment.postedBy?.firstName} ${comment.postedBy?.lastName}`} /> : null}
        </div>
        {isExpanded && count ? <div style={{ height: 'calc(100% - 2rem)' }} className={`absolute top-4 left-4 -ml-px w-0.5 bg-gray-200`}></div> : null}
      </div>
      <div className="w-full">
        <div className="flex justify-between">
          <p className="text-gray-800 mr-1">
            {`${comment.postedBy?.firstName} ${comment.postedBy?.lastName}`}
          </p>
          <Menu as="div" className="relative inline-block text-left text-sm">
            <Menu.Button className="px-1 hover:bg-gray-50 rounded-lg">
              <FiMoreHorizontal className="w-5 h-5"></FiMoreHorizontal>
            </Menu.Button>
            <Menu.Items className="origin-top-right absolute right-0 mt-1 w-max-content rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                <button onClick={() => setReportingComment(comment)} className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-50">
                  <FiFlag className="mr-2" />
                  {i18n.forum.create.fields.report}
                </button>
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
        {comment?.text?.split('\n').map(t => <p>{t}</p>)}
        <div className="flex space-x-2 text-sm text-gray-500">
          <button className="flex items-center" onClick={() => setReplyState(!canReply)}>
            <BsReply className="w-6 h-6 mr-1" />
            {i18n.forum.create.fields.reply}
          </button>
          {count ? <button className="flex items-center" onClick={() => setExpanded(!isExpanded)}>
            {!isExpanded ? `See ${count} replies` : 'Hide replies'}
          </button> : null}
        </div>
        {canReply ? <CommentForm loading={loading} onSend={onSend} postId={postId} replyId={comment.id} placeholder="Reply to this comment" /> : null}
        {isExpanded ? children.map(c => <CommentView {...c} />) : null}
      </div>
    </div>
    <ReportModal
      reportee={comment.postedBy!}
      open={!!reportingComment}
      onClose={() => setReportingComment(undefined)}
      id={comment.id}
      subject={Enum_Report_Reportsubject.Comment}
    />
  </div>
}

interface CommentFormProps {
  postId: string,
  replyId?: string
  placeholder: string
  loading?: boolean
  onSend: (comment: string, replyId?: string) => void
}
export const CommentForm: FC<CommentFormProps> = ({ postId, replyId, onSend, loading, placeholder }) => {

  const user = useAuthenticationService(state => state.user);
  const [text, setText] = useState<string>('');

  const postComment = async () => {
    if (!postId && text) return;
    try {
      onSend(text, replyId);
      setText('');
    } catch (e) {
    }
  }

  return <form className="flex w-full items-start mt-1">
    <div className="w-8 h-8 mb-2 mt-1 rounded-full border mr-3 border-gray-300">
      {user?.profilePicture ? <img src={user?.profilePicture.url} alt={`${user?.firstName} ${user?.lastName}'s profile`} /> : null}
    </div>
    <TextAreaInput
      name="text"
      placeholder={placeholder}
      value={text}
      onChange={setText}
    />
    {text ? <Button onClick={() => postComment()} loading={loading} className="mb-2 mt-1 ml-3" submit primary>{i18n.forum.create.fields.submit}</Button> : null}
  </form>
}
