import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Activity, Group, useGetGroupQuery, UsersPermissionsUser } from '../services/graphql';
import { ContentLoader } from '../shared/ContentLoader';
import { PageContainer } from '../shared/PageContainer';
import { CourseHeader } from './CourseHeader';
import i18n from "../translations/Courses";
import { CourseDetailsCard } from './CourseDetailsCard';
import { PostSummary } from '../forum/PostSummary';
import { CourseModulesCard } from './CourseModulesCard';
import { PageHelmet } from '../shared/Helmet';

interface CoursePageParams {
  id: string
}

export const CoursePage: FC = () => {
  const { id } = useParams<CoursePageParams>();

  const { data, loading, error } = useGetGroupQuery({
    variables: {
      id: id
    }
  });

  const group = data?.group as Group;

  return <ContentLoader loading={loading} error={error}>
    {group && <>
      <PageHelmet pageTitle={i18n.helmets.courseDetails}/>
      <CourseHeader course={group} pageTitle={group.name} />
      <PageContainer>
        <div className="flex flex-col sm:flex-row gap-5">
          <div className="w-full sm:w-72 flex flex-col gap-2">
            <CourseDetailsCard
              courseId={id}
              mods={group.users as UsersPermissionsUser[]}
            />
          </div>
          <div className="flex-1 flex flex-col gap-5">
            <CourseModulesCard modules={group.activities as Activity[]}/>
            {group.forumPosts && group.forumPosts?.length > 0 && <div>
              <div className="font-bold text-primary-600 text-lg mb-2">
                {i18n.home.topPosts.title}
              </div>
              <div className="grid lg:grid-cols-2 gap-5">
                {group.forumPosts.map((post) => <PostSummary post={post} key={post.id} />)}
              </div>
            </div>}
          </div>
        </div>
      </PageContainer>
    </>}
  </ContentLoader >
};
