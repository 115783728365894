import { FC, HTMLAttributes, KeyboardEvent, useCallback, useEffect, useState } from 'react';
import findIndex from 'lodash.findindex';
import { compareTwoStrings } from 'string-similarity';

import SearchItem, { Item } from './SearchItem';
import { TextInput } from '../inputs/TextInput';
import { merge } from '../../helpers/ui';

interface Section {
  id: string
  items: Item[]
}

export interface SearchBarProps extends HTMLAttributes<HTMLDivElement> {
  options?: Section[]
  onSearchChange: (item: Item) => void
}

const SearchBar: FC<SearchBarProps> = ({ options = [], onSearchChange, className }) => {

  const [isOpen, setOpenState] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<Item>()
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>()
  const [term, setTerm] = useState<string>('')
  const [results, setResults] = useState<Section[]>()

  const setSelected = useCallback((index: [number, number]) => {
    const [sectionIndex, itemIndex] = index;
    setSelectedIndex(index);
    setSelectedItem(results?.[sectionIndex].items[itemIndex])
  }, [setSelectedIndex, setSelectedItem, results]);

  // Side effects when "term" changes.
  useEffect(() => {
    // If the user has cleared out the search bar, dismiss the results container.
    if (!term) {
      setResults(options);
      return;
    }

    // If the user has started typing into the search bar without clicking
    // (for example, if there is an existing term that was clicked out of), open the results container again.
    if (term && !isOpen) setOpenState(true);

    const matching = options.map(s => ({ ...s, items: s.items.filter(option => compareTwoStrings(option.name, term) > 0.1)}))
    setResults(matching);
  }, [term, options, isOpen])

  // Side effects when results change
  // This mainly covers trying to maintain a "sticky" selection:
  // If an option is selected (highlighted) but the user decides to refine the selection by typing more
  // we want to maintain selection on that option/section if possible
  useEffect(() => {
    if (!selectedItem || !results || !results.length) return;

    // With the currently selected item (which is possibly invalid position-wise, might even not exist in the changed results)
    // try and find if the section of selected item still exists in new set of results
    const currentSelectedSectionInNewResults = findIndex(results, (section) => {
      if (!section) return false;
      return selectedItem.section === section.id;
    });

    // If it doesn't, that also means specific item does not exist, reset selection.
    if (currentSelectedSectionInNewResults === -1) { setSelected([0,0]); return; }

    // Try and find if the selected item still exists in new set of results
    const currentSelectedItemInNewResults = findIndex(results[currentSelectedSectionInNewResults].items, (item) => {
      return selectedItem.name === item.name;
    });

    // If it doesn't, just reset back to the start of the known section.
    if (currentSelectedSectionInNewResults === -1) { setSelected([currentSelectedSectionInNewResults,0]); return; }

    // If specific section & item position is found, set current selection to them.
    setSelected([currentSelectedSectionInNewResults, currentSelectedItemInNewResults])
  }, [results, selectedItem, setSelected]);


  const onFieldTextChange = (value: string) => {
    setTerm(value);
  }


  const onItemClick = (sectionIndex: number, itemIndex: number) => {
    if (!results) return;

    setSelected([sectionIndex, itemIndex]);

    const section = results[sectionIndex];
    if (!section) return;

    const item = section.items[itemIndex];
    if (!item) return;
    console.log(item);
    onSearchChange(item);

    setTerm('');
    setSelected([0,0]);
    setOpenState(false);
  }

  // A helper function/enum for array traversal
  enum Bound {
    Top,
    Bottom
  }

  const isAt = (bound: Bound, index: number, array?: any[]) => {
    if (bound === Bound.Top) {
      return (index === 0)
    }

    if (bound === Bound.Bottom) {
      if (!array) throw new Error('An array needs to be provided in the bound is bottom.');
      return (index === array.length - 1)
    }
  }

  const lastIndex = (array: any[]) => {
    return array.length - 1
  }

  const onFieldKeyEvent = (e: KeyboardEvent<HTMLInputElement>) => {

    if (!selectedIndex || !results) return;
    const [selectedSectionIndex, selectedItemIndex] = selectedIndex;

    switch (e.key) {
      case 'ArrowDown':
        if (isAt(Bound.Bottom, selectedItemIndex, results[selectedSectionIndex].items)) {
          if (isAt(Bound.Bottom, selectedSectionIndex, results)) {
            // If at the bottom of the results and the bottom of the sections, wrap to the top
            setSelected([0, 0])
            break;
          }

          //  If at the bottom of _this_ section, go to the top of the next section.
          const newSectionIndex = selectedSectionIndex + 1;
          setSelected([newSectionIndex, 0])
          break;
        }

        // If no special concerns, go to the next item.
        setSelected([selectedSectionIndex, selectedItemIndex + 1]);
        break;

      case 'ArrowUp':
        if (isAt(Bound.Top, selectedItemIndex)) {
          if (isAt(Bound.Top, selectedSectionIndex)) {
            // If at the top of the results and the top of the sections, wrap to the bottom.
            const newSectionIndex = lastIndex(results);
            setSelected([newSectionIndex, lastIndex(results[newSectionIndex].items)])
            break;
          }

          // If at the top of _this_ section, go to the bottom of the previous section.
          const newSectionIndex = selectedSectionIndex - 1;
          setSelected([newSectionIndex, lastIndex(results[newSectionIndex].items)])
          break;
        }

        // If no special concerns, go to the previous item.
        setSelected([selectedSectionIndex, selectedItemIndex - 1]);
        break;

      case 'Enter':
        onItemClick(selectedSectionIndex, selectedItemIndex);
        break;

      case 'Escape':
        setOpenState(false)
        break;

      default:
        return;
    }

    e.preventDefault();
  }

  return <div className={merge("flex-1 flex flex-col items-center justify-center", className)}>
    {isOpen ? <div className="absolute inset-0 z-0" onClick={(e) => {
      e.preventDefault();
      setOpenState(false)
    }}></div> : null}
    <div className="w-full">
      <div className="relative">
        <TextInput value={term} onClick={() => setOpenState(true)} onChange={(value) => onFieldTextChange(value)} onKeyDown={onFieldKeyEvent} name="search" placeholder="Search for modules" autoComplete="off"  />
        {isOpen ? <div className="absolute mt-1 pt-1 text-black flex flex-col rounded-md border w-full box-content bg-white overflow-y-scroll overflow-x-hidden max-h-72 z-10">
          {results?.map((section, sectionIndex) => <>
            <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide px-3 py-1">{section.id}</h2>
            {section.items.map((result, resultIndex) => <SearchItem index={[sectionIndex, resultIndex]} selectedIndex={selectedIndex} item={result} onClick={() => onItemClick(sectionIndex, resultIndex)}></SearchItem>)}
          </>
          )}
        </div> : null}
      </div>
    </div>
  </div>
}

export default SearchBar;
