import { FC, ReactNode } from 'react'
import { Modal, ModalProps } from './Modal'
import { Button } from './Button'
import { FiAlertTriangle } from 'react-icons/fi'
import { Title } from './Title'

export enum AlertStyle {
  Warning = 'Warning'
}

export interface AlertProps extends ModalProps {
  title: string
  description: string
  style?: AlertStyle
  onSuccess?: () => void

  confirmLabel?: string
  confirmButton?: ReactNode
  cancelLabel?: string
}

export const Alert: FC<AlertProps> = ({ open, onClose, onSuccess, title, description, style, confirmLabel = 'Confirm', cancelLabel = 'Cancel', confirmButton }) => {

  return (
    <Modal open={open} onClose={onClose}>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="sm:flex sm:items-start">
          {(() => {
            switch (style) {
              case AlertStyle.Warning:
                return <div className="mx-auto flex-shrink-0 sm:mr-4 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <FiAlertTriangle className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
              default:
                return;
            }
          })()}
          <div className="mt-3 text-center sm:mt-0 sm:text-left">
            <Title tight size="2xl" className="text-primary-800">
              {title}
            </Title>
            <div className="mt-2">
              <p className="text-gray-500">
                {description}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="space-y-2 sm:space-y-0 px-6 py-2 sm:px-6 sm:pb-6 justify-end sm:flex sm:flex-row">
        <Button className="sm:mr-3 w-full sm:w-auto" onClick={() => onClose()}>
          {cancelLabel}
        </Button>
        { confirmButton ? confirmButton : <Button primary className="w-full sm:w-auto" onClick={onSuccess}>
          {confirmLabel}
        </Button> }
      </div>

    </Modal>
  )
}
