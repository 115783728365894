import { FC } from 'react';
import { Level } from '../../services/graphql';
import i18n from "../../translations/User";

interface ProfileCourseLevelProps {
  level: Level
}

export const ProfileCourseLevel: FC<ProfileCourseLevelProps> = ({ level }) => {
  const graduated = level.name === i18n.profile.courseSummary.graduated;

  return <div>
    <span>{level.name}</span>
    {graduated && <span className="ml-2">{i18n.profile.courseSummary.graduatedEmoji}</span>}
  </div>
};
