import { FC, HTMLAttributes } from 'react';
import { Role, useAuthenticationService } from '../authentication/authentication-service';
import { useStartConversation } from '../conversations/useStartConversation';
import { Enum_Userspermissionsuser_Mentorstatus, UsersPermissionsUser } from '../services/graphql';
import { Button } from '../shared/Button';
import { breadcrumb, PageHeader } from '../shared/PageHeader';
import { PointsCount } from '../shared/PointsCount';
import { ProfileIcon } from '../shared/ProfileIcon';
import i18n from "../translations/User";

interface ProfileHeaderProps extends HTMLAttributes<HTMLDivElement> {
  user: UsersPermissionsUser,
  myProfile?: boolean
}

export const ProfileHeader: FC<ProfileHeaderProps> = (props: ProfileHeaderProps) => {
  const { startChat, loading: startChatLoading } = useStartConversation();

  const logo = <ProfileIcon className="w-full h-full" user={props.user} />;
  const title = `${props.user.firstName} ${props.user.lastName}`;
  const subtitles: breadcrumb[] = [];

  if (props.user.organisation?.name) {
    subtitles.push({
      name: props.user.organisation.name
    })
  }

  if (props.user.group?.name) {
    subtitles.push({
      name: props.user.group.name,
      link: `/course/${props.user.group.id}`
    })
  }

  const user = useAuthenticationService(state => state.user);
  const userIsModerator = user?.role.id.toString() === Role.Moderator;

  const showChatButton =
    userIsModerator ||
    props.user.role?.id.toString() === Role.Moderator ||
    props.user.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;

  const additionalComponent = <div className="flex gap-2 items-center">
    {!props.myProfile && showChatButton && <Button onClick={() => startChat(props.user.id)} loading={startChatLoading}>
      {i18n.profile.startChat}
    </Button>}
    <div className="hidden sm:flex">
      <PointsCount points={props.user.points} />
    </div>
  </div>;

  return <PageHeader
    title={title}
    breadcrumbs={subtitles}
    logo={logo}
    additionalComponent={additionalComponent}
  />
};