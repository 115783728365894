import { FC, HTMLAttributes } from 'react';
import { Group } from '../services/graphql';
import { breadcrumb, PageHeader } from '../shared/PageHeader';

interface CourseHeaderProps extends HTMLAttributes<HTMLDivElement> {
  course: Group,
  pageTitle: string
}

export const CourseHeader: FC<CourseHeaderProps> = ({course, pageTitle}) => {
  const breadcrumbs: breadcrumb[] = [
    {
      name: course.organisation?.name as string
    }
  ];

  return <PageHeader 
    title={pageTitle}
    breadcrumbs={breadcrumbs}
  />
};