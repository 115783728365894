import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ForumPostTitleAndText } from '../forum/ForumPostTitleAndText';
import { Enum_Userspermissionsuser_Mentorstatus, ForumPost, Report } from '../services/graphql';
import { Card } from '../shared/Card';
import i18n from "../translations/Moderators";
import { ReportSummaryCard } from './ReportSummaryCard';

interface ReportPostSummaryProps {
    post: ForumPost,
    report: Report
}

export const ReportPostSummary: FC<ReportPostSummaryProps> = ({ post, report }) => {
    const revokedMentor = report.reportee?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Revoked;

    return <div className="flex mx-auto max-w-3xl my-4 px-4 flex-col items-center">
        <Link to={`/forum/posts/${post.id}`} className="w-full">
            <Card className="p-5 w-full">
                <div className="font-bold text-lg w-full text-center mb-2 text-primary-500">
                    {i18n.forum.post.cardLabel}
                </div>
                <ForumPostTitleAndText post={post} />
            </Card>
        </Link>
        <ReportSummaryCard report={report}/>
        {revokedMentor && <Card className="self-center flex flex-col items-center gap-2 m-5 p-5">
            <span className="text-primary-600 font-bold text-lg">{i18n.response.mentorRevoked(report.reportee!)}</span>
        </Card>}
    </div>
};
