import { FC, HTMLAttributes } from 'react';
import { ImageField } from '../../../shared/inputs/ImageInput';
import { TextAreaField } from '../../../shared/inputs/TextAreaInput';
import { TextField } from '../../../shared/inputs/TextInput';
import { Title } from '../../../shared/Title';
import i18n from '../../../translations/User';


interface PersonalDetailsSectionProps extends HTMLAttributes<HTMLDivElement> {
  compact?: boolean
}

export const PersonalDetailsSection: FC<PersonalDetailsSectionProps> = ({ children, compact, ...props }) => {
  return <>
    <Title size="xl" className={!compact ? 'sm:col-span-3' : ''}>{i18n.profile.courseSummary.personalDetails}</Title>
    <ImageField
      className={!compact ? 'sm:row-span-2 h-64 sm:h-full' : 'h-64 pb-4'}
      name="profilePicture"
      title={i18n.editProfile.fields.profilePhoto.label}
    />
    <TextField
      name="firstName"
      title={i18n.editProfile.fields.firstName.label}
      placeholder={i18n.editProfile.fields.firstName.label}
      requiredMessage={i18n.editProfile.fields.firstName.requiredMessage}
    />
    <TextField
      name="lastName"
      title={i18n.editProfile.fields.lastName.label}
      placeholder={i18n.editProfile.fields.lastName.label}
      requiredMessage={i18n.editProfile.fields.lastName.requiredMessage}
    />
    <TextField
      name="email"
      className={!compact ? 'sm:col-span-2' : ''}
      title={i18n.editProfile.fields.email.label}
      placeholder={i18n.editProfile.fields.email.label}
      requiredMessage={i18n.editProfile.fields.email.requiredMessage}
    />
    <TextAreaField
      name="bio"
      className={!compact ? 'sm:col-span-3 mt-2' : ''}
      title={i18n.editProfile.fields.bio.label}
      placeholder={i18n.editProfile.fields.bio.label}
      maxLength={140}
      adaptiveHeight={compact}
    />
  </>
};
