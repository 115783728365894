import { FC, HTMLAttributes } from 'react';
import i18n from "../../translations/Conversation";

interface PrivacyWarningProps extends HTMLAttributes<HTMLDivElement> {
}

export const PrivacyWarning: FC<PrivacyWarningProps> = () => {
  return <div className="border bg-white flex flex-col w-full rounded-lg items-center p-4 my-4 self-center">
    <div>{i18n.review.privacyWarning}</div>
  </div>;
};
