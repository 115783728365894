import { FC } from 'react';
import { PageContainer } from '../../shared/PageContainer';
import { Enum_Notification_Type, useGetMeQuery, useGetMyNotificationsQuery, UsersPermissionsUser } from '../../services/graphql';
import { ContentLoader } from '../../shared/ContentLoader';
import { Card } from '../../shared/Card';
import { ModeratorHomeHeader } from './ModeratorHomeHeader';
import { Link } from 'react-router-dom';
import i18n from "../../translations/Home";
import { ModeratorHomeStats } from './ModeratorHomeStats';
import { ModeratorHomeFeed } from './ModeratorHomeFeed';
import { PageHelmet } from '../../shared/Helmet';

interface HomeProps { }

export const ModeratorHome: FC<HomeProps> = () => {
  const { data, loading, error } = useGetMeQuery();

  const user = data?.me?.user as UsersPermissionsUser;

  const notificationResponse = useGetMyNotificationsQuery();
  const notifications = notificationResponse?.data?.me?.user?.notifications;
  const unreadConversations = notifications?.filter(notification => notification.type === Enum_Notification_Type.NewMessage);

  return <ContentLoader loading={loading} error={error}>
    {user && <>
      <PageHelmet pageTitle={i18n.helmets.home}/>
      <ModeratorHomeHeader user={user} />
      <PageContainer>
        <div className="text-xl text-primary-500 font-bold mb-2">
          {i18n.moderator.issuesAndMessages}
        </div>
        <div className="flex gap-5">
          <Link to="/reports">
            <Card className="text-center p-4 flex flex-col gap-2 items-center">
              <p className="font-bold text-2xl">{notifications?.length}</p>
              <p>{i18n.moderator.issues.new}</p>
            </Card>
          </Link>
          <Link to="/messages">
            <Card className="text-center p-4 flex flex-col gap-2 items-center">
              <p className="font-bold text-2xl">{unreadConversations?.length}</p>
              <p>{i18n.moderator.messages.unread}</p>
            </Card>
          </Link>
        </div>
        <ModeratorHomeStats groupId={user.group!.id}/>
        <ModeratorHomeFeed groupId={user.group!.id}/>
      </PageContainer>
    </>}
  </ContentLoader>
};
