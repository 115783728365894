import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Enum_Userspermissionsuser_Mentorstatus, Review, useGetUserQuery, UsersPermissionsUser } from '../../services/graphql';
import { ContentLoader } from '../../shared/ContentLoader';
import { PageContainer } from '../../shared/PageContainer';
import { ProfileHeader } from '../ProfileHeader';
import { ProfileCourseCard } from './ProfileCourseCard';
import { ProfilePostsCard } from './ProfilePostsCard';
import { ProfileReviewsCard } from './ProfileReviewsCard';
import { useAuthenticationService } from '../../authentication/authentication-service';
import i18n from "../../translations/User";
import { PageHelmet } from '../../shared/Helmet';

interface ProfilePageProps { }

interface ProfileParams {
  id: string
}

export const ProfilePage: FC<ProfilePageProps> = () => {
  const { id } = useParams<ProfileParams>();
  const user = useAuthenticationService(state => state.user);
  const myProfile = user?.id.toString() === id;

  const { data, loading, error } = useGetUserQuery({
    variables: {
      id: id
    }
  });

  const userDetails: UsersPermissionsUser | undefined = data?.user as UsersPermissionsUser;
  const reviews: Review[] | undefined = data?.reviewsForUser as Review[];

  const userIsMentor = userDetails?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved;

  return <ContentLoader loading={loading} error={error}>
    {userDetails && <>
      <PageHelmet pageTitle={i18n.helmets.profile(userDetails)}/>
      <ProfileHeader user={userDetails} myProfile={myProfile} />
      <PageContainer>
        <div className="flex flex-col sm:flex-row w-full">
          <ProfileCourseCard user={userDetails} className="sm:w-72 mb-5 sm:mb-0 sm:mr-5" myProfile={myProfile} />
          <div className="flex-1 space-y-5">
            {userIsMentor && reviews && <ProfileReviewsCard user={userDetails} reviews={reviews} className="flex-1" />}
            { user?.organisation.hideForum ? null : <ProfilePostsCard user={userDetails} /> }
          </div>
        </div>
      </PageContainer>
    </>}
  </ContentLoader >
};
