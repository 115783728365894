import { FC } from 'react';
import { ConversationMessage } from '../conversations/messages/ConversationMessage';
import { Enum_Userspermissionsuser_Mentorstatus, Message, Report } from '../services/graphql';
import { Button } from '../shared/Button';
import { Card } from '../shared/Card';
import i18n from "../translations/Moderators";
import { ReportSummaryCard } from './ReportSummaryCard';

interface ReportChatHistoryProps {
    report: Report,
    messages: Message[],
    menteeId?: string,
    getMoreMessages: () => void
}

export const ReportChatHistory: FC<ReportChatHistoryProps> = ({ report, messages, menteeId, getMoreMessages }) => {
    const revokedMentor = report.reportee?.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Revoked;

    return <div className="flex mx-auto max-w-3xl my-4 px-4 flex-col items-start">
        {messages ? <>
            {messages.length >= 20 ? <Button compact className="w-full mb-4" onClick={getMoreMessages}>{i18n.chat.getMore}</Button> : null}
            {messages.map(message => {
                return <ConversationMessage
                    rightAlignMessage={message!.sentBy?.id === menteeId}
                    message={message}
                />
            })}
            <ReportSummaryCard report={report}/>
            {revokedMentor && <Card className="self-center flex flex-col items-center gap-2 m-5 p-5">
                <span className="text-primary-600 font-bold text-lg">{i18n.response.mentorRevoked(report.reportee!)}</span>
            </Card>}
        </> : null}
    </div>
};
