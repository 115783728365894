import { useLayoutEffect, useRef } from 'react';
import { FC } from 'react';
import { InputProps, withField } from '.';
import { merge } from '../../helpers/ui';

interface TextAreaInputProps extends InputProps<string> {
  placeholder?: string
  adaptiveHeight?: boolean
}

export const TextAreaInput: FC<TextAreaInputProps> = ({ className, name, onChange, value, placeholder, ...props }) => {

  const ref = useRef<HTMLTextAreaElement>(null)
  useLayoutEffect(() => {
    if (!ref.current) return;
    ref.current.style.height = "1px";
    const newHeight = Math.max(ref.current.scrollHeight + 4, 35)
    ref.current.style.height = newHeight + "px";
  }, [value])

  return <div className="w-full">
    <textarea
      {...props}
      name={name}
      id={name}
      ref={ref}
      className={merge('shadow-sm max-h-40 h-11 text-sm resize-none overflow-y-scroll focus:ring-primary-500 bg-white focus:border-primary-500 block w-full border-gray-300 rounded-md', className)}
      placeholder={placeholder}
      value={value}
      onChange={(event) => onChange(event.target.value)}
    />
  </div>
};

export const TextAreaField = withField(TextAreaInput);
