import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { log } from '../../helpers/log';
import { PageContainer } from '../../shared/PageContainer';
import { Spinner } from '../../shared/Spinner';
import { useAuthenticationService } from '../authentication-service';

interface LogoutProps { }

export const Logout: FC<LogoutProps> = () => {
  const [state, clearUserInfo] = useAuthenticationService(state => [state.state, state.clearUserInfo]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        log(`User is ${state}. Logging in regardless of previous state.`)
        clearUserInfo()
        history.push('/');

      } catch (e) {
        log('Logout was unsuccessful.', e)
      }
    })()
  }, [clearUserInfo, history, state])

  return <PageContainer className="flex w-screen sm:h-screen flex-col justify-center items-center">
    <Spinner />
  </PageContainer>
};
