import { FC } from 'react';
import { ChitList } from '../../shared/ChitList';
import { Label } from '../../shared/Label';

interface ProfileCourseModulesProps {
  title: string,
  names: string[],
  secondary?: boolean
}

export const ProfileCourseModules: FC<ProfileCourseModulesProps> = ({ title, names, secondary }) => {
  return <div className="flex flex-col mb-2 space-y-1">
    <Label className="text-primary-400">{title}</Label>
    <ChitList chits={names} secondary={secondary}/>
  </div>
};
