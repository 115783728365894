import { FC } from 'react';
import { Enum_Userspermissionsuser_Mentorstatus, Report } from '../services/graphql';
import { ReportResponseChatButton } from './ReportResponseChatButton';
import { ReportResponseForwardButton } from './forward/ReportResponseForwardButton';
import { ReportResponseRevokeButton } from './ReportResponseRevokeButton';

interface ReportResponseProps {
    report: Report,
    refetch: () => void
}

export const ReportResponse: FC<ReportResponseProps> = ({ report, refetch }) => {
    return <div className="w-full flex justify-center items-center p-5 bg-white">
        <div className="max-w-3xl w-full px-4 flex gap-5  justify-between">
            {report.reportee ? <div className="flex flex-col gap-2">
                <ReportResponseChatButton user={report.reportee} mentor conversation={report.conversation!}/>
                {report.reportee.mentorStatus === Enum_Userspermissionsuser_Mentorstatus.Approved && 
                    <ReportResponseRevokeButton onRevoke={refetch} user={report.reportee}/>
                }
            </div> : <div/>}
            <div>
            <ReportResponseForwardButton report={report}/>
            </div>
            {report.reporter ? <div>
                <ReportResponseChatButton user={report.reporter} conversation={report.conversation!}/>
            </div> : <div/>}
        </div>
    </div>
};
