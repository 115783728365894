import LocalizedStrings from 'react-localization';

export default new LocalizedStrings({
  en: {
    helmets: {
      forum: (forumName: string) => `Forum - ${forumName}`,
      createNewPost: "Create new post",
      forumPost: (postName: string) => `Forum Post - ${postName}`,
    },
    postNotFound: "Forum post not found",
    forumNotFound: "Forum not found",
    noPosts: "No posts available",
    responses: {
      title: "Responses",
      noResponses: "No comments yet!",
      reply: {
        label: "Reply",
        placeholder: "Leave a comment"
      }
    },
    forum: {
      title: "Forum",
      create: {
        label: "Create New Post",
        title: (groupName: string) => `${groupName} - Create New Post`,
        linkedModules: "Linked Modules",
        fields: {
          title: {
            label: "Title",
            placeholder: "Title for your post",
            required: "Your post must have a title"
          },
          text: {
            label: "Text",
            placeholder: "Text for your post",
            required: "You post must have text"
          },
          post: {
            label: "Post"
          },
          cancel: {
            label: "Cancel"
          },
          report: "Report",
          reply: "Reply",
          submit: "Submit"
        }
      },
      filter: {
        label: "Filter",
        showAll: "Show all"
      }
    }
  }
})
