import { FC } from 'react';
import i18n from "../translations/Mentors";
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { HTMLAttributes } from 'react';

interface PagerProps extends HTMLAttributes<HTMLDivElement> {
    currentPage: number,
    totalPages: number,
    onPage: (next: boolean) => void
}

export const Pager: FC<PagerProps> = ({currentPage, totalPages, onPage}) => {
    return <div className="flex w-full h-12 items-center relative justify-center">
        <div>
            {i18n.search.paging.count(currentPage, totalPages)}
        </div>
        {currentPage !== 1 && <div className="absolute left-2 top-2">
            <button onClick={() => onPage(false)} className="flex items-center gap-2">
                <FiArrowLeft className="h-8 w-8" />
                <span className="hidden sm:block">{i18n.search.paging.prev}</span>
            </button>
        </div>}
        {currentPage !== totalPages && <div className="absolute top-2 right-2">
            <button onClick={() => onPage(true)} className="flex items-center gap-2">
                <span className="hidden sm:block">{i18n.search.paging.next}</span>
                <FiArrowRight className="h-8 w-8" />
            </button>
        </div>}
    </div>
};
