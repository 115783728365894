import { FC } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';

interface ErrorMessageProps {
  message: string
}

export const ErrorMessage: FC<ErrorMessageProps> = (props: ErrorMessageProps) => {
  return <div className="flex flex-col items-center">
    <FiAlertTriangle className="w-8 h-8"/>
    <span>{props.message}</span>
  </div>
};