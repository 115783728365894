import { FC, useState } from 'react';

import { PageContainer } from '../shared/PageContainer';
import { Spinner } from '../shared/Spinner';
import { useGetMyModeratorsQuery, UsersPermissionsUser } from '../services/graphql';
import { useStartConversation } from './useStartConversation';
import { ErrorMessage } from '../shared/ErrorMessage';
import { Alert } from '../shared/Alert';
import { useHistory } from 'react-router-dom';
import i18n from "../translations/Moderators";

export const ContactModerator: FC = () => {
  const { startChat, error: startChatError } = useStartConversation();
  const [moderator, setModerator] = useState<UsersPermissionsUser | undefined>();
  const history = useHistory();

  const { error: getModsError } = useGetMyModeratorsQuery({
    onCompleted: (data) => {
      if (data.me?.user?.group?.users?.length && data.me?.user?.group?.users?.length > 0) {
        setModerator(data.me?.user?.group?.users[0] as UsersPermissionsUser);
      }
    }
  });

  const error = getModsError || startChatError;

  return <PageContainer className="flex w-screen sm:h-screen flex-col justify-center items-center">
    {error ? <ErrorMessage message={error.message} /> : <Spinner />}
    <Alert
      title={i18n.speakToModerator.title}
      description={i18n.speakToModerator.message}
      open={!!moderator}
      onSuccess={() => {if (moderator) {startChat(moderator.id)}}} 
      onClose={() => history.goBack()}
    />
  </PageContainer>
};
