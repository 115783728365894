import { FC, HTMLAttributes } from 'react';
import { merge } from '../helpers/ui';
import { Organisation, UploadFile } from '../services/graphql';

interface OrgProfilePictureProps extends HTMLAttributes<HTMLDivElement> {
  org: Organisation
}

export const OrgProfilePicture: FC<OrgProfilePictureProps> = ({ org, className }) => {
  let orgPic: UploadFile | undefined;

  if (org.profilePicture?.length) {
    orgPic = org.profilePicture[0];
  }

  if (orgPic) {
    return <img className={merge("h-full", className)} src={orgPic.url} alt={orgPic.alternativeText ? orgPic.alternativeText : `${org.name}'s logo`}/>
  } else {
    return <div className={merge("h-full", className)}>{org.name}</div>
  }
};
