import { FC } from 'react';
import { RiMailSendLine } from 'react-icons/ri';
import { Button } from '../../shared/Button';

interface ConversationSendButtonProps {
  onClick: () => void,
  loading: boolean
}

export const ConversationSendButton: FC<ConversationSendButtonProps> = (props: ConversationSendButtonProps) => {
  return <Button className="flex justify-center items-center" primary loading={props.loading} onClick={props.onClick}>
    <RiMailSendLine className="text-white h-6 w-6" />
  </Button>
};
