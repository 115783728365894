import { FC } from 'react';
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom';
import i18n from '../../translations/Authentication';

import { AuthenticationState, Role, useAuthenticationService } from '../authentication-service';

interface AuthenticatedRouteProps extends RouteProps {
  role?: Role
}

const AuthenticatedRoute: FC<AuthenticatedRouteProps> = ({ role, ...props }) => {
  const [authState, user] = useAuthenticationService(state => [state.state, state.user]);

  const history = useHistory();
  const location = useLocation();

  // If the user is not authenticated, redirect them to a logon page
  if (authState === AuthenticationState.Unauthenticated) {
    history.push('/login', { restore: location.pathname });
  }

  if (authState === AuthenticationState.Authenticated && !user?.isOnboarded) {
    history.push('/onboarding', { restore: location.pathname });
  }

  // If the user is not allowed here
  if (user && role && user.role.id !== role) {
    return <h1>{i18n.route.notAllowed}</h1>
  }

  // If they're a user, go ahead and continue to what they wanted to see
  return <Route {...props}></Route>
}

export default AuthenticatedRoute;
