import { FC, HTMLAttributes } from "react";
import { FiMessageSquare, FiThumbsUp } from "react-icons/fi";
import { Link } from "react-router-dom";
import { getDateAndTimeToDisplay } from "../helpers/date";
import { merge } from "../helpers/ui";
import { ForumPost, UsersPermissionsUser } from "../services/graphql";
import { ChitList } from "../shared/ChitList";
import { ProfileIcon } from "../shared/ProfileIcon";
import { TextSeparator } from "../shared/TextSeparator";

interface ForumLinkProps extends HTMLAttributes<HTMLDivElement> {
    post: ForumPost
}

export const ForumLink: FC<ForumLinkProps> = ({ post, className }) => {
    const tagList: string[] = post.activities?.map(tag => tag.name as string) || [];
    return <div className={merge(className, "flex gap-5 items-center")}>
        <ProfileIcon className="hidden sm:block w-10 h-10" user={post.postedBy as UsersPermissionsUser}/>
        <div className="flex flex-col flex-1">
            <Link className="font-bold" to={`/forum/posts/${post.id}`}>{post.title}</Link>
            <div className="flex">
                <div>{`${post.postedBy?.firstName} ${post.postedBy?.lastName}`}</div>
                <TextSeparator/>
                <div>{getDateAndTimeToDisplay(post.published_at)}</div>
            </div>
            {tagList.length > 0 && <ChitList chits={tagList}/>}
        </div>
        <div className="flex flex-col sm:flex-row items-center">
            <div className="flex items-center">
                <FiMessageSquare/>
                <span className="font-bold ml-1">{post.comments?.length || 0}</span>
            </div>
            <div className="sm:ml-5 flex items-center">
                <FiThumbsUp/>
                <span className="font-bold ml-1">{post.upvotedBy?.length || 0}</span>
            </div>
        </div>
    </div>;
}
