import LocalizedStrings from 'react-localization';
import { UsersPermissionsUser } from '../services/graphql';

export default new LocalizedStrings({
  en: {
    helmets: {
      profile: (user: UsersPermissionsUser) => `Profile - ${user.firstName} ${user.lastName}`,
      editProfile: "Edit Profile"
    },
    editProfile: {
      header: {
        title: 'Profile Information',
        description: 'Who are you and what are you up to?'
      },
      buttons: {
        save: {
          label: "Save"
        },
        cancel: {
          label: "Cancel"
        }
      },
      fields: {
        firstName: {
          label: "First Name",
          requiredMessage: "First name is required."
        },
        lastName: {
          label: "Last Name",
          requiredMessage: "Last name is required."
        },
        email: {
          label: "Email",
          requiredMessage: "Email is required."
        },
        bio: {
          label: "Bio",
        },
        profilePhoto: {
          label: "Profile Photo",
        },
        university: {
          label: 'University',
          requiredMessage: 'University is required.'
        },
        levels: {
          label: 'Year',
          requiredMessage: 'Year is required.'
        },
        groups: {
          label: 'Course',
          requiredMessage: 'Course is required'
        }
      }
    },
    profile: {
      courseSummary: {
        course: "Course",
        year: "Year",
        bio: "Bio",
        modules: "Modules",
        enrolledModules: "Enrolled Modules",
        completedModules: "Completed Modules",
        experiences: "Experiences",
        graduated: "Graduate",
        verifiedMentor: "Approved Mentor",
        academicDetails: "Academic Details",
        personalDetails: "Personal Details",
        graduatedEmoji: "🎉"
      },
      posts: {
        title: "Posts",
        noPosts: (name: string) => `${name} hasn't posted anything on the forums yet.`
      },
      reviews: {
        title: "Reviews",
        noReviews: "{0} doesn't have any reviews yet.",
        speed: 'Speed',
        knowledge: 'Knowledge',
        helpfulness: 'Helpfulness'
      },
      startChat: "Start chat"
    }
  }
})
