import { FC } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DisabledPropertyName } from '@microsoft/applicationinsights-common';

import { useAuthenticationService } from '../authentication/authentication-service';
import { dependencyReportingLink } from './log';
import { createUploadLink } from 'apollo-upload-client';

declare global {
  interface Window {
    environment: {[key: string]: string};
  }
}

const uploadLink = createUploadLink({
  uri: `${window.environment.baseUrl}/graphql`,
  fetchOptions: {
    [DisabledPropertyName]: true
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { token } = useAuthenticationService.getState();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    }
  }
});

export const client = new ApolloClient({
  link: from([
    authLink,
    dependencyReportingLink,
    uploadLink
  ]),
  cache: new InMemoryCache({
    addTypename: true
  })
});


export const GraphQLProvider: FC = ({ children }) => {
  return <ApolloProvider client={client}>
    { children }
  </ApolloProvider>
}
