import { FC, HTMLAttributes } from 'react';
import { LoginError } from '../../authentication/authentication-service';
import { merge } from '../../helpers/ui';
import i18n from "../../translations/Shared";

interface StrapiErrorProps extends HTMLAttributes<HTMLDivElement> {
  errors: LoginError
}

export const StrapiError: FC<StrapiErrorProps> = ({ children, className, errors, ...props }) => {
  return <div className={merge('flex flex-col bg-red-100 border border-red-300 rounded mt-4 text-red-700 text-sm mb-2 py-3 px-4', className)} {...props}>
    <strong>{i18n.errors.strapi}</strong>
    {`Message: ${errors.message}`}
  </div>
};
