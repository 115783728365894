import { FC } from 'react';
import { InputProps, withField } from '.';
import { merge } from '../../helpers/ui';

interface TextInputProps extends InputProps<string> {
  placeholder?: string
  autoComplete?: string
}

export const TextInput: FC<TextInputProps> = ({ className, name, onChange, value, placeholder, ...props }) => {
  return <input
    {...props}
    type="text"
    name={name}
    id={name}
    className={merge('shadow-sm focus:ring-primary-500 bg-white focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md', className)}
    placeholder={placeholder}
    value={value}
    onChange={(event) => onChange(event.target.value)}
  />
};

export const TextField = withField(TextInput);
